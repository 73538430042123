import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const GROUP_CARDS_TEXT = {
  'feature_tile.group_cards.title': {
    'en-GB': `Your friends can write inside too!`,
    'nl-NL': `Schrijf een kaart samen met je vrienden!`,
  },
  'feature_tile.group_cards.call_to_action': {
    'en-GB': `Browse Group Cards`,
    'nl-NL': `Ontdek groepskaarten`,
  },
  'feature_tile_group_cards.list_item_1': {
    'en-GB': `Create a <strong>group card</strong>`,
    'nl-NL': `Maak een <strong>groepskaart</strong>`,
  },
  'feature_tile_group_cards.list_item_2': {
    'en-GB': `<strong>Share</strong> a link so others can sign online`,
    'nl-NL': `<strong>Deel</strong> een link zodat iedereen kan bijdragen`,
  },
  'feature_tile_group_cards.list_item_3': {
    'en-GB': `<strong>No account required</strong> to sign - easy!`,
    'nl-NL': `<strong>Geen account</strong> nodig - supermakkelijk!`,
  },

  'feature_tile.group_cards.pill': {
    'en-GB': `New Feature`,
    'nl-NL': `Nieuw`,
  },
}

const dictionary = {
  ...GROUP_CARDS_TEXT,
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})
