import {
  DYNAMIC_PAGE_TYPE,
  DYNAMIC_RESULTS_TYPE,
  GALLERY_PAGE_TYPE,
  GALLERY_RESULTS_TYPE,
  SEARCH_PAGE_TYPE,
  SEARCH_RESULTS_TYPE,
  PAY_PER_CLICK_PAGE_TYPE,
} from '../constants'

export const getGAListDataType = (pageType: string): string => {
  switch (pageType) {
    case SEARCH_PAGE_TYPE:
      return SEARCH_RESULTS_TYPE
    case GALLERY_PAGE_TYPE:
      return GALLERY_RESULTS_TYPE
    case DYNAMIC_PAGE_TYPE:
      return `${DYNAMIC_RESULTS_TYPE} | organic`
    case PAY_PER_CLICK_PAGE_TYPE:
      return `${DYNAMIC_RESULTS_TYPE} | ppc`
    default:
      return pageType
  }
}
