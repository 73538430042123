import {
  topLevelFacetFragment,
  midLevelFacetFragment,
  bottomLevelFacetFragment,
  headerFragment as FilterHeader,
  filterToggleFragment as ToggleFilter,
  filterInvertedToggleFragment as InvertedToggleFilter,
} from '../filters/__generated__/createLoadFilters'
import {
  FilterItemsTypeEnum,
  FilterServiceFilterFacet,
  FilterServiceFilterHeader,
  FilterServiceFilterItem,
  FilterHeader as FilterHeaderVariant,
  FilterCategory,
  FilterItem as FilterItemVariant,
  ApplicableFilter,
} from './services'

type FilterFacet = Overwrite<
  topLevelFacetFragment | midLevelFacetFragment | bottomLevelFacetFragment,
  {
    children?:
      | topLevelFacetFragment['children']
      | midLevelFacetFragment['children']
      | null
  }
>

export type FilterItem =
  | FilterFacet
  | ToggleFilter
  | InvertedToggleFilter
  | FilterHeader

const allFiltersInCategorySelected = (filters: FilterItemVariant[]) => {
  return filters.every(
    filter => filter.__typename === 'Filter' && filter.isSelected,
  )
}

const mapGqlFilterFacetToDomainVariant = (
  gqlFilter: FilterFacet | ToggleFilter | InvertedToggleFilter,
): FilterCategory | ApplicableFilter => {
  if (
    gqlFilter.__typename === 'FilterFacet' &&
    gqlFilter.children &&
    gqlFilter.children.length
  ) {
    const children = gqlFilter.children.map(child =>
      mapGqlFilterItemToDomainVariant(child as FilterItem),
    )
    if (gqlFilter.all) {
      children.unshift({
        __typename: 'AllFilter',
        id: `all-${gqlFilter.facetKey}`,
        parent: gqlFilter.facetKey,
        isSelected: allFiltersInCategorySelected(children),
        label: gqlFilter.label,
      })
    }
    return {
      __typename: 'FilterCategory',
      id: gqlFilter.facetKey,
      parent: gqlFilter.group,
      count: gqlFilter.count,
      label: gqlFilter.label,
      nbaScore: gqlFilter.nbaScore || undefined,
      children,
    }
  }

  if (gqlFilter.__typename === 'ToggleFilter') {
    return {
      __typename: 'FilterToggle',
      id: gqlFilter.facetKey,
      parent: gqlFilter.group,
      isSelected: gqlFilter.isSelected,
      label: gqlFilter.label,
      ...(gqlFilter.icon && { icon: gqlFilter.icon }),
    }
  }

  if (gqlFilter.__typename === 'InvertedToggleFilter') {
    return {
      __typename: 'FilterInvertedToggle',
      id: gqlFilter.facetKey,
      parent: gqlFilter.group,
      isSelected: gqlFilter.isSelected,
      label: gqlFilter.label,
      ...(gqlFilter.icon && /* istanbul ignore next */ {
        icon: gqlFilter.icon,
      }),
    }
  }

  return {
    __typename: 'Filter',
    id: gqlFilter.facetKey,
    parent: gqlFilter.group,
    count: gqlFilter.count,
    isSelected: gqlFilter.isSelected,
    label: gqlFilter.label,
    nbaScore: gqlFilter.nbaScore || undefined,
  }
}

const mapGqlFilterFacetToDomain = (
  gqlFacet: FilterFacet,
): FilterServiceFilterFacet => {
  return {
    type: FilterItemsTypeEnum.FACET,
    facetKey: gqlFacet.facetKey,
    group: gqlFacet.group,
    hasAllOption: gqlFacet.all || undefined,
    count: gqlFacet.count,
    nbaScore: gqlFacet.nbaScore || undefined,
    isSelected: gqlFacet.isSelected,
    label: gqlFacet.label,
    children: gqlFacet.children
      ?.map(child => mapGqlFilterItemToDomain(child as FilterItem))
      .filter(Boolean) as FilterServiceFilterItem[],
  }
}

const mapGqlFilterHeaderToDomain = (
  gqlFacet: FilterHeader,
): FilterServiceFilterHeader => ({
  type: FilterItemsTypeEnum.HEADER,
  name: gqlFacet.name,
})

const mapGqlFilterHeaderToDomainVariant = (
  gqlFacet: FilterHeader,
): FilterHeaderVariant => ({
  __typename: gqlFacet.__typename,
  label: gqlFacet.name,
})

export const mapGqlFilterItemToDomain = (
  gqlFacet: FilterItem,
): FilterServiceFilterItem | null => {
  if (gqlFacet.__typename === 'FilterFacet') {
    return mapGqlFilterFacetToDomain(gqlFacet)
  }

  if (gqlFacet.__typename === 'FilterHeader') {
    return mapGqlFilterHeaderToDomain(gqlFacet)
  }

  return null
}

export const mapGqlFilterItemToDomainVariant = (
  gqlFacet: FilterItem,
): ApplicableFilter | FilterCategory | FilterHeaderVariant => {
  return gqlFacet.__typename === 'FilterFacet' ||
    gqlFacet.__typename === 'ToggleFilter' ||
    gqlFacet.__typename === 'InvertedToggleFilter'
    ? mapGqlFilterFacetToDomainVariant(gqlFacet)
    : mapGqlFilterHeaderToDomainVariant(gqlFacet)
}
