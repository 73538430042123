import { RouterType } from '@moonpig/web-core-routing'
import { PageRedirectResponse } from '@moonpig/web-core-app'
import { facetMappings } from './facetMap'

export const capitaliseFirstCharacter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export type ApplyFiltersFacet = {
  group: string
  facetKey: string
}

const updateFilters = (
  existingSelectedFacets: {
    facetKey: string
    group: string
  }[],
  updatedFacets: {
    facetKey: string
    group: string
  }[],
  type: 'ADD' | 'REMOVE' | 'UPDATE',
) => {
  const existingUrlFacets = existingSelectedFacets.map(
    x => `${x.group}:${x.facetKey}`,
  )

  const newUrlFacets = updatedFacets.map(x => `${x.group}:${x.facetKey}`)

  switch (type) {
    case 'ADD':
      return existingUrlFacets.concat(newUrlFacets).join('.')
    case 'REMOVE':
      return existingUrlFacets.filter(x => !newUrlFacets.includes(x)).join('.')
    case 'UPDATE':
    default:
      return newUrlFacets.join('.')
  }
}

export const getFacetsRedirectForPreFacetKeyUrl = (
  queryString: {
    filters?: string
    region: string
    q?: string
    d?: string
    ds_kw?: string
    parts: string[]
  },
  searchType: 'search' | 'gallery' | 'sd' | 'ppc',
): PageRedirectResponse | null => {
  const hasNameValueFilters = queryString.filters?.includes('_equals_')
  if (hasNameValueFilters) {
    const individualQueryFilters =
      queryString.filters?.split('.') || /* istanbul ignore next */ []
    const mappedFromLegacyFacets = individualQueryFilters
      .reduce<ApplyFiltersFacet[]>((curr, x: string) => {
        const [_name, value] = x.split('_equals_')

        return facetMappings[value]
          ? [
              ...curr,
              {
                group: facetMappings[value]?.group,
                facetKey: facetMappings[value]?.key,
              },
            ]
          : [...curr]
      }, [])
      .map((x: ApplyFiltersFacet) => `${x.group}:${x.facetKey}`)
      .join('.')

    const searchTermQuery =
      searchType === 'search' && queryString.q ? `q=${queryString.q}` : ''
    const departmentQuery =
      searchType === 'search' && queryString.d ? `d=${queryString.d}` : ''
    const filtersQuery = mappedFromLegacyFacets
      ? `filters=${mappedFromLegacyFacets}`
      : ''

    const queryElements = [filtersQuery, searchTermQuery, departmentQuery]
      .filter(x => x)
      .join('&')

    const fullQueryElements = queryElements ? `?${queryElements}` : ''

    return {
      pageRedirect: {
        statusCode: 301,
        location: `/${queryString.region}/${getGalleryRoute(
          queryString,
          searchType,
        )}/${fullQueryElements}`,
      },
    }
  }
  return null
}

const getGalleryRoute = (
  queryString: {
    filters?: string
    region: string
    q?: string
    d?: string
    ds_kw?: string
    parts: string[]
  },
  searchType: 'search' | 'sd' | 'gallery' | 'ppc',
) => {
  if (searchType === 'search') {
    return 'search'
  }
  if (searchType === 'sd') {
    return `d/${queryString.parts.join('/')}`
  }
  return queryString.parts.join('/')
}

export async function updateRoute(
  existingSelectedFacets: {
    group: string
    facetKey: string
  }[],
  updatedFacets: {
    group: string
    facetKey: string
  }[],
  router: RouterType,
  type: 'ADD' | 'REMOVE' | 'UPDATE' = 'UPDATE',
): Promise<void> {
  const queryFilters = updateFilters(
    existingSelectedFacets,
    updatedFacets,
    type,
  )
  const { name, params } = router.getCurrentRoute<'content'>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { page, filters, ...existingQuery } = {
    ...params,
    filters: queryFilters,
  }
  return router.replace({
    name,
    params: {
      ...existingQuery,
      ...(filters && { filters }),
    },
    shallow: true,
  })
}
