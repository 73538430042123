import React, { FC } from 'react'
import { useInView } from 'react-intersection-observer'
import { FiltersButtonSticky } from './FiltersButtonSticky'
import { useProductListingPageContext } from '../../utils/productListingPageContext'

type Props = {
  onFiltersClickedCallback: () => void
  onInView: (inView: boolean) => void
}

export const FiltersMenuButton: FC<Props> = ({
  onFiltersClickedCallback,
  onInView,
}) => {
  const [state] = useProductListingPageContext()
  const [ref] = useInView({
    threshold: 0,
    triggerOnce: false,
    onChange: onInView,
  })

  return (
    <FiltersButtonSticky
      ref={ref}
      appliedFiltersCount={Object.keys(state.applyFilterData.facets).length}
      onClick={onFiltersClickedCallback}
    />
  )
}
