import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { Text } from '@moonpig/launchpad-components'
import { FilterCategory } from '../../../../services/types/services'
import { useFindLocaleText } from '../../../../text-localisation'
import { getAllSelectedFilters } from '../../helpers/getAllSelectedFilters'

const MAX_FILTERS_DISPLAYED = 5

const StyledSubText = styled(Text)`
  ${s({
    textAlign: 'left',
    color: 'colorInteractionSelectedState',
    typography: 'typeBodyCaption',
  })}
`

const allFiltersSelected = (filter: FilterCategory) => {
  const firstFilter = filter.children[0]
  return firstFilter.__typename === 'AllFilter' && firstFilter.isSelected
}

export const SelectedFiltersList: FC<{
  filter: FilterCategory
}> = ({ filter }) => {
  const localiseText = useFindLocaleText()
  const selectedFilters = getAllSelectedFilters(filter.children)

  if (selectedFilters.length === 0) return null

  if (allFiltersSelected(filter)) {
    return (
      <StyledSubText>
        {localiseText('find.all', { label: filter.label })}
      </StyledSubText>
    )
  }

  return selectedFilters.length > MAX_FILTERS_DISPLAYED ? (
    <StyledSubText>
      {localiseText('find.n_more_filters', {
        filterName: selectedFilters[0],
        moreCount: selectedFilters.length - 1,
      })}
    </StyledSubText>
  ) : (
    <StyledSubText>{selectedFilters.join(', ')}</StyledSubText>
  )
}
