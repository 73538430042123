import { Flex } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { FiltersMenuButton } from '../FiltersCarouselVariant/components/FiltersMenuButton'
import { useSearchStore } from '../../store/SearchStore'

const StyledQuickFiltersBar = styled(Flex)`
  ${s({
    boxShadow: '0px 4px 4px -4px rgba(0, 32, 77, 0.15)',
    bgcolor: 'colorBackground01',
    pb: 5,
    mb: 4,
  })}
`

export const QuickFilters: FC = () => {
  const toggleFiltersMenu = useSearchStore(store => store.toggleFiltersMenu)
  const filtersMenuOpen = useSearchStore(store => store.filtersMenuOpen)
  const selectedFilters = useSearchStore(store => store.selectedFilters)

  return (
    <StyledQuickFiltersBar data-testid="dropdown-filters">
      <FiltersMenuButton
        toggleMenu={toggleFiltersMenu}
        isMenuOpen={filtersMenuOpen}
        appliedFilters={selectedFilters}
      />
    </StyledQuickFiltersBar>
  )
}
