import { gql } from '@moonpig/web-core-graphql'

const PRODUCT_SEARCH_FIELDS_FRAGMENT = gql`
  fragment SearchServiceProductVariant on ProductVariant {
    key
    title
    subtitle
    inStock
    sku
    minimumQuantity
    price {
      centAmount
      currencyCode
      fractionDigits
    }
    fullPrice {
      centAmount
      currencyCode
      fractionDigits
    }
    discountedPercentage
    bundles {
      description
      price {
        currencyCode
        centAmount
        fractionDigits
      }
      discountPercentage
      size
    }
    dimensions {
      description
    }
    capabilities {
      video
    }
  }

  fragment SearchServiceProduct on Product {
    id
    title
    slug
    dependencies
    customisable
    isLandscape
    clickRankDocumentCount
    category {
      id
      slug
      name
      department
    }
    rating {
      count
      score
    }
    masterVariant {
      ...SearchServiceProductVariant
      productImages {
        mainImage {
          medium {
            jpgUrl
          }
        }
      }
      images {
        url
      }
      masterImage {
        url
      }
    }
    variants {
      ...SearchServiceProductVariant
    }
    hasAugmentedReality
    productPills {
      displayLabel
      displayVariant
    }
    primaryProductPill {
      displayLabel
      displayVariant
    }
    publishDate
    isSponsored
  }
`

export const ProductSearchGQL = gql`
  query SearchServiceProductSearch(
    $searchTerm: String!
    $limit: Int!
    $offset: Int!
    $filters: CriteriaInput!
    $secondProductSearchFilters: CriteriaInput
    $thirdProductSearchFilters: CriteriaInput
    $department: [DepartmentsEnum!]
    $returnSuggestions: Boolean!
    $secondDepartment: [DepartmentsEnum!]
    $thirdDepartment: [DepartmentsEnum!]
    $searchOnAllParentDepartments: Boolean!
    $experimentValues: String
    $platform: Platform
    $nbaAlgorithm: String
    $sortOrder: SortOrder
    $sponsoredProducts: [SponsoredProductInput!]
  ) {
    productSearch(
      searchTerm: $searchTerm
      department: $department
      limit: $limit
      offset: $offset
      filters: $filters
      experimentValues: $experimentValues
      platform: $platform
      nbaAlgorithm: $nbaAlgorithm
      sortOrder: $sortOrder
      sponsoredProducts: $sponsoredProducts
    ) {
      totalClickRankDocumentCount
      count
      products {
        ...SearchServiceProduct
      }
      suggestions @include(if: $returnSuggestions) {
        ...SearchServiceProduct
      }
      spellingCorrection {
        initialSearchTerm
        correctedSearchTerm
      }
      supportedSortOrders {
        displayText
        isDefault
        value
      }
    }
    secondProductSearch: productSearch(
      searchTerm: $searchTerm
      department: $secondDepartment
      limit: 0
      offset: 0
      filters: $secondProductSearchFilters
      experimentValues: $experimentValues
      platform: $platform
    ) @include(if: $searchOnAllParentDepartments) {
      count
    }
    thirdProductSearch: productSearch(
      searchTerm: $searchTerm
      department: $thirdDepartment
      limit: 0
      offset: 0
      filters: $thirdProductSearchFilters
      experimentValues: $experimentValues
      platform: $platform
    ) @include(if: $searchOnAllParentDepartments) {
      count
    }
  }
  ${PRODUCT_SEARCH_FIELDS_FRAGMENT}
`
