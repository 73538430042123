import {
  FilterServiceFilterFacet,
  FilterServiceFilterHeader,
} from '../../services/types/services'
import { getFilterFacet } from './filterItemUtils'

export const facetsAreEqual = (
  first?: Partial<FilterServiceFilterFacet>,
  second?: Partial<FilterServiceFilterFacet>,
): boolean => {
  if (!first || !second) {
    return !!first === !!second
  }

  const equal: boolean =
    first.count === second.count &&
    first.facetKey === second.facetKey &&
    first.group === second.group &&
    first.children?.length === second.children?.length &&
    !!first.children === !!second.children &&
    (first.children || [])
      .map((firstChild, i) => {
        const secondChild = second?.children && second?.children[i]
        const secondFacetChild =
          (secondChild && getFilterFacet(secondChild)) ||
          /* istanbul ignore next */ undefined
        const firstFacetChild = getFilterFacet(firstChild)
        return facetsAreEqual(firstFacetChild, secondFacetChild) || undefined
      })
      .every(x => x)

  return equal
}

export const headersAreEqual = (
  first?: FilterServiceFilterHeader,
  second?: FilterServiceFilterHeader,
): boolean => {
  if (!first || !second) {
    return !!first === !!second
  }

  const equal: boolean = first.name === second.name

  return equal
}
