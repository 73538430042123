import { ProductTileProduct } from '../ProductTile/ProductTileProduct'

export type GalleryProduct = ProductTileProduct

type GalleryComponent = {
  component: string
  id: string
  gridIndex?: number
  productIndex?: number
}

export type ProductGridItem = ProductTileProduct | GalleryComponent

export enum ProductGridComponent {
  groupCardTile = 'groupCardTile',
  promoTile = 'promoTile',
  giftXSellCarousel = 'giftXSellCarousel',
}
