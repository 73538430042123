/* eslint-disable jsx-a11y/no-redundant-roles */
import { styled, breakpointDown, breakpointUp } from '@moonpig/launchpad-utils'
import { Box } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import React, { FC } from 'react'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { ContextualHeader } from './ContextualHeader'
import { FilterCategory } from './FilterCategory'
import { SlidingPanel } from './SlidingPanel'
import { SlidingMenu } from './SlidingMenu'
import { LAYOUT_BREAKPOINT } from './constants'
import { useProductListingPageContext } from '../../utils/productListingPageContext'
import { getFilterFacet, useSearchContext } from '../../utils'
import { AllSelectedChangedEvent, FilterChangedEvent } from './types'
import { useFindLocaleText } from '../../text-localisation'
import { RudeFilterToggle } from '../RudeFilterToggle'
import { SortBy } from '../SortBy'
import {
  FilterServiceFilterFacet,
  FilterServiceFilterItem,
} from '../../services/types/services'
import { FiltersPageType } from '../types'

const StyledBox = styled(Box)`
  flex: 1;
  ${s({
    typography: { xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' },
    color: 'outlineCtaTwoContrast',
  })}
`

const ScrollableFixedWrapper = styled.div`
  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    width: 100vw;
    overflow: auto;
    ${s({
      mb: 13,
      pb: 6,
    })}
  }
`

const StyledFixedContent = styled.div`
  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    margin-top: 60px;
  }
`

const ToggleDesktopOnlyBox = styled.div`
  ${breakpointDown('md')} {
    display: none;
  }
`

const MobileOnly = styled.div`
  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    display: none;
  }
`

const StyledDivider = styled(Box)`
  ${breakpointDown('md')} {
    ${s({
      borderBottom: '1px solid',
      borderColor: 'colorBorder03',
    })}
    width: 100%;
  }
`

type FilterCategoriesProps = {
  filters: FilterServiceFilterItem[]
  enableHorizontalAnimation: boolean
  openFilterCategory: string | undefined
  openOption: string | undefined
  allowClear: boolean
  selectedFacets: FilterServiceFilterFacet[]
  filterChanged: FilterChangedEvent
  close: () => void
  setOpenFilterCategory: (facetKey: string | undefined) => void
  setOpenOption: (facetKey: string) => void
  allSelectedChanged: AllSelectedChangedEvent
  onClearAll: () => void
  pageType: FiltersPageType
}

export const FilterCategories: FC<FilterCategoriesProps> = ({
  filters,
  enableHorizontalAnimation,
  openFilterCategory,
  openOption,
  allowClear,
  selectedFacets,
  setOpenFilterCategory,
  setOpenOption,
  allSelectedChanged,
  filterChanged,
  close,
  onClearAll,
  pageType,
}) => {
  const localiseText = useFindLocaleText()
  const [{ loading }] = useProductListingPageContext()

  const useRudeFilterToggle = !process.env.SEARCH_DISABLE_RUDE_TOGGLE

  const { departments } = useSearchContext()
  const currentDepartment = getParentDepartment(departments[0])
  return (
    <SlidingMenu
      enableHorizontalAnimation={enableHorizontalAnimation}
      rightPanelActive={!!openFilterCategory}
      leftPanelActive={!openFilterCategory}
    >
      <SlidingPanel isPrimaryPanel>
        <ContextualHeader
          title={localiseText('find.filters')}
          onClear={onClearAll}
          onBack={close}
          allowClose
          allowClear={allowClear}
          loading={loading}
        />

        <ScrollableFixedWrapper>
          <StyledBox>
            <Box marginTop={'64px'}>
              <ToggleDesktopOnlyBox data-testid="desktop-filters-menu-rude-toggle">
                {useRudeFilterToggle && <RudeFilterToggle />}
              </ToggleDesktopOnlyBox>

              <StyledFixedContent>
                <ul aria-label={localiseText('find.filters')} role="list">
                  <MobileOnly data-testid="find-filters-menu-sort-by">
                    <StyledDivider>
                      <SortBy
                        parentDepartment={currentDepartment}
                        setOpenFilterCategory={setOpenFilterCategory}
                        openFilterCategory={openFilterCategory}
                      />
                    </StyledDivider>
                  </MobileOnly>
                  {filters?.map(facet => {
                    const typedFacet = getFilterFacet(facet)
                    /* istanbul ignore next */

                    if (typedFacet) {
                      return (
                        <FilterCategory
                          key={typedFacet.facetKey}
                          facet={typedFacet}
                          openFilterCategory={openFilterCategory}
                          setOpenFilterCategory={setOpenFilterCategory}
                          filterChanged={filterChanged}
                          selectedFacets={selectedFacets}
                          onClearAll={onClearAll}
                          allowClear={allowClear}
                          allSelectedChanged={allSelectedChanged}
                          openOption={openOption}
                          setOpenOption={setOpenOption}
                          pageType={pageType}
                        />
                      )
                    }
                    return null
                  })}
                </ul>
              </StyledFixedContent>
            </Box>
          </StyledBox>
        </ScrollableFixedWrapper>
      </SlidingPanel>
    </SlidingMenu>
  )
}
