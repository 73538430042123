import React, { FC, ReactNode } from 'react'
import { Text } from '@moonpig/launchpad-components'
import { Checkbox, FormControlLabel } from '@moonpig/launchpad-forms'
import { styled, breakpointDown, breakpointUp } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { IconSystemTick } from '@moonpig/launchpad-assets'
import { LAYOUT_BREAKPOINT } from './constants'
import { FilterChangedEvent } from './types'

const StyledFilterOption = styled.div.attrs(
  ({ checked }: { checked: boolean }) => ({
    className: `${checked && 'checked-mobile'}`,
  }),
)<{ checked: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${s({
    mx: 0,
    pr: 8,
    py: 0,
  })}
  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    height: auto;
    ${s({
      py: 4,
      px: 6,
      mt: 4,
      mx: 0,
      border: 1,
      borderColor: 'colorBorder03',
      borderRadius: 2,
    })}
  }
  &.checked-mobile {
    ${breakpointDown(LAYOUT_BREAKPOINT)} {
      ${s({ bgcolor: 'colorBackground03' })}
    }
  }
`

const DesktopOnly = styled.div`
  position: relative;
  ${s({ mx: 3 })}

  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    display: none;
  }
`

const MobileOnly = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    display: none;
  }
`
const StyledPrimaryText = styled(Text)<{ checked: boolean }>`
  ${({ checked }: { checked: boolean }) =>
    s({
      typography: 'typeBody',
      fontWeight: checked ? 'bold' : 'regular',
      color: 'outlineCtaTwoContrast',
    })}
  max-width: 100%;
  flex-grow: 1;
  line-height: 1.2rem;
  overflow: hidden;
`

const StyledSecondaryText = styled(Text)`
  ${s({
    typography: 'typeBodyCaption',
    color: 'colorBlack40',
  })}
`

type MultiSelectFilterOptionProps = {
  group: string
  label?: string
  facetKey: string
  count?: number
  checked: boolean
  selected: boolean
  filterChanged: FilterChangedEvent
}

export const MultiSelectFilterOption: FC<MultiSelectFilterOptionProps> = ({
  label,
  facetKey,
  count,
  checked,
  filterChanged,
}) => {
  const styleMilestones = (lab: string): ReactNode => {
    const milestoneRegex = /^[0-9]+(?:ste|nd|rd|th|st|e)/
    const milestone = lab?.match(milestoneRegex)
    const labelWithRemovedMilestone = lab.replace(milestoneRegex, '')

    return (
      <StyledPrimaryText checked={checked}>
        {milestone && <b>{milestone}</b>}
        {labelWithRemovedMilestone}
      </StyledPrimaryText>
    )
  }

  const filterOptionLabel = (
    <>
      {label && styleMilestones(label)}
      {!!count && <StyledSecondaryText>&nbsp;{count}</StyledSecondaryText>}
    </>
  )

  return (
    <StyledFilterOption
      checked={checked}
      data-testid="web-filter-option-multi-select"
    >
      <FormControlLabel
        data-testid={`web-find-filters-facet-${facetKey}`}
        label={filterOptionLabel}
        width={'100%'}
      >
        <DesktopOnly>
          <Checkbox
            onChange={e => {
              filterChanged(!e.target.checked, facetKey)
              e.stopPropagation()
            }}
            checked={checked}
            value={facetKey}
          />
        </DesktopOnly>
      </FormControlLabel>
      <MobileOnly>
        {checked && <IconSystemTick width="28px" height="28px" />}
      </MobileOnly>
    </StyledFilterOption>
  )
}
