import React, { FC, useRef } from 'react'
import {
  ScreenReaderOnly,
  Text,
  IconButton,
  Flex,
  Box,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import {
  IconSystemChevronLeft,
  IconSystemChevronRight,
} from '@moonpig/launchpad-assets'
import { SortOrder } from '@moonpig/web-explore-types-graphql'
import { useFindLocaleText } from '../../../../text-localisation'
import { SlidingPanel } from '../SlidingMenu'
import { SortOptions } from './SortOptions'
import { ClearButton } from '../ClearButton'
import { shouldShowClearButton } from '../../helpers/shouldShowClearButton'

const ICON_SIZE = 24

const StyledButton = styled.button.attrs(
  ({ isSubCategory }: { isSubCategory: boolean }) => ({
    className: isSubCategory && 'isSubCategory',
  }),
)<{ isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 64px;
  ${s({
    py: 3,
    pl: 6,
    pr: 8,
    borderBottom: '1px solid',
    borderColor: 'colorBorder03',
    color: 'colorTextLabel',
  })}
`

const StyledText = styled(Text)`
  ${s({
    typography: { xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' },
  })}
`

const StyledHeader = styled(Flex)`
  position: sticky;
  top: 0;
  left: 0;

  ${s({
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: '64px',
    py: 3,
    pl: 3,
    pr: 6,
    borderBottom: '1px solid',
    borderColor: 'colorBorder03',
    bgcolor: 'colorBackground01',
  })}
`

const StyledIconButton = styled(IconButton)`
  flex: 1;
`

type Props = {
  options: { label: string; value: string }[]
  value: string
  defaultValue: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  open: boolean
  resultsButton: JSX.Element
}

export const SortBy: FC<Props> = ({
  options,
  value,
  onChange,
  defaultValue,
  open,
  resultsButton,
}) => {
  const [isOpen, setIsOpen] = React.useState(open)
  const [showSortOptions, setShowSortOptions] = React.useState(open)
  const localiseText = useFindLocaleText()
  const sortByTitle = localiseText('find.sort_by')
  const backButton = useRef<HTMLButtonElement>(null)
  const selectedSortOption = options.find(
    option => option.value === value,
  )?.label
  const hideClearButton = !shouldShowClearButton({
    sortValue: value as SortOrder,
    defaultSortValue: defaultValue as SortOrder,
  })
  const toggleSortByMenu = () => setIsOpen(!isOpen)

  return (
    <>
      <StyledButton
        data-testid="sort-by"
        onClick={() => {
          toggleSortByMenu()
          setShowSortOptions(true)
        }}
        aria-controls={'sort-by-filter-category-panel'}
        role="tab"
      >
        <Flex>
          <StyledText textAlign="left">{sortByTitle}</StyledText>
          <ScreenReaderOnly>
            {localiseText('find.filters_menu_panel.open', sortByTitle)}
          </ScreenReaderOnly>
        </Flex>
        <Flex color="colorInteractionSelectedState">
          <Text>{selectedSortOption}</Text>
          <IconSystemChevronRight width={ICON_SIZE} height={ICON_SIZE} />
        </Flex>
      </StyledButton>
      {showSortOptions && (
        <SlidingPanel
          level={1}
          isOpen={isOpen}
          onAnimationEnd={
            isOpen
              ? () => backButton.current?.focus()
              : () => setShowSortOptions(false)
          }
          id={'sort-by-filter-category-panel'}
        >
          <StyledHeader>
            <StyledIconButton
              ref={backButton}
              icon={IconSystemChevronLeft}
              onClick={toggleSortByMenu}
              label={localiseText('find.filters_menu_panel.close', sortByTitle)}
              tabIndex={isOpen ? 0 : -1}
            />
            <StyledText flex={4} textAlign="center">
              {sortByTitle}
            </StyledText>
            <ClearButton level={1} type="sort" hide={hideClearButton} />
          </StyledHeader>
          <SortOptions
            options={options}
            value={value}
            onChange={onChange}
            disabled={!isOpen}
          />
          <Box position="absolute" bottom={0} width="100%">
            {resultsButton}
          </Box>
        </SlidingPanel>
      )}
    </>
  )
}
