import { ProductTileProduct } from '../ProductTile'
import { AD_CELL_WIDTH, AD_CELL_WIDTH_WIDE } from '../PromoTile/constants'
import { ProductGridComponent, ProductGridItem } from './types'

const groupCardTile = {
  component: ProductGridComponent.groupCardTile,
  id: ProductGridComponent.groupCardTile,
}

const promoTile = {
  component: ProductGridComponent.promoTile,
  id: ProductGridComponent.promoTile,
}

export const formatGridLayout = (
  products: ProductTileProduct[],
  promoTileLocation: number,
  groupCardTilelocation: number,
) => {
  if (!products || products.length === 0) return []

  if (!promoTileLocation && !groupCardTilelocation) return products

  return products.reduce(
    (
      acc: ProductGridItem[],
      product: ProductTileProduct | ProductGridItem,
      currentIndex: number,
    ) => {
      let items: ProductGridItem[] = [...acc]

      if (groupCardTilelocation && currentIndex === groupCardTilelocation) {
        items = [
          ...items,
          {
            ...groupCardTile,
          },
        ]
      }
      if (promoTileLocation && currentIndex === promoTileLocation) {
        items = [...items, promoTile]
      }

      items = [
        ...items,
        {
          ...product,
        },
      ]

      return items
    },
    [],
  )
}

export const calculateComponentWidth = (
  component: string,
  numOfTilesPerRow: number,
) => {
  switch (component) {
    case ProductGridComponent.promoTile:
      if (numOfTilesPerRow === AD_CELL_WIDTH_WIDE) return `100%`
      return AD_CELL_WIDTH / numOfTilesPerRow
    case ProductGridComponent.groupCardTile:
      return `100%`
    case ProductGridComponent.giftXSellCarousel:
      return `100%`
    default:
      return 1 / numOfTilesPerRow
  }
}

export const calculateGroupTilePosition = (
  tilesPerRow: number,
  addPromoTileSpace: number,
) => {
  const GROUP_CARD_ROWS_MOBILE = 6
  const GROUP_CARD_ROWS_DESKTOP = 3

  const rowCount =
    tilesPerRow > 3 ? GROUP_CARD_ROWS_DESKTOP : GROUP_CARD_ROWS_MOBILE

  const isDesktopPromoTileAddOn =
    tilesPerRow >= 4 ? addPromoTileSpace - rowCount + 1 : -addPromoTileSpace

  const promoTileAddOn = addPromoTileSpace ? isDesktopPromoTileAddOn : 0

  return rowCount * tilesPerRow + promoTileAddOn - tilesPerRow
}
