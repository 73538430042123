import React, { FC } from 'react'
import { useFlag } from '@moonpig/web-explore-flags'
import {
  FilterServiceFilterItem,
  SearchSortOrder,
} from '../../services/types/services'
import { FiltersMenuModal } from '../FiltersMenu/FiltersMenuModal'
import { FiltersMenu } from '../FiltersMenu/FiltersMenu'
import { FiltersPageType } from '../types'
import { FiltersMenu as FiltersMenuVariant } from '../FiltersMenuVariant'
import { useSearchStore } from '../../store/SearchStore'

type FiltersMenuSwitcherProps = {
  filtersOpen: boolean
  toggleFiltersMenu: () => void
  facets: FilterServiceFilterItem[]
  totalCount: number
  filtersLoading: boolean
  searchResultsLoading: boolean
  firstLoad: React.MutableRefObject<boolean>
  supportedSortOrders: SearchSortOrder[]
  isFromSearch: boolean
  pageType: FiltersPageType
  sortByOptions: { label: string; value: string }[]
}

export const FiltersMenuSwitcher: FC<FiltersMenuSwitcherProps> = ({
  filtersOpen,
  toggleFiltersMenu,
  facets,
  totalCount,
  filtersLoading,
  searchResultsLoading,
  firstLoad,
  supportedSortOrders,
  isFromSearch,
  pageType,
  sortByOptions,
}) => {
  const filtersMenuRebuild = useFlag('search-filters-menu-rebuild')
  const filtersMenuOpen = useSearchStore(store => store.filtersMenuOpen)
  const filters = useSearchStore(store => store.filters)
  const toggleFiltersMenuFromStore = useSearchStore(
    store => store.toggleFiltersMenu,
  )

  return filtersMenuRebuild ? (
    <FiltersMenuVariant
      toggleFiltersMenu={toggleFiltersMenuFromStore}
      isOpen={filtersMenuOpen}
      filters={filters}
      searchResultsLoading={searchResultsLoading}
      sortOptions={sortByOptions}
    />
  ) : (
    <FiltersMenuModal onClose={toggleFiltersMenu} isOpen={filtersOpen}>
      <FiltersMenu
        isOpen={filtersOpen}
        facets={facets}
        count={totalCount}
        close={toggleFiltersMenu}
        isFromSearch={isFromSearch}
        filtersLoading={filtersLoading}
        firstLoadCurrent={firstLoad.current}
        supportedSortOrders={supportedSortOrders}
        pageType={pageType}
      />
    </FiltersMenuModal>
  )
}
