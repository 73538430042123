import {
  IconSystemChevronLeft,
  IconSystemCross,
} from '@moonpig/launchpad-assets'
import {
  IconButton,
  Text,
  LoadingIndicator,
  Flex,
  Heading,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled, breakpointDown, breakpointUp } from '@moonpig/launchpad-utils'
import React, { FC, memo } from 'react'
import { useFindLocaleText } from '../../text-localisation'
import { LAYOUT_BREAKPOINT } from './constants'
import { DESKTOP_MODAL_FILTERS_MENU_WIDTH } from '../../constants'

const StyledHeader = styled(Flex)`
  ${s({
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingY: 4,
    bgcolor: 'colorBackground01',
  })}

  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    width: ${DESKTOP_MODAL_FILTERS_MENU_WIDTH};
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 1;

    ${s({
      pr: 4,
      pl: 6,
      borderBottom: `1px solid`,
      borderColor: 'colorBorder03',
    })}
  }

  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    ${s({
      borderRadius: 1,
      boxShadow: 1,
      marginRight: 4,
    })}
    height: 54px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: white;
    z-index: 1;
  }
`
const StyledBackWrapper = styled.div`
  order: 0;

  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    order: 3;
  }
`

const StyledHeaderText = styled.div`
  white-space: nowrap;
  order: 1;
`

const StyledHeaderClear = styled(Flex)`
  order: 2;
  min-width: 48px;
  max-height: 48px;
  ${s({
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 4,
  })}

  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    ${s({
      flexGrow: 2,
      justifyContent: 'flex-end',
    })}
  }
`

const StyledButton = styled.button`
  ${s({
    color: 'colorInteractionTextLink',
  })}
  &:hover {
    ${s({
      color: 'colorTextBody',
    })}
  }
`

const StyledTitleText = styled(Heading)`
  display: auto;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${s({
    typography: { xs: 'typeMobileDisplay04', md: 'typeDesktopDisplay05' },
    mb: 0,
  })}
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  ${s({
    color: 'colorInteractionTextLink',
    width: '32px',
    height: '32px',
  })}
`

type ContextualHeaderProps = {
  onClear: () => void
  onBack: () => void
  allowClose?: boolean
  allowClear?: boolean
  title: string
  loading?: boolean
}

const ContextualHeaderComponent: FC<ContextualHeaderProps> = ({
  onBack,
  onClear,
  allowClose,
  allowClear,
  title,
  loading = false,
}) => {
  const localiseText = useFindLocaleText()
  const closeButtonRef = React.useRef<HTMLButtonElement>(null)

  const handleOnClear = () => {
    onClear()
    if (closeButtonRef.current) {
      closeButtonRef.current.focus()
    }
  }

  return (
    <StyledHeader data-testid="filters-menu-header">
      <StyledBackWrapper>
        <IconButton
          icon={allowClose ? IconSystemCross : IconSystemChevronLeft}
          label={allowClose ? `close menu` : `back to parent category`}
          onClick={onBack}
          ref={closeButtonRef}
        />
      </StyledBackWrapper>
      <StyledHeaderText>
        <StyledTitleText level="h2">{title}</StyledTitleText>
      </StyledHeaderText>
      <StyledHeaderClear>
        {loading && (
          <StyledLoadingIndicator label={localiseText('find.loading')} />
        )}
        {!loading && !!allowClear && (
          <StyledButton
            type="button"
            onClick={handleOnClear}
            aria-label={localiseText('find.clear_selection')}
          >
            <Text typography={'typeButtonLabel'}>
              {localiseText('find.clear')}
            </Text>
          </StyledButton>
        )}
      </StyledHeaderClear>
    </StyledHeader>
  )
}

export const ContextualHeader = memo(ContextualHeaderComponent)
