import { createGenericSelectContentEvent } from '@moonpig/web-core-analytics'
import { Price } from '../components/types'
import { SearchFilters } from '../pages/GalleryPage/types'
import { FilterSource, FilterAction } from '../types'
import { GetUpcomingOccasions_me_customer_recommendations_products as RecommendedProducts } from '../queries/types-graphql'
import { generateGAListDataName } from '../utils/generateGAListDataName'
import { getGAListDataType } from '../utils/getGAListDataType'
import { getTrackingLabelForSortOrder } from '../utils/getTrackingLabel'
import { SEARCH_PAGE_TYPE } from '../constants'

type Variant = {
  title: string
  price: Price
}

type MasterVariant = Variant & {
  images: { url: string }[]
  masterImage: { url: string }
  inStock: boolean
}

type Breadcrumb = {
  label: string
  url: string
}

export type ModalOpenGAEventDetailsProduct = {
  id: string
  title: string
  category: {
    name: string
  }
}

export type ModalVPVEventDetailsProduct = {
  id: string
  title: string
  customisable: boolean
  masterVariant: MasterVariant
  category: {
    name: string
    slug: string
  }
  slug: string
  isFavourite?: boolean
  productIndex?: number
}

export type ResultsLoadedGAEventDetailsProduct = ModalVPVEventDetailsProduct

export const filtersOpenGAEventDetails = (title: string) => {
  return createGenericSelectContentEvent({
    eventCategory: 'gallery action',
    eventAction: 'filters opened',
    eventLabel: title,
  })
}

export type ApplyFilterGAEventDetails = {
  pageType: string
  filters: SearchFilters
  selectedFilter?: {
    facetKey: string
    group: string
    count?: number
  }
  filterDetails: {
    sender: FilterSource
    action: FilterAction
  }
  pageTitle: string
  terms: string
  department: string
}

export const applyFilterGAEventDetails = ({
  pageType,
  filters,
  selectedFilter,
  filterDetails,
  pageTitle,
  terms,
  department,
}: ApplyFilterGAEventDetails) => {
  let filteringAction
  let filteringEventName

  switch (filterDetails.action) {
    case 'add':
      filteringEventName = 'ga_selectFilters'
      filteringAction = `${filterDetails.sender} filter selected`
      break
    case 'remove':
      filteringEventName = 'ga_removeFilters'
      filteringAction = `${filterDetails.sender} filter removed`
      break
    case 'clear all':
      filteringEventName = 'ga_clearAllFilters'
      filteringAction = `${filterDetails.sender} filters cleared`
      break
    /* istanbul ignore next*/
    default:
      break
  }

  const listDataType = getGAListDataType(pageType)

  return {
    event: 'eventLoaded',
    eventData: {
      name: filteringEventName,
      category: 'gallery action',
      action: filteringAction,
      label: selectedFilter
        ? `${selectedFilter.group} | ${selectedFilter.facetKey}`
        : 'remove all applied filters',
      interaction: 1,
    },
    productData: undefined,
    listData: {
      results:
        filterDetails.action === 'add' && selectedFilter
          ? selectedFilter.count
          : undefined,
      type: listDataType,
      name: generateGAListDataName(
        pageType,
        pageTitle,
        terms,
        filters.facets,
        department,
      ),
      terms,
      filters,
    },
    page: {
      type: pageType,
      platform: 'new',
    },
    ecommerce: { impressions: null },
  }
}

export const loadMoreGAEventDetails = (productCount: number) => {
  return createGenericSelectContentEvent({
    eventCategory: 'gallery action',
    eventAction: 'load more',
    eventLabel: productCount.toString(),
  })
}

export const generateBreadcrumbsClickGAEvent = (
  breadcrumbs?: Array<Breadcrumb>,
) => {
  const getBreadcrumbHierarchy = () => {
    if (!breadcrumbs) return undefined
    const result: string[] = []
    breadcrumbs.forEach((breadcrumb: Breadcrumb) =>
      result.push(breadcrumb.label),
    )
    return result.join(' > ')
  }

  const breadcrumbHierarchy = getBreadcrumbHierarchy()
  return {
    event: 'select_content',
    content_type: `breadcrumb | ${breadcrumbHierarchy}`,
    event_data: {
      category: 'site breadcrumb selection',
      action: 'global navigation link click',
      label: breadcrumbHierarchy,
      nonInteraction: true,
      value: undefined,
    },
  }
}

export type RecentlyViewedGAEventProduct = {
  id: string
  title: string
  category: {
    name: string
  }
  masterVariant: {
    price: {
      centAmount: number
    }
  }
}

export type ProductsInRecentlyViewed = {
  products: RecentlyViewedGAEventProduct[]
  pageType: string
}

export type NumberOfProductsInRecentlyViewed = {
  productIndex: number
  productCount: number
  pageTitle: string
}

export type RecommendedForYouCarouselImpressions = {
  type: string
  products: RecommendedProducts[]
}

export const ExpandOrMinimiseRecentlyViewedGAEvent = (
  expandOrMinimise: string,
) => {
  return createGenericSelectContentEvent({
    eventCategory: 'product action',
    eventAction: 'list click',
    eventLabel: `Recently Viewed | footer carousel | ${expandOrMinimise}`,
  })
}

export const generateDepartmentHeadersClickGAEvent = (
  searchTerm: string,
  departmentName: string,
  departmentCount: number,
) => {
  return createGenericSelectContentEvent({
    eventCategory: 'search',
    eventAction: 'global header click',
    eventLabel: `${searchTerm} in ${departmentName} | ${departmentCount}`,
  })
}

export const upcomingOccasionsV1LoadEvent = {
  event: 'impression',
  content_data: {
    content_type: 'reminders | shop for reminder load | upcoming occasions v1',
  },
  ecommerce: undefined,
  error_data: undefined,
  event_data: {
    action: 'shop for reminder load',
    category: 'reminders',
    label: 'upcoming occasions v1',
    non_interaction: true,
    value: 1,
  },
}

export const generateSelectSortOrderGAEvent = (sortBy: string) => {
  return createGenericSelectContentEvent({
    eventCategory: 'gallery action',
    eventAction: 'gallery sort selected',
    eventLabel: getTrackingLabelForSortOrder(sortBy),
  })
}

export const generateFilterCategoryExpandedGAEvent = ({
  facetKey,
  pageType,
  expanded,
}: {
  facetKey: string
  pageType: string
  expanded: boolean
}) => {
  const pageTypeString = pageType === SEARCH_PAGE_TYPE ? 'search' : 'gallery'
  const expandedString = expanded ? 'expand' : 'collapse'
  return {
    event: 'select_content',

    content_data: {
      content_type: `${pageTypeString} | ${expandedString} | ${facetKey}`,
    },

    // For GA3 backward compatibility
    event_data: {
      category: pageTypeString,
      action: `${expandedString} filter menu`,
      label: facetKey,
      non_interaction: true, // always true
      value: undefined,
    },

    // Web only //
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  }
}

export const generateFilterCategorySeeMoreLessGAEvent = ({
  facetKey,
  pageType,
  seeMore,
}: {
  facetKey: string
  pageType: string
  seeMore: boolean
}) => {
  const pageTypeString = pageType === SEARCH_PAGE_TYPE ? 'search' : 'gallery'
  const seeMoreString = seeMore ? 'see more' : 'see less'
  return {
    event: 'select_content',

    content_data: {
      content_type: `${pageTypeString} | ${seeMoreString} | ${facetKey}`,
    },

    // For GA3 backward compatibility
    event_data: {
      category: pageTypeString,
      action: `${seeMoreString} filter menu`,
      label: facetKey,
      non_interaction: true, // always true
      value: undefined,
    },

    // Web only //
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  }
}

export const generateFilterMenuSearchBarClickGAEvent = ({
  pageType,
}: {
  pageType: string
}) => {
  const pageTypeString = pageType === SEARCH_PAGE_TYPE ? 'search' : 'gallery'
  return {
    event: 'select_content',

    content_data: {
      content_type: `${pageTypeString} | filters search bar click`,
    },

    // For GA3 backward compatibility
    event_data: {
      category: pageTypeString,
      action: 'filters search bar',
      label: 'form focus',
      non_interaction: true, // always true
      value: undefined,
    },

    // Web only //
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  }
}

export const generateFiltersSearchZeroResultsSearchLinkGAEvent = ({
  pageType,
  searchTerm,
}: {
  pageType: string
  searchTerm: string
}) => {
  const pageTypeString = pageType === SEARCH_PAGE_TYPE ? 'search' : 'gallery'
  return {
    event: 'select_content',

    content_data: {
      content_type: `${pageTypeString} | filters search zero results link click | ${searchTerm}`,
    },

    // For GA3 backward compatibility
    event_data: {
      category: pageTypeString,
      action: 'filters search bar',
      label: `zero results | ${searchTerm}`,
      non_interaction: true, // always true
      value: undefined,
    },

    // Web only //
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  }
}
