import {
  SponsoredProductInput,
  DepartmentsEnum,
  Dependencies,
  ProductPillType,
  SortOrder,
} from '@moonpig/web-explore-types-graphql'

export type SearchPrice = {
  centAmount: number
  currencyCode: string
  fractionDigits: number
}

export type SearchBundle = {
  description: string
  price: SearchPrice
  discountPercentage: number
  size: number
}

export type SearchImage = {
  url: string
}

export type SearchVariant = {
  key: string
  title: string
  subtitle: string | null
  inStock: boolean
  sku: string | null
  minimumQuantity: number
  price: SearchPrice
  fullPrice: SearchPrice
  discountedPercentage?: number | null
  bundles: SearchBundle[]
  dimensions?: { description: string }
  capabilities?: { video: boolean }
}

export type SearchMasterVariant = {
  key: string
  title: string
  subtitle: string | null
  inStock: boolean
  sku: string | null
  minimumQuantity: number
  price: SearchPrice
  fullPrice: SearchPrice
  discountedPercentage?: number | null
  bundles: SearchBundle[]
  dimensions?: { description: string }
  capabilities?: { video: boolean }
  images: SearchImage[]
  masterImage: SearchImage
}

export type SearchRating = {
  count: number
  score: number
}

export type SearchCategory = {
  id: number
  slug: string
  name: string
  department: DepartmentsEnum
}

export type ProductPill = {
  displayLabel: string
  displayVariant: ProductPillType
}

export type SearchProduct = {
  id: string
  title: string
  slug: string
  dependencies: Dependencies[]
  customisable: boolean
  isLandscape: boolean
  category: SearchCategory
  rating?: SearchRating
  masterVariant: SearchMasterVariant
  variants: SearchVariant[]
  hasAugmentedReality: boolean
  publishDate?: string
  productPills: ProductPill[]
  primaryProductPill?: ProductPill
  productIndex: number
  isSponsored: boolean
  internallyPromoted: boolean
  clickRankDocumentCount: number
}

export type FavouriteProduct = {
  id: string
}

export type DepartmentCount = {
  department: DepartmentsEnum
  count: number
}

export enum FilterItemsTypeEnum {
  FACET = 'FACET',
  TOGGLE_FILTER = 'TOGGLE_FILTER',
  HEADER = 'HEADER',
}

export type FilterServiceFilterItem =
  | FilterServiceFilterHeader
  | FilterServiceFilterFacet

export type FilterServiceFilterHeader = {
  type: FilterItemsTypeEnum.HEADER
  name: string
}

export type FilterServiceFilterFacet = {
  type: FilterItemsTypeEnum.FACET
  facetKey: string
  group: string
  hasAllOption?: boolean
  count: number
  nbaScore?: number
  isSelected?: boolean
  label: string
  children?: FilterServiceFilterItem[]
  userApplied?: boolean
}

export type FilterInput = {
  facetKey: string
  group: string
}

export type SearchFilter = {
  key: string
  group: string
  userApplied?: boolean
}

export type GroupedFilters = {
  group: string
  keys: string[]
  userApplied?: boolean
}

export type SearchRequestContext = {
  searchTerm: string
  filters: SearchFilter[]
  departments: DepartmentsEnum[]
  sponsoredProducts: SponsoredProductInput[]
  page: number
  excludeRude?: boolean
  promotionId?: string
  sortOrder?: SortOrder
}

export type RecommendationsRequestContext = {
  searchTerm: string
  facetKeys: string[]
}

export type LoadFavouritesRequestContext = {
  loggedIn: boolean
}

export type LoadFiltersRequestContext = {
  departments: DepartmentsEnum[]
  excludeRude?: boolean
  facets: SearchFilter[]
  promotionId?: string
  searchTerm: string
}

export type SearchRequestResponse = {
  products: SearchProduct[]
  suggestions: SearchProduct[]
  departments: DepartmentCount[]
  totalCount: number
  spellingCorrection?: {
    initialSearchTerm: string
    correctedSearchTerm: string
  }
  page: number
  supportedSortOrders: SearchSortOrder[]
  totalClickRankDocumentCount: number
}

export type RecommendationsResponse = {
  recommendationsBySearchCriteria: Omit<
    Omit<SearchProduct, 'dependencies'>,
    'productIndex'
  >[]
}

export type SearchSortOrder = {
  displayText: string
  isDefault: boolean
  value: SortOrder
}

export type LoadFavouritesResponse = {
  favourites: FavouriteProduct[]
}

export type LoadFiltersResponse = {
  facets: FilterServiceFilterItem[]
  filters: FilterItem[]
}

export type SearchRequest = (
  context: SearchRequestContext,
) => Promise<SearchRequestResponse>

export type RecommendationsRequest = (
  context: RecommendationsRequestContext,
) => Promise<RecommendationsResponse>

export type LoadFavouritesRequest = (
  context: LoadFavouritesRequestContext,
) => Promise<LoadFavouritesResponse>

export type LoadFiltersRequest = (
  context: LoadFiltersRequestContext,
) => Promise<LoadFiltersResponse>

export type SearchService = {
  search: SearchRequest
  loadRecommendations: RecommendationsRequest
  loadFavourites: LoadFavouritesRequest
  loadFilters: LoadFiltersRequest
}

export type FilterHeader = {
  __typename: 'FilterHeader'
  label: string
}

export type Filter = {
  __typename: 'Filter'
  id: string
  parent: string
  count: number
  isSelected?: boolean
  label: string
  nbaScore?: number
}

export type AllFilter = {
  __typename: 'AllFilter'
  id: string
  parent: string
  isSelected?: boolean
  label: string
}

export type FilterToggle = {
  __typename: 'FilterToggle'
  id: string
  parent: string
  isSelected?: boolean
  label: string
  icon?: string
}

export type InvertedFilterToggle = {
  __typename: 'FilterInvertedToggle'
  id: string
  parent: string
  isSelected?: boolean
  label: string
  icon?: string
}

export type FilterCategory = {
  __typename: 'FilterCategory'
  id: string
  parent: string
  count: number
  label: string
  children: FilterItem[]
  nbaScore?: number
}

export type ApplicableFilter =
  | Filter
  | FilterToggle
  | InvertedFilterToggle
  | AllFilter

export type FilterItem = FilterCategory | FilterHeader | ApplicableFilter
