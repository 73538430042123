import { FilterServiceFilterItem } from '../../services/types/services'

type RecursivelyRemovePresetFacetsProps = {
  data: FilterServiceFilterItem[]
  presetFacets?: { facetKey: string; group: string }[]
  suggestionFacetKey?: string
  department?: string
}

export const recursivelyRemovePresetFacets = (
  props: RecursivelyRemovePresetFacetsProps,
): FilterServiceFilterItem[] => {
  const { data, presetFacets, suggestionFacetKey, department } = props

  const filterChildren = (
    facets: FilterServiceFilterItem[],
    parentDepartment?: string,
  ) => {
    return facets?.map(facet => {
      if ('facetKey' in facet) {
        return {
          ...facet,
          children: recursivelyRemovePresetFacets({
            data: facet.children || [],
            presetFacets,
            suggestionFacetKey,
            department: parentDepartment,
          }),
        }
      }
      /* istanbul ignore next */
      return facet
    })
  }

  const presetFacetKeys = presetFacets && [
    ...new Set(
      presetFacets
        .filter(x => presetFacets.filter(p => p.group === x.group).length === 1)
        .map(x => x.facetKey),
    ),
  ]

  const availableFacets = data?.filter(x =>
    'facetKey' in x
      ? !(presetFacetKeys && presetFacetKeys.includes(x.facetKey)) &&
        !(suggestionFacetKey && suggestionFacetKey === x.facetKey)
      : true,
  )

  if (department) {
    const filteredChildren = filterChildren(availableFacets, department)
    return filteredChildren as FilterServiceFilterItem[]
  }

  return availableFacets
}
