import React, { FC, memo, PropsWithChildren } from 'react'
import { styled, breakpointDown, breakpointUp } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Box, Text } from '@moonpig/launchpad-components'
import { MultiSelectFilterOption } from './MultiSelectFilterOption'
import { FilterSelections } from './FilterSelections'
import { LAYOUT_BREAKPOINT } from './constants'
import {
  shallowCompare,
  facetsAreEqual,
  getFilterFacet,
  getFilterHeader,
} from '../../utils'
import { AllSelectedChangedEvent, FilterChangedEvent } from './types'
import {
  FilterServiceFilterFacet,
  FilterServiceFilterItem,
} from '../../services/types/services'
import { FiltersPageType } from '../types'

const StyledHeaderText = styled(Text)`
  ${s({
    typography: 'typeDisplay06',
  })}
`

const StyledHeaderBox = styled(Box)`
  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    ${s({
      ml: 8,
      mt: 6,
    })}
  }
  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    ${s({
      ml: 10,
    })}
  }
`

type FilterOptionProps = {
  facet: FilterServiceFilterItem
  checked: boolean
  selected: boolean
  openFilterCategory: string | undefined
  allowClear: boolean
  isMixedView: boolean
  selectedChildren: FilterServiceFilterFacet[]
  filterChanged: FilterChangedEvent
  setOpenFilterCategory: (facetKey: string) => void
  allSelectedChanged: AllSelectedChangedEvent
  onClearAll: () => void
  pageType: FiltersPageType
}

const FilterOptionComponent: FC<FilterOptionProps> = props => {
  const {
    facet,
    setOpenFilterCategory,
    openFilterCategory,
    selectedChildren,
    allowClear,
    selected,
    isMixedView,
    filterChanged,
    onClearAll,
    allSelectedChanged,
    pageType,
  } = props

  const filterHeader = getFilterHeader(facet)

  if (filterHeader) {
    return (
      <StyledHeaderBox>
        <StyledHeaderText>{filterHeader.name}</StyledHeaderText>
      </StyledHeaderBox>
    )
  }

  const filterFacet = getFilterFacet(facet)

  /* istanbul ignore next */
  if (filterFacet) {
    return (
      <li key={filterFacet.facetKey}>
        {((filterFacet.children?.length ?? 0) > 0 && (
          <FilterSelections
            facet={filterFacet}
            setOpenFilterCategory={setOpenFilterCategory}
            openFilterCategory={openFilterCategory}
            filterChanged={filterChanged}
            onClearAll={onClearAll}
            allowClear={allowClear}
            selected={selected}
            selectedChildren={selectedChildren}
            isMixedView={isMixedView}
            key={filterFacet.facetKey}
            allSelectedChanged={allSelectedChanged}
            pageType={pageType}
          />
        )) || (
          <MultiSelectFilterOption
            {...props}
            {...filterFacet}
            key={filterFacet.facetKey}
          />
        )}
      </li>
    )
  }
  /* istanbul ignore next */
  return null
}

/* istanbul ignore next */
const propsAreEqual = (
  prevProps: Readonly<PropsWithChildren<FilterOptionProps>>,
  nextProps: Readonly<PropsWithChildren<FilterOptionProps>>,
): boolean => {
  const {
    facet: firstFacet,
    selectedChildren: firstSelectedChildren,
    ...firstProps
  } = prevProps
  const {
    facet: secondFacet,
    selectedChildren: secondSelectedChildren,
    ...secondProps
  } = nextProps

  return (
    shallowCompare(firstProps, secondProps) &&
    (firstFacet === secondFacet ||
      facetsAreEqual(
        getFilterFacet(firstFacet),
        getFilterFacet(secondFacet),
      )) &&
    (firstSelectedChildren === secondSelectedChildren ||
      (firstSelectedChildren.length === 0 &&
        secondSelectedChildren.length === 0) ||
      (firstSelectedChildren.length === secondSelectedChildren.length &&
        firstSelectedChildren
          .map((x, i) => facetsAreEqual(x, secondSelectedChildren[i]))
          .every(x => x)))
  )
}

export const FilterOption = memo(FilterOptionComponent, propsAreEqual)
