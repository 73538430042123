import React, { FC, PropsWithChildren } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'

const StyledSlidingPanel = styled(Box).attrs(
  ({ isOpen }: { isOpen: boolean }) => ({
    className: `${isOpen ? 'is-open' : 'is-closed'}`,
  }),
)<{ isOpen: boolean; level: number }>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  ${({ level }) => `z-index: ${level};`}

  &.is-open {
    animation: slide-in 450ms cubic-bezier(0.56, 0.1, 0.34, 0.91) forwards;
  }

  &.is-closed {
    animation: slide-out 450ms cubic-bezier(0.56, 0.1, 0.34, 0.91) forwards;
  }

  @media (prefers-reduced-motion) {
    animation: none;
    &.is-open {
      transform: translateX(0);
    }
  }

  @keyframes slide-in {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`

type Props = {
  level: number
  onClose?: () => void
  isOpen: boolean
  id: string
  onAnimationEnd: () => void
}

export const SlidingPanel: FC<PropsWithChildren<Props>> = ({
  onClose,
  children,
  level,
  isOpen,
  id,
  onAnimationEnd,
}) => {
  const handleOnAnimationEnd = () => {
    if (!isOpen && onClose) {
      onClose()
    }
    onAnimationEnd()
  }

  return (
    <StyledSlidingPanel
      data-testid="sliding-panel"
      isOpen={isOpen}
      level={level}
      onAnimationEnd={handleOnAnimationEnd}
      id={id}
    >
      {children}
    </StyledSlidingPanel>
  )
}
