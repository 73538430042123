import React, { FC, memo, PropsWithChildren } from 'react'
import { styled, breakpointDown, breakpointUp } from '@moonpig/launchpad-utils'
import { Box, Text } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { SlidingOption } from './SlidingOption'
import { SlidingPanel } from './SlidingPanel'
import { MultiSelectFilterOption } from './MultiSelectFilterOption'
import { ContextualHeader } from './ContextualHeader'
import { LAYOUT_BREAKPOINT } from './constants'
import {
  areAllChildrenSelected,
  facetsAreEqual,
  reduceFilterFacets,
  selectedAllFilters,
  shallowCompare,
} from '../../utils'
import { ListWithAllOption } from './ListWithAllOption'
import { FilterChangedEvent, AllSelectedChangedEvent } from './types'
import { FilterServiceFilterFacet } from '../../services/types/services'
import { FiltersPageType } from '../types'

const MobileOnly = styled.div`
  display: auto;
  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    display: none;
  }
`

const StyledAllList = styled(ListWithAllOption)`
  ${s({
    pb: 6,
    mb: 1,
  })}
  width: 100%;
  height: 100%;
  min-width: 295px;
  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    ${s({
      pb: 6,
      mb: 15,
      px: 8,
    })}
    margin-top: 66px;
    height: auto;
  }
`

const StyledHeaderText = styled(Text)`
  ${s({
    typography: 'typeDisplay06',
  })}
`

const StyledHeaderBox = styled(Box)`
  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    ${s({
      ml: 5,
      mt: 4,
    })}
  }
  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    ${s({
      ml: 10,
    })}
  }
`

type FilterSelectionsProps = {
  facet: FilterServiceFilterFacet
  openFilterCategory: string | undefined
  allowClear: boolean
  selected: boolean
  isMixedView: boolean
  selectedChildren: FilterServiceFilterFacet[]
  filterChanged: FilterChangedEvent
  setOpenFilterCategory: (facetKey: string) => void
  onClearAll: () => void
  allSelectedChanged: AllSelectedChangedEvent
  pageType: FiltersPageType
}

const FilterSelectionsComponent: FC<FilterSelectionsProps> = ({
  facet,
  openFilterCategory,
  allowClear,
  selected,
  isMixedView,
  selectedChildren,
  setOpenFilterCategory,
  filterChanged,
  onClearAll,
  allSelectedChanged,
  pageType,
}) => {
  const { group, facetKey, label, children, hasAllOption } = facet

  const childKeys = reduceFilterFacets(
    children || /* istanbul ignore next */ [],
  ).flatMap(x => x.facetKey)

  const currentSelections: FilterServiceFilterFacet[] = selectedChildren.filter(
    appliedFacet => childKeys.includes(appliedFacet.facetKey),
  )

  return (
    <SlidingOption
      root={false}
      facetKey={facetKey}
      label={label}
      isOptionSelected={facetKey === openFilterCategory}
      selectedChildren={currentSelections}
      selected={currentSelections.length > 0}
      isMixedView={isMixedView}
      onSelection={setOpenFilterCategory}
      allSelected={selectedAllFilters(facet, currentSelections)}
      pageType={pageType}
    >
      <SlidingPanel>
        <MobileOnly>
          <ContextualHeader
            title={label}
            onBack={() => {
              setOpenFilterCategory('')
            }}
            onClear={onClearAll}
            allowClear={allowClear}
          />
        </MobileOnly>

        <StyledAllList
          group={group}
          label={label}
          facetKey={facetKey}
          hasAllOption={hasAllOption}
          selected={areAllChildrenSelected(facet, selectedChildren)}
          selectedChanged={removed =>
            allSelectedChanged(
              removed,
              reduceFilterFacets(children || /* istanbul ignore next */ []).map(
                x => x.facetKey,
              ),
              facetKey,
            )
          }
        >
          {children &&
            children.map(currentItem => {
              return 'name' in currentItem ? (
                <StyledHeaderBox key={currentItem?.name}>
                  <StyledHeaderText>{currentItem.name}</StyledHeaderText>
                </StyledHeaderBox>
              ) : (
                <MultiSelectFilterOption
                  {...currentItem}
                  key={currentItem?.label}
                  checked={
                    !!currentSelections.find(
                      selection => selection.facetKey === currentItem.facetKey,
                    )
                  }
                  filterChanged={filterChanged}
                  selected={selected}
                />
              )
            })}
        </StyledAllList>
      </SlidingPanel>
    </SlidingOption>
  )
}

/* istanbul ignore next */
const propsAreEqual = (
  prevProps: Readonly<PropsWithChildren<FilterSelectionsProps>>,
  nextProps: Readonly<PropsWithChildren<FilterSelectionsProps>>,
): boolean => {
  const {
    facet: firstFacet,
    selectedChildren: firstSelectedChildren,
    ...firstProps
  } = prevProps
  const {
    facet: secondFacet,
    selectedChildren: secondSelectedChildren,
    ...secondProps
  } = nextProps

  return (
    shallowCompare(firstProps, secondProps) &&
    (firstFacet === secondFacet || facetsAreEqual(firstFacet, secondFacet)) &&
    (firstSelectedChildren === secondSelectedChildren ||
      (firstSelectedChildren.length === secondSelectedChildren.length &&
        firstSelectedChildren
          .map((x, i) => facetsAreEqual(x, secondSelectedChildren[i]))
          .every(x => x)))
  )
}

export const FilterSelections = memo(FilterSelectionsComponent, propsAreEqual)
