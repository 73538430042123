import React, { FC, useMemo, useState } from 'react'
import { styled, breakpointUp, breakpointDown } from '@moonpig/launchpad-utils'
import { spacingPx } from '@moonpig/launchpad-theme'
import {
  CarouselRoot,
  CarouselControls,
  CarouselState,
  CarouselPreviousButton,
  CarouselNextButton,
  CarouselItem,
  UnstyledCarouselScrollArea,
  Chip,
  Flex,
  Box,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { useRouter } from '@moonpig/web-core-routing'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { FilterAction, FilterSource } from '../../types'
import { useProductListingPageContext } from '../../utils/productListingPageContext'
import { updateRoute } from '../../utils'
import { useFindLocaleText } from '../../text-localisation'
import { filtersOpenGAEventDetails } from '../../analytics/GAEvents'
import { FilterServiceFilterFacet } from '../../services/types/services'
import { FiltersMenuButton } from './FiltersMenuButton'
import { AllFiltersButton } from './AllFiltersButton'

const StyledFlex = styled(Flex)`
  ${breakpointUp('md')} {
    width: 100%;
  }
  padding-top: ${spacingPx(4)};
  padding-bottom: ${spacingPx(4)};
  width: 100vw;
`

const StyledCarouselItem = styled(CarouselItem)`
  height: 48px;
  overflow: hidden;
`

const StyledCarouselRoot = styled(CarouselRoot)`
  ${s({
    paddingLeft: 0,
    paddingRight: 0,
  })}
`

const StyledCarouselScrollArea = styled(UnstyledCarouselScrollArea)`
  gap: ${spacingPx(4)};
`

const StyledScrollButtonsWrapper = styled(Box)`
  ${breakpointDown('md')} {
    display: none;
  }
`

type Props = {
  title: string
  onFiltersClicked: () => void
  modalOpen: boolean
  availableNextBestActions?: FilterServiceFilterFacet[]
}

export const FiltersCarousel: FC<Props> = ({
  title,
  onFiltersClicked,
  modalOpen,
  availableNextBestActions = [],
}) => {
  const [state, dispatch] = useProductListingPageContext()
  const router = useRouter()
  const localiseText = useFindLocaleText()
  const { filtersLoading } = state
  const [filtersButtonInView, setFiltersButtonInView] = useState(false)

  const showNextBestActions =
    !filtersLoading && state.applyFilterData.facets.length < 8

  const showAllFiltersButton = !filtersLoading

  const onFiltersClickedCallback = React.useCallback(() => {
    trackGAEvent(filtersOpenGAEventDetails(title))
    onFiltersClicked()
  }, [title, onFiltersClicked])

  const nextBestActionFacets = availableNextBestActions
    .map(facet => ({ ...facet, nbaScore: facet.nbaScore ?? 0 }))
    .filter(facet => facet.nbaScore)

  const removeFilter = (selectedFacetKey: string) => {
    const applyFilterData = {
      facetKeys: [selectedFacetKey],
      selectedFacetKey,
      details: {
        sender: FilterSource.NextBestAction,
        action: FilterAction.Remove,
      },
    }
    updateRoute(
      state.applyFilterData.facets,
      [state.filterLookup[selectedFacetKey]],
      router,
      'REMOVE',
    )
    dispatch({
      type: 'REMOVE_FILTERS',
      payload: {
        applyFilterData,
      },
    })
  }

  const applyNba = (selectedNba: FilterServiceFilterFacet) => {
    const applyFilterData = {
      facetKeys: [selectedNba.facetKey],
      selectedFacetKey: selectedNba.facetKey,
      details: {
        sender: FilterSource.NextBestAction,
        action: FilterAction.Add,
      },
    }
    updateRoute(state.applyFilterData.facets, [selectedNba], router, 'ADD')
    dispatch({
      type: 'ADD_FILTERS',
      payload: {
        applyFilterData,
      },
    })
  }

  const filtersButton = useMemo(() => {
    return (
      <StyledCarouselItem tabIndex={-1} key={`open-filters-button`}>
        <FiltersMenuButton
          onInView={setFiltersButtonInView}
          onFiltersClickedCallback={onFiltersClickedCallback}
        />
      </StyledCarouselItem>
    )
  }, [onFiltersClickedCallback])

  const allFiltersButton = useMemo(() => {
    const displayButton =
      !modalOpen && showAllFiltersButton && !filtersButtonInView
    return (
      <StyledCarouselItem key={`view-all-filters-button`} tabIndex={-1}>
        <AllFiltersButton
          displayButton={displayButton}
          onFiltersClickedCallback={onFiltersClickedCallback}
        />
      </StyledCarouselItem>
    )
  }, [
    filtersButtonInView,
    modalOpen,
    onFiltersClickedCallback,
    showAllFiltersButton,
  ])

  const appliedFilters = state.applyFilterData.facets
    .filter(x => !!state.filterLookup[x.facetKey])
    .map(x => ({
      ...x,
      label:
        x.label === 'default' ? state.filterLookup[x.facetKey]?.label : x.label,
      isSelected: true,
    }))

  const appliedFilterChips = appliedFilters.map(facet => {
    return (
      <StyledCarouselItem key={`${facet.facetKey}-applied`} tabIndex={-1}>
        <Chip
          dismissible
          selected
          onClick={() => removeFilter(facet.facetKey)}
          aria-label={localiseText(
            'find.remove_facet_group_filter_facet_label',
            {
              group: facet.group,
              label: facet.label,
            },
          )}
        >
          {facet.label}
        </Chip>
      </StyledCarouselItem>
    )
  })

  const nextBestActionFilters = showNextBestActions
    ? nextBestActionFacets
        .sort((a, b) => a.nbaScore - b.nbaScore)
        .map(facet => (
          <StyledCarouselItem
            key={`${facet.facetKey}-available-nbas`}
            tabIndex={-1}
          >
            <Chip
              dismissible
              selected={false}
              onClick={() => applyNba(facet)}
              aria-label={localiseText(
                'find.apply_facet_group_filter_facet_label',
                {
                  group: facet.group,
                  label: facet.label.toLowerCase(),
                },
              )}
            >
              {facet.label}
            </Chip>
          </StyledCarouselItem>
        ))
    : [
        <StyledCarouselItem key={'empty-nbas'}>
          <></>
        </StyledCarouselItem>,
      ]

  const carouselItems = [
    filtersButton,
    ...appliedFilterChips,
    ...nextBestActionFilters,
    allFiltersButton,
  ]

  return (
    <StyledFlex
      flexDirection={'column'}
      marginBottom={6}
      data-testid="filters-carousel"
    >
      {carouselItems.length > 0 && (
        <CarouselState>
          <StyledCarouselRoot aria-label={localiseText('find.nba_filters')}>
            <StyledScrollButtonsWrapper>
              <CarouselControls>
                <CarouselPreviousButton />
                <CarouselNextButton />
              </CarouselControls>
            </StyledScrollButtonsWrapper>
            <StyledCarouselScrollArea>{carouselItems}</StyledCarouselScrollArea>
          </StyledCarouselRoot>
        </CarouselState>
      )}
    </StyledFlex>
  )
}
