/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { FC } from 'react'
import { MultiSelectFilterOption } from './MultiSelectFilterOption'
import { useFindLocaleText } from '../../text-localisation'

export type ListWithAllOptionProps = {
  group: string
  label?: string
  facetKey: string
  hasAllOption?: boolean
  selected: boolean
  selectedChanged: (removed: boolean) => void
} & React.HTMLProps<HTMLUListElement>

export const ListWithAllOption: FC<ListWithAllOptionProps> = ({
  group,
  label,
  facetKey,
  hasAllOption,
  selected,
  selectedChanged,
  children,
  ...listProps
}) => {
  const localiseText = useFindLocaleText()

  const allLabel = localiseText('find.all', { label })

  return (
    <ul {...listProps} role="list">
      {hasAllOption && (
        <MultiSelectFilterOption
          filterChanged={(removed, _facetKey) => selectedChanged(removed)}
          label={allLabel}
          group={group}
          facetKey={facetKey}
          checked={selected}
          selected={selected}
        />
      )}
      {children}
    </ul>
  )
}
