import { DepartmentsEnum } from '@moonpig/web-explore-types-graphql'
import { useEffect, useRef } from 'react'
import { SearchFilter } from '../../services'
import { filtersAreEqual } from '../filtersAreEqual'

type UseWatchFilterParametersProps = {
  departments: DepartmentsEnum[]
  excludeRude: boolean
  facets: SearchFilter[]
  promotionId?: string
  searchTerm: string
  loadFiltersCallback: () => void
}

const departmentsAreEqual = (
  first: DepartmentsEnum[],
  second: DepartmentsEnum[],
) => {
  return (
    first.length === second.length &&
    first.every((dept, index) => dept === second[index])
  )
}

export const useWatchFilterParameters: (
  props: UseWatchFilterParametersProps,
) => void = ({
  departments,
  excludeRude,
  facets,
  promotionId = '',
  searchTerm,
  loadFiltersCallback,
}) => {
  // This hook watches for changes in any of the parameters.
  // If any of the parameters change, it calls the callback function.

  const currentDepartments = useRef([] as DepartmentsEnum[])
  const currentExcludeRude = useRef(false)
  const currentFacets = useRef([] as SearchFilter[])
  const currentPromotionId = useRef('')
  const currentSearchTerm = useRef('')

  useEffect(() => {
    if (
      !departmentsAreEqual(departments, currentDepartments.current) ||
      excludeRude !== currentExcludeRude.current ||
      !filtersAreEqual(facets, currentFacets.current) ||
      promotionId !== currentPromotionId.current ||
      searchTerm !== currentSearchTerm.current
    ) {
      currentDepartments.current = departments
      currentExcludeRude.current = excludeRude
      currentFacets.current = facets
      currentDepartments.current = departments
      currentPromotionId.current = promotionId
      currentSearchTerm.current = searchTerm
      loadFiltersCallback()
    }
  }, [
    departments,
    excludeRude,
    facets,
    promotionId,
    searchTerm,
    loadFiltersCallback,
    currentDepartments,
    currentExcludeRude,
    currentFacets,
    currentPromotionId,
    currentSearchTerm,
  ])
}
