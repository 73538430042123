import React, { FC, useState } from 'react'
import { SortOrder } from '@moonpig/web-explore-types-graphql'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { useProductListingPageContext } from '../../utils/productListingPageContext'
import { useFindLocaleText } from '../../text-localisation'
import { generateSelectSortOrderGAEvent } from '../../analytics/GAEvents'
import { SortByFilter } from './SortByFilter'
import { getSortByOptions } from '../../utils'

type ActionToggleSortBy =
  | SortOrder.POPULARITY
  | SortOrder.PRICE_ASCENDING
  | SortOrder.PRICE_DESCENDING
  | SortOrder.NEWNESS

export const SortBy: FC<{
  parentDepartment: string
  setOpenFilterCategory?: (value: string | undefined) => void
  openFilterCategory?: string
}> = ({ parentDepartment, setOpenFilterCategory, openFilterCategory }) => {
  const [{ sortOrder }, dispatch] = useProductListingPageContext()
  const [sortByValue, setSortByValue] = useState(sortOrder as string)

  const isCards = parentDepartment === 'ALL_CARDS'

  const applySortBy = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'TOGGLE_SORT_BY',
      payload: event.target.value as ActionToggleSortBy,
    })
    setSortByValue(event.target.value)
    trackGAEvent(generateSelectSortOrderGAEvent(event.target.value))
  }
  const localiseText = useFindLocaleText()
  const sortByOptions = getSortByOptions({ isCards, localiseText })

  return (
    <>
      {setOpenFilterCategory && (
        <SortByFilter
          name={''}
          label={localiseText('find.sort_by')}
          value={sortByValue}
          options={sortByOptions}
          onChange={applySortBy}
          setOpenFilterCategory={setOpenFilterCategory}
          openFilterCategory={openFilterCategory}
        />
      )}
    </>
  )
}
