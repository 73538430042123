import { FilterServiceFilterFacet } from '../../services/types/services'
import { reduceFilterFacets } from './filterItemUtils'

export const recursiveFlatMap = (
  allFacets: FilterServiceFilterFacet[],
): {
  facetKey: string
  group: string
}[] => {
  const current = allFacets
    .filter(x => !x.children?.length)
    .map(x => {
      return {
        group: x.group,
        facetKey: x.facetKey,
      }
    })

  const currentChildren = allFacets.flatMap(x =>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    x.children?.length ? recursiveFlatMap(reduceFilterFacets(x.children)) : [],
  )

  return [...current, ...currentChildren]
}

export const flattenAllFacets = (
  allFacets: FilterServiceFilterFacet[],
): {
  label: string
  facetKey: string
}[] => {
  const flattened: {
    label: string
    facetKey: string
  }[] = []

  allFacets.forEach(facet => {
    flattened.push({
      label: facet.label,
      facetKey: facet.facetKey,
    })
    const isNode = facet.children && facet.children?.length > 0
    // if it is a node (has children) then pass it's children to the resursive function
    if (isNode) {
      facet.children?.forEach(childFacet => {
        flattened.push(
          ...flattenAllFacets([childFacet as FilterServiceFilterFacet]),
        )
      })
    }
  })

  return flattened
}

export const areAllChildrenSelected = (
  facet: FilterServiceFilterFacet,
  selectedFacets: FilterServiceFilterFacet[],
): boolean => {
  const facetChildren = reduceFilterFacets(facet.children || [])
  const currentFacetChildrenSelected =
    !facetChildren.length ||
    facetChildren.every(
      c => c.children?.length || isFacetSelected(c, selectedFacets),
    )

  const allSelected =
    currentFacetChildrenSelected &&
    (!facetChildren ||
      facetChildren.every(c => areAllChildrenSelected(c, selectedFacets)))

  return allSelected
}

export const selectedAllFilters = (
  facet: FilterServiceFilterFacet,
  selectedFacets: FilterServiceFilterFacet[],
): { label: string; includedKeys: string[] }[] => {
  const facetChildren = reduceFilterFacets(facet.children || [])
  const currentFacetChildrenSelected =
    !facetChildren.length ||
    facetChildren.every(
      c => c.children?.length || isFacetSelected(c, selectedFacets),
    )

  const allSelected =
    currentFacetChildrenSelected &&
    (!facetChildren ||
      facetChildren.every(c => areAllChildrenSelected(c, selectedFacets)))

  if (allSelected) {
    return facet.children?.length
      ? [
          {
            label: facet.label,
            includedKeys: facetChildren.map(x => x.facetKey),
          },
        ]
      : []
  }

  return facetChildren.flatMap(c => selectedAllFilters(c, selectedFacets))
}

export const selectedDescendants = (
  facet: FilterServiceFilterFacet,
  selectedFacets: FilterServiceFilterFacet[],
): FilterServiceFilterFacet[] => {
  const facetChildren = reduceFilterFacets(facet.children || [])
  const selectedChildren = facetChildren.filter(c =>
    isFacetSelected(c, selectedFacets),
  )
  if (facetChildren.some(child => child.children?.length)) {
    return [
      ...selectedChildren,
      ...facetChildren.flatMap(c1 => selectedDescendants(c1, selectedFacets)),
    ]
  }

  return selectedChildren
}

export const isFacetSelected = (
  facet: FilterServiceFilterFacet,
  selectedFacets: FilterServiceFilterFacet[],
) => {
  return selectedFacets.some(
    selectedFacet => selectedFacet.facetKey === facet.facetKey,
  )
}
