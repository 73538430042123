export const facetMappings: {
  [key: string]: { group: string; key: string; label?: string }
} = {
  'birthday milestones': {
    key: 'birthday-milestones',
    group: 'birthday milestones',
    label: 'Birthday Milestones',
  },
  '1st birthday': {
    key: '1st-birthday',
    group: 'birthday milestones',
    label: '1st Birthday',
  },
  '2nd birthday': {
    key: '2nd-birthday',
    group: 'birthday milestones',
    label: '2nd Birthday',
  },
  '3rd birthday': {
    key: '3rd-birthday',
    group: 'birthday milestones',
    label: '3rd Birthday',
  },
  '4th birthday': {
    key: '4th-birthday',
    group: 'birthday milestones',
    label: '4th Birthday',
  },
  '5th birthday': {
    key: '5th-birthday',
    group: 'birthday milestones',
    label: '5th Birthday',
  },
  '6th birthday': {
    key: '6th-birthday',
    group: 'birthday milestones',
    label: '6th Birthday',
  },
  '7th birthday': {
    key: '7th-birthday',
    group: 'birthday milestones',
    label: '7th Birthday',
  },
  '8th birthday': {
    key: '8th-birthday',
    group: 'birthday milestones',
    label: '8th Birthday',
  },
  '9th birthday': {
    key: '9th-birthday',
    group: 'birthday milestones',
    label: '9th Birthday',
  },
  '10th birthday': {
    key: '10th-birthday',
    group: 'birthday milestones',
    label: '10th Birthday',
  },
  '11th birthday': {
    key: '11th-birthday',
    group: 'birthday milestones',
    label: '11th Birthday',
  },
  '12th birthday': {
    key: '12th-birthday',
    group: 'birthday milestones',
    label: '12th Birthday',
  },
  '13th birthday': {
    key: '13th-birthday',
    group: 'birthday milestones',
    label: '13th Birthday',
  },
  '14th birthday': {
    key: '14th-birthday',
    group: 'birthday milestones',
    label: '14th Birthday',
  },
  '15th birthday': {
    key: '15th-birthday',
    group: 'birthday milestones',
    label: '15th Birthday',
  },
  '16th birthday': {
    key: '16th-birthday',
    group: 'birthday milestones',
    label: '16th Birthday',
  },
  '17th birthday': {
    key: '17th-birthday',
    group: 'birthday milestones',
    label: '17th Birthday',
  },
  '18th birthday': {
    key: '18th-birthday',
    group: 'birthday milestones',
    label: '18th Birthday',
  },
  '19th birthday': {
    key: '19th-birthday',
    group: 'birthday milestones',
    label: '19th Birthday',
  },
  '20th birthday': {
    key: '20th-birthday',
    group: 'birthday milestones',
    label: '20th Birthday',
  },
  '21st birthday': {
    key: '21st-birthday',
    group: 'birthday milestones',
    label: '21st Birthday',
  },
  '25th birthday': {
    key: '25th-birthday',
    group: 'birthday milestones',
    label: '25th Birthday',
  },
  '30th birthday': {
    key: '30th-birthday',
    group: 'birthday milestones',
    label: '30th Birthday',
  },
  '40th birthday': {
    key: '40th-birthday',
    group: 'birthday milestones',
    label: '40th Birthday',
  },
  '50th birthday': {
    key: '50th-birthday',
    group: 'birthday milestones',
    label: '50th Birthday',
  },
  '60th birthday': {
    key: '60th-birthday',
    group: 'birthday milestones',
    label: '60th Birthday',
  },
  '70th birthday': {
    key: '70th-birthday',
    group: 'birthday milestones',
    label: '70th Birthday',
  },
  '80th birthday': {
    key: '80th-birthday',
    group: 'birthday milestones',
    label: '80th Birthday',
  },
  '90th birthday': {
    key: '90th-birthday',
    group: 'birthday milestones',
    label: '90th Birthday',
  },
  '100th birthday': {
    key: '100th-birthday',
    group: 'birthday milestones',
    label: '100th Birthday',
  },
  other: {
    key: 'birthday-milestones-other',
    group: 'birthday-milestones-other',
  },
  unspecified: {
    key: 'birthday-milestones-unspecified',
    group: 'birthday-milestones-unspecified',
  },
  "who's it for?": {
    key: 'gift-experiences-whos-it-for',
    label: "Who's It For?",
    group: 'gift-experiences-whos-it-for',
  },
  'for her': { key: 'for-her', group: "who's it for?", label: 'For Her' },
  'for him': { key: 'for-him', group: "who's it for?", label: 'For Him' },
  'for kids': {
    key: 'whos-it-for-for-kids',
    group: "who's it for?",
    label: 'For Kids',
  },
  'for boys': { key: 'for-boys', group: "who's it for?", label: 'For Boys' },
  'for girls': { key: 'for-girls', group: "who's it for?", label: 'For Girls' },
  auntie: { key: 'whos-it-for-auntie', group: "who's it for?" },
  'best friend': {
    key: 'best-friend',
    group: "who's it for?",
    label: 'Best Friend',
  },
  boyfriend: { key: 'whos-it-for-boyfriend', group: "who's it for?" },
  bridesmaid: { key: 'bridesmaid', group: "who's it for?" },
  brother: { key: 'whos-it-for-brother', group: "who's it for?" },
  'brother and partner': {
    key: 'brother-and-partner',
    group: "who's it for?",
    label: 'Brother and Partner',
  },
  'brother in law': {
    key: 'whos-it-for-brother-in-law',
    group: "who's it for?",
    label: 'Brother in Law',
  },
  colleague: { key: 'colleague', group: "who's it for?" },
  couple: { key: 'whos-it-for-couple', group: "who's it for?" },
  cousin: { key: 'cousin', group: "who's it for?" },
  dad: { key: 'whos-it-for-dad', group: "who's it for?" },
  'dad and partner': {
    key: 'dad-and-partner',
    group: "who's it for?",
    label: 'Dad and Partner',
  },
  'daddy to be': {
    key: 'daddy-to-be',
    group: "who's it for?",
    label: 'Dad to Be',
  },
  daughter: { key: 'whos-it-for-daughter', group: "who's it for?" },
  'daughter and partner': {
    key: 'daughter-and-partner',
    group: "who's it for?",
    label: 'Daughter and Partner',
  },
  'daughter in law': {
    key: 'daughter-in-law',
    group: "who's it for?",
    label: 'Daughter in Law',
  },
  'father in law': {
    key: 'father-in-law',
    group: "who's it for?",
    label: 'Father in Law',
  },
  fiance: { key: 'whos-it-for-fiance', group: "who's it for?" },
  fiancee: { key: 'whos-it-for-fiancee', group: "who's it for?" },
  friend: { key: 'friend', group: "who's it for?" },
  'from the bump': {
    key: 'from-the-bump',
    group: "who's it for?",
    label: 'From the Bump',
  },
  'from the dog': {
    key: 'from-the-dog',
    group: "who's it for?",
    label: 'From the Dog',
  },
  'from the family': {
    key: 'from-the-family',
    group: "who's it for?",
    label: 'From the Family',
  },
  'from the kids': {
    key: 'from-the-kids',
    group: "who's it for?",
    label: 'From the Kids',
  },
  'group cards multiple signatures': {
    key: 'group-cards-multiple-signatures',
    group: 'group-cards-multiple-signatures',
  },
  girlfriend: { key: 'whos-it-for-girlfriend', group: "who's it for?" },
  goddaughter: { key: 'whos-it-for-goddaughter', group: "who's it for?" },
  godfather: { key: 'godfather', group: "who's it for?" },
  godmother: { key: 'godmother', group: "who's it for?" },
  godson: { key: 'whos-it-for-godson', group: "who's it for?" },
  grandad: { key: 'whos-it-for-grandad', group: "who's it for?" },
  granddaughter: { key: 'whos-it-for-granddaughter', group: "who's it for?" },
  grandma: { key: 'grandma', group: "who's it for?" },
  grandparents: { key: 'grandparents', group: "who's it for?" },
  grandson: { key: 'whos-it-for-grandson', group: "who's it for?" },
  'great grandad': {
    key: 'great-grandad',
    group: "who's it for?",
    label: 'Great Grandad',
  },
  'great granddaughter': {
    key: 'great-granddaughter',
    group: "who's it for?",
    label: 'Great Granddaughter',
  },
  'great grandma': {
    key: 'great-grandma',
    group: "who's it for?",
    label: 'Great Grandma',
  },
  'great grandson': {
    key: 'great-grandson',
    group: "who's it for?",
    label: 'Great Grandson',
  },
  husband: { key: 'whos-it-for-husband', group: "who's it for?" },
  'lgbtq+': {
    key: 'whos-it-for-lgbtq',
    group: "who's it for?",
    label: 'LGBTQ+',
  },
  'mother in law': {
    key: 'mother-in-law',
    group: "who's it for?",
    label: 'Mother in Law',
  },
  mum: { key: 'whos-it-for-mum', group: "who's it for?" },
  'mum and dad': {
    key: 'mum-and-dad',
    group: "who's it for?",
    label: 'Mum and Dad',
  },
  'mum and partner': {
    key: 'mum-and-partner',
    group: "who's it for?",
    label: 'Mum and Partner',
  },
  'mum to be': { key: 'mum-to-be', group: "who's it for?", label: 'Mum to Be' },
  nephew: { key: 'whos-it-for-nephew', group: "who's it for?" },
  niece: { key: 'whos-it-for-niece', group: "who's it for?" },
  parents: { key: 'parents', group: "who's it for?" },
  partner: { key: 'partner', group: "who's it for?" },
  pet: { key: 'pet', group: "who's it for?" },
  sister: { key: 'whos-it-for-sister', group: "who's it for?" },
  'sister and partner': {
    key: 'sister-and-partner',
    group: "who's it for?",
    label: 'Sister and Partner',
  },
  'sister in law': {
    key: 'whos-it-for-sister-in-law',
    group: "who's it for?",
    label: 'Sister in Law',
  },
  'someone special': {
    key: 'someone-special',
    group: "who's it for?",
    label: 'Someone Special',
  },
  son: { key: 'whos-it-for-son', group: "who's it for?" },
  'son and partner': {
    key: 'son-and-partner',
    group: "who's it for?",
    label: 'Son and Partner',
  },
  'son in law': {
    key: 'son-in-law',
    group: "who's it for?",
    label: 'Son in Law',
  },
  'step dad': { key: 'step-dad', group: "who's it for?", label: 'Step Dad' },
  'step mum': { key: 'step-mum', group: "who's it for?", label: 'Step Mum' },
  teacher: { key: 'teacher', group: "who's it for?" },
  twins: { key: 'whos-it-for-twins', group: "who's it for?" },
  uncle: { key: 'whos-it-for-uncle', group: "who's it for?" },
  wife: { key: 'whos-it-for-wife', group: "who's it for?" },
  occasion: { key: 'newia-occasion', group: 'occasion' },
  adoption: { key: 'adoption', group: 'occasion' },
  anniversaries: { key: 'anniversaries', group: 'occasion' },
  'all anniversaries': { key: 'all-anniversaries', group: 'all-anniversaries' },
  '1st paper': {
    key: '1st-paper',
    group: 'occasion',
    label: '1st Anniversary – Paper',
  },
  '2nd cotton': {
    key: '2nd-cotton',
    group: 'occasion',
    label: '2nd Anniversary – Cotton',
  },
  '3rd leather': {
    key: '3rd-leather',
    group: 'occasion',
    label: '3rd Anniversary – Leather',
  },
  '4th flowers': {
    key: '4th-flowers',
    group: 'occasion',
    label: '4th Anniversary – Flowers',
  },
  '5th wood': {
    key: '5th-wood',
    group: 'occasion',
    label: '5th Anniversary – Wood',
  },
  '6th sugar': {
    key: '6th-sugar',
    group: 'occasion',
    label: '6th Anniversary – Sugar',
  },
  '7th wool': {
    key: '7th-wool',
    group: 'occasion',
    label: '7th Anniversary – Wool',
  },
  '8th bronze': {
    key: '8th-bronze',
    group: 'occasion',
    label: '8th Anniversary – Bronze',
  },
  '9th pottery': {
    key: '9th-pottery',
    group: 'occasion',
    label: '9th Anniversary – Pottery',
  },
  '10th tin': {
    key: '10th-tin',
    group: 'occasion',
    label: '10th Anniversary – Tin',
  },
  '11th steel': {
    key: '11th-steel',
    group: 'occasion',
    label: '11th Anniversary – Steel',
  },
  '12th silk': {
    key: '12th-silk',
    group: 'occasion',
    label: '12th Anniversary – Silk',
  },
  '13th lace': {
    key: '13th-lace',
    group: 'occasion',
    label: '13th Anniversary – Lace',
  },
  '14th ivory': {
    key: '14th-ivory',
    group: 'occasion',
    label: '14th Anniversary – Ivory',
  },
  '15th crystal': {
    key: '15th-crystal',
    group: 'occasion',
    label: '15th Anniversary – Crystal',
  },
  '20th china': {
    key: '20th-china',
    group: 'occasion',
    label: '20th Anniversary – China',
  },
  '25th silver': {
    key: '25th-silver',
    group: 'occasion',
    label: '25th Anniversary – Silver',
  },
  '30th pearl': {
    key: '30th-pearl',
    group: 'occasion',
    label: '30th Anniversary – Pearl',
  },
  '40th ruby': {
    key: '40th-ruby',
    group: 'occasion',
    label: '40th Anniversary – Ruby',
  },
  '50th gold': {
    key: '50th-gold',
    group: 'occasion',
    label: '50th Anniversary – Gold',
  },
  '60th diamond': {
    key: '60th-diamond',
    group: 'occasion',
    label: '60th Anniversary – Diamond',
  },
  'baby shower': {
    key: 'baby-shower',
    group: 'occasion',
    label: 'Baby Shower',
  },
  birthday: { key: 'occasion-birthday', group: 'occasion' },
  'belated birthday': {
    key: 'belated-birthday',
    group: 'occasion',
    label: 'Belated Birthday',
  },
  'bon voyage': {
    key: 'occasion-bon-voyage',
    group: 'occasion',
    label: 'Bon Voyage',
  },
  'chinese new year': {
    key: 'occasion-chinese-new-year',
    group: 'occasion',
    label: 'Chinese New Year',
  },
  christening: { key: 'christening', group: 'occasion' },
  christmas: { key: 'occasion-christmas', group: 'occasion' },
  'coming out': { key: 'coming-out', group: 'occasion', label: 'Coming Out' },
  congratulations: { key: 'occasion-congratulations', group: 'occasion' },
  'driving test': {
    key: 'occasion-driving-test',
    group: 'occasion',
    label: 'Driving Test',
  },
  easter: { key: 'occasion-easter', group: 'occasion' },
  eid: { key: 'occasion-eid', group: 'occasion' },
  empathy: { key: 'empathy', group: 'occasion' },
  engagement: { key: 'occasion-engagement', group: 'occasion' },
  exams: { key: 'occasion-exams', group: 'occasion' },
  'fathers day': {
    key: 'fathers-day',
    group: 'occasion',
    label: "Father's Day",
  },
  'first day at school': {
    key: 'first-day-at-school',
    group: 'occasion',
    label: 'First Day at School',
  },
  friendship: { key: 'occasion-friendship', group: 'occasion' },
  'galentines day': {
    key: 'galentines-day',
    group: 'occasion',
    label: "Galentine's Day",
  },
  pride: { key: 'pride', group: 'occasion' },
  'get well': {
    key: 'occasion-get-well',
    group: 'occasion',
    label: 'Get Well',
  },
  'good luck': {
    key: 'occasion-good-luck',
    group: 'occasion',
    label: 'Good Luck',
  },
  goodbye: { key: 'goodbye', group: 'occasion' },
  graduation: { key: 'occasion-graduation', group: 'occasion' },
  halloween: { key: 'occasion-halloween', group: 'occasion' },
  'hen party': { key: 'hen-party', group: 'occasion', label: 'Hen Party' },
  holiday: { key: 'holiday', group: 'occasion' },
  home: { key: 'home', group: 'occasion' },
  'international womens day': {
    key: 'international-womens-day',
    group: 'occasion',
    label: "International Women's Day",
  },
  'just to say': {
    key: 'just-to-say',
    group: 'occasion',
    label: 'Just to Say',
  },
  leaving: { key: 'occasion-leaving', group: 'occasion' },
  lockdown: { key: 'occasion-lockdown', group: 'occasion' },
  'missing you': {
    key: 'missing-you',
    group: 'occasion',
    label: 'Missing You',
  },
  'mothers day': {
    key: 'mothers-day',
    group: 'occasion',
    label: "Mother's Day",
  },
  'new baby': {
    key: 'occasion-new-baby',
    group: 'occasion',
    label: 'New Baby',
  },
  'new home': {
    key: 'occasion-new-home',
    group: 'occasion',
    label: 'New Home',
  },
  'new job': { key: 'occasion-new-job', group: 'occasion', label: 'New Job' },
  'new school': {
    key: 'occasion-new-school',
    group: 'occasion',
    label: 'New School',
  },
  'new year': {
    key: 'occasion-new-year',
    group: 'occasion',
    label: 'New Year',
  },
  'palentines day': {
    key: 'palentines-day',
    group: 'occasion',
    label: "Palentine's Day",
  },
  'pet sympathy': {
    key: 'pet-sympathy',
    group: 'occasion',
    label: 'Pet Sympathy',
  },
  'postponed wedding': {
    key: 'postponed-wedding',
    group: 'occasion',
    label: 'Postponed Wedding',
  },
  pregnancy: { key: 'occasion-pregnancy', group: 'occasion' },
  'proud of you': {
    key: 'proud-of-you',
    group: 'occasion',
    label: 'Proud of You',
  },
  'religious occasions': {
    key: 'religious-occasions',
    group: 'occasion',
    label: 'Religious Occasions',
  },
  'bar/bat mitzvah': {
    key: 'barbat-mitzvah',
    group: 'occasion',
    label: 'Bar/Bat Mitzvah',
  },
  diwali: { key: 'religious-occasions-diwali', group: 'occasion' },
  'dia de muertos': { key: 'dia-de-muertos', group: 'dia-de-muertos' },
  'eid al adha': { key: 'eid-al-adha', group: 'occasion' },
  'eid mubarak': {
    key: 'eid-mubarak',
    group: 'occasion',
    label: 'Eid Mubarak',
  },
  'hajj mubarak': {
    key: 'hajj-mubarak',
    group: 'occasion',
    label: 'Hajj Mubarak',
  },
  hanukkah: { key: 'hanukkah', group: 'occasion' },
  holi: { key: 'holi', group: 'occasion' },
  'naming day': { key: 'naming-day', group: 'occasion', label: 'Naming Day' },
  passover: { key: 'passover', group: 'occasion' },
  'raksha bandhan': { key: 'raksha-bandhan', group: 'occasion' },
  ramadan: { key: 'ramadan', group: 'occasion' },
  'ramadan mubarak': {
    key: 'ramadan-mubarak',
    group: 'occasion',
    label: 'Ramadan Mubarak',
  },
  'rosh hashanah': {
    key: 'rosh-hashanah',
    group: 'occasion',
    label: 'Rosh Hashanah',
  },
  'saints days': {
    key: 'religious-occasions-saints-days',
    group: 'occasion',
    label: "Saints' Days",
  },
  umrah: { key: 'umrah', group: 'umrah' },
  'umrah mubarak': {
    key: 'umrah-mubarak',
    group: 'occasion',
    label: 'Umrah Mubarak',
  },
  vaisakhi: { key: 'vaisakhi', group: 'occasion' },
  'yom kippur': { key: 'yom-kippur', group: 'occasion', label: 'Yom Kippur' },
  retirement: { key: 'occasion-retirement', group: 'occasion' },
  school: { key: 'school', group: 'occasion' },
  sorry: { key: 'occasion-sorry', group: 'occasion' },
  'stag do': { key: 'stag-do', group: 'occasion', label: 'Stag Do' },
  sympathy: { key: 'occasion-sympathy', group: 'occasion' },
  'thank you': {
    key: 'occasion-thank-you',
    group: 'occasion',
    label: 'Thank You',
  },
  'thinking of you': {
    key: 'occasion-thinking-of-you',
    group: 'occasion',
    label: 'Thinking of You',
  },
  'valentines day': {
    key: 'valentines-day',
    group: 'occasion',
    label: "Valentine's Day",
  },
  wedding: { key: 'occasion-wedding', group: 'occasion' },
  'welcome home': {
    key: 'welcome-home',
    group: 'occasion',
    label: 'Welcome Home',
  },
  'well done': {
    key: 'occasion-well-done',
    group: 'occasion',
    label: 'Well Done',
  },
  age: { key: 'newia-age', group: 'age' },
  'age groups': { key: 'age-groups', group: 'age-groups' },
  'baby 0 1 years old': {
    key: 'baby-0-1-years-old',
    group: 'age',
    label: 'Baby (0-18m)',
  },
  'all kids': { key: 'all-kids', group: 'age', label: 'All Kids' },
  'kids 2 5 years old': {
    key: 'kids-2-5-years-old',
    group: 'age',
    label: 'Kids (2-5)',
  },
  'kids 6 9 years old': {
    key: 'kids-6-9-years-old',
    group: 'age',
    label: 'Kids (6-9)',
  },
  'tween 9 12 years old': {
    key: 'tween-9-12-years-old',
    group: 'age',
    label: 'Tween (9-12)',
  },
  'teen 13 17 years old': {
    key: 'teen-13-17-years-old',
    group: 'age',
    label: 'Teen (13-17)',
  },
  'young adult 18 24 years old': {
    key: 'young-adult-18-24-years-old',
    group: 'age',
    label: 'Young Adult (18-24)',
  },
  'adult 25 64 years old': {
    key: 'adult-25-64-years-old',
    group: 'age',
    label: 'Adult (25-64)',
  },
  'senior over 65 years old': {
    key: 'senior-over-65-years-old',
    group: 'age',
    label: 'Senior (over 65)',
  },
  'sentiment & style': {
    key: 'sentiment-style',
    group: 'sentiment & style',
    label: 'Sentiment & Style',
  },
  funny: { key: 'sentiment-style-funny', group: 'sentiment & style' },
  cheeky: { key: 'cheeky', group: 'sentiment & style' },
  rude: { key: 'rude', group: 'sentiment & style' },
  'across the miles': {
    key: 'across-the-miles',
    group: 'sentiment & style',
    label: 'Across the Miles',
  },
  artistic: { key: 'artistic', group: 'sentiment & style' },
  belated: { key: 'belated', group: 'sentiment & style' },
  colour: { key: 'colour', group: 'sentiment & style' },
  black: { key: 'black', group: 'sentiment & style' },
  'black and white': {
    key: 'black-and-white',
    group: 'sentiment & style',
    label: 'Black and White',
  },
  blue: { key: 'colour-blue', group: 'sentiment & style' },
  coloured: { key: 'coloured', group: 'sentiment & style' },
  pink: { key: 'pink', group: 'sentiment & style' },
  watercolour: { key: 'watercolour', group: 'sentiment & style' },
  multicolour: { key: 'multicolour', group: 'sentiment & style' },
  white: { key: 'colour-white', group: 'sentiment & style' },
  cute: { key: 'sentiment-style-cute', group: 'sentiment & style' },
  floral: { key: 'sentiment-style-floral', group: 'sentiment & style' },
  handprint: { key: 'handprint', group: 'sentiment & style' },
  illustration: { key: 'illustration', group: 'sentiment & style' },
  love: { key: 'sentiment-style-love', group: 'sentiment & style' },
  luck: { key: 'sentiment-style-luck', group: 'sentiment & style' },
  pun: { key: 'pun', group: 'sentiment & style' },
  retro: { key: 'sentiment-style-retro', group: 'sentiment & style' },
  romantic: { key: 'romantic', group: 'sentiment & style' },
  traditional: {
    key: 'sentiment-style-traditional',
    group: 'sentiment & style',
  },
  verse: { key: 'verse', group: 'sentiment & style' },
  vintage: { key: 'vintage', group: 'sentiment & style' },
  topic: { key: 'topic', group: 'topic' },
  animals: { key: 'topic-animals', group: 'topic' },
  bear: { key: 'bear', group: 'topic' },
  bird: { key: 'bird', group: 'topic' },
  butterfly: { key: 'butterfly', group: 'topic' },
  cats: { key: 'cats', group: 'topic' },
  chicken: { key: 'chicken', group: 'topic' },
  cow: { key: 'cow', group: 'topic' },
  dinosaur: { key: 'dinosaur', group: 'topic' },
  dogs: { key: 'dogs', group: 'topic' },
  donkey: { key: 'donkey', group: 'topic' },
  elephant: { key: 'elephant', group: 'topic' },
  fish: { key: 'fish', group: 'topic' },
  flamingo: { key: 'flamingo', group: 'topic' },
  fox: { key: 'fox', group: 'topic' },
  giraffe: { key: 'giraffe', group: 'topic' },
  'guinea pig': { key: 'guinea-pig', group: 'topic', label: 'Guinea Pig' },
  hedgehog: { key: 'hedgehog', group: 'topic' },
  horse: { key: 'horse', group: 'topic' },
  llama: { key: 'llama', group: 'topic' },
  monkey: { key: 'monkey', group: 'topic' },
  owl: { key: 'owl', group: 'topic' },
  panda: { key: 'panda', group: 'topic' },
  penguin: { key: 'penguin', group: 'topic' },
  pig: { key: 'pig', group: 'topic' },
  pug: { key: 'pug', group: 'topic' },
  rabbit: { key: 'rabbit', group: 'topic' },
  reindeer: { key: 'reindeer', group: 'topic' },
  'sausage dog': { key: 'sausage-dog', group: 'topic', label: 'Sausage Dog' },
  sheep: { key: 'sheep', group: 'topic' },
  sloth: { key: 'sloth', group: 'topic' },
  unicorn: { key: 'unicorn', group: 'topic' },
  'interests & hobbies': {
    key: 'interests-hobbies',
    group: 'topic',
    label: 'Interests & Hobbies',
  },
  arsenal: { key: 'interests-hobbies-arsenal', group: 'topic' },
  australia: { key: 'australia', group: 'topic' },
  'cars and bikes': {
    key: 'cars-and-bikes',
    group: 'topic',
    label: 'Cars and Bikes',
  },
  chelsea: { key: 'interests-hobbies-chelsea', group: 'topic' },
  cricket: { key: 'cricket', group: 'topic' },
  cycling: { key: 'cycling', group: 'topic' },
  dancing: { key: 'dancing', group: 'topic' },
  fifa: { key: 'fifa', group: 'brands' },
  fishing: { key: 'fishing', group: 'topic' },
  fitness: { key: 'fitness', group: 'topic' },
  'food and drink': {
    key: 'food-and-drink',
    group: 'topic',
    label: 'Food and Drink',
  },
  football: { key: 'football', group: 'topic' },
  fortnite: { key: 'fortnite', group: 'topic' },
  gaming: { key: 'interests-hobbies-gaming', group: 'topic' },
  gardening: { key: 'gardening', group: 'topic' },
  golf: { key: 'golf', group: 'topic' },
  gym: { key: 'gym', group: 'topic' },
  liverpool: { key: 'liverpool', group: 'topic' },
  'manchester united': {
    key: 'manchester-united',
    group: 'topic',
    label: 'Manchester United',
  },
  'super mario': { key: 'super-mario', group: 'topic', label: 'Super Mario' },
  minecraft: { key: 'minecraft', group: 'topic' },
  music: { key: 'interests-hobbies-music', group: 'topic' },
  pokemon: { key: 'pokemon', group: 'topic' },
  roblox: {
    key: 'interests-hobbies-roblox',
    group: 'interests-hobbies-roblox',
  },
  rugby: { key: 'rugby', group: 'topic' },
  sonic: { key: 'interests-hobbies-sonic', group: 'topic' },
  space: { key: 'space', group: 'topic' },
  tennis: { key: 'tennis', group: 'topic' },
  tottenham: { key: 'tottenham', group: 'topic' },
  travel: { key: 'interests-hobbies-travel', group: 'topic' },
  wwe: { key: 'interests-hobbies-wwe', group: 'topic', label: 'WWE' },
  'trending topics & celebs': {
    key: 'trending-topics-celebs',
    group: 'topic',
    label: 'Trending Topics & Celebs',
  },
  'boris johnson': {
    key: 'boris-johnson',
    group: 'topic',
    label: 'Boris Johnson',
  },
  'carol baskin': {
    key: 'carol-baskin',
    group: 'topic',
    label: 'Carol Baskin',
  },
  covid: { key: 'covid', group: 'topic' },
  'donald trump': {
    key: 'donald-trump',
    group: 'topic',
    label: 'Donald Trump',
  },
  elvis: { key: 'elvis', group: 'topic' },
  'harry and meghan': {
    key: 'harry-and-meghan',
    group: 'topic',
    label: 'Harry & Meghan',
  },
  kardashians: { key: 'kardashians', group: 'topic' },
  'kate middleton': {
    key: 'kate-middleton',
    label: 'Kate Middleton',
    group: 'kate-middleton',
  },
  nhs: { key: 'nhs', group: 'topic', label: 'NHS' },
  oasis: { key: 'oasis', group: 'topic' },
  'one direction': {
    key: 'one-direction',
    group: 'topic',
    label: 'One Direction',
  },
  police: { key: 'police', group: 'topic' },
  'ru paul': { key: 'ru-paul', group: 'topic', label: 'Ru Paul' },
  'tv & film': { key: 'tv-film', group: 'topic', label: 'TV & Film' },
  batman: { key: 'batman', group: 'topic' },
  'breaking bad': {
    key: 'breaking-bad',
    label: 'Breaking Bad',
    group: 'breaking-bad',
  },
  'brooklyn 99': {
    key: 'tv-film-brooklyn-99',
    group: 'topic',
    label: 'Brooklyn 99',
  },
  'dads army': { key: 'dads-army', group: 'topic', label: "Dad's Army" },
  disney: { key: 'disney', group: 'topic' },
  'doctor who': {
    key: 'tv-film-doctor-who',
    group: 'topic',
    label: 'Doctor Who',
  },
  'family guy': {
    key: 'tv-film-family-guy',
    label: 'Family Guy',
    group: 'tv-film-family-guy',
  },
  'father ted': {
    key: 'tv-film-father-ted',
    group: 'topic',
    label: 'Father Ted',
  },
  'friends tv show': {
    key: 'friends-tv-show',
    group: 'brands',
    label: 'Friends TV Show',
  },
  'game of thrones': {
    key: 'brands-game-of-thrones',
    label: 'Game of Thrones',
    group: 'brands-game-of-thrones',
  },
  'gavin and stacey': {
    key: 'gavin-and-stacey',
    group: 'topic',
    label: 'Gavin and Stacey',
  },
  'harry potter': {
    key: 'harry-potter',
    group: 'topic',
    label: 'Harry Potter',
  },
  'jurassic world': {
    key: 'jurassic-world',
    label: 'Jurassic World',
    group: 'jurassic-world',
  },
  'lord of the rings': {
    key: 'tv-film-lord-of-the-rings',
    label: 'Lord of the Rings',
    group: 'tv-film-lord-of-the-rings',
  },
  marvel: { key: 'tv-film-marvel', group: 'topic' },
  'mrs browns boys': {
    key: 'tv-film-mrs-browns-boys',
    group: 'topic',
    label: "Mrs Brown's Boys",
  },
  'only fools and horses': {
    key: 'only-fools-and-horses',
    group: 'brands',
    label: 'Only Fools and Horses',
  },
  'peaky blinders': {
    key: 'peaky-blinders',
    group: 'topic',
    label: 'Peaky Blinders',
  },
  simpsons: { key: 'simpsons', group: 'simpsons' },
  'south park': {
    key: 'tv-film-south-park',
    label: 'South Park',
    group: 'tv-film-south-park',
  },
  'star trek': { key: 'star-trek', group: 'topic', label: 'Star Trek' },
  'star wars': { key: 'star-wars', group: 'topic', label: 'Star Wars' },
  'stranger things': {
    key: 'tv-film-stranger-things',
    group: 'topic',
    label: 'Stranger Things',
  },
  'strictly come dancing': {
    key: 'tv-film-strictly-come-dancing',
    group: 'topic',
    label: 'Strictly Come Dancing',
  },
  'tiger king': { key: 'tiger-king', group: 'topic', label: 'Tiger King' },
  'the avengers': {
    key: 'tv-film-the-avengers',
    group: 'topic',
    label: 'The Avengers',
  },
  'wallace and gromit': {
    key: 'tv-film-wallace-and-gromit',
    group: 'topic',
    label: 'Wallace and Gromit',
  },
  'kids tv & film': {
    key: 'kids-tv-film',
    group: 'topic',
    label: 'Kids TV & Film',
  },
  aladdin: { key: 'aladdin', group: 'topic' },
  'baby shark': {
    key: 'kids-tv-film-baby-shark',
    group: 'topic',
    label: 'Baby Shark',
  },
  barbie: { key: 'barbie', group: 'topic' },
  bing: { key: 'bing', group: 'topic' },
  boofle: { key: 'kids-tv-film-boofle', group: 'topic' },
  'despicable me': {
    key: 'despicable-me',
    label: 'Despicable Me',
    group: 'despicable-me',
  },
  'disney princesses': {
    key: 'kids-tv-film-disney-princesses',
    group: 'topic',
    label: 'Disney Princesses',
  },
  'fireman sam': {
    key: 'kids-tv-film-fireman-sam',
    label: 'Fireman Sam',
    group: 'kids-tv-film-fireman-sam',
  },
  frozen: { key: 'frozen', group: 'topic' },
  'hey duggee': {
    key: 'kids-tv-film-hey-duggee',
    group: 'topic',
    label: 'Lego',
  },
  lego: { key: 'kids-tv-film-lego', group: 'kids-tv-film-lego' },
  mermaid: { key: 'mermaid', group: 'topic' },
  'mickey mouse': {
    key: 'brands-mickey-mouse',
    group: 'brands',
    label: 'Mickey Mouse',
  },
  'minnie mouse': {
    key: 'minnie-mouse',
    group: 'brands',
    label: 'Minnie Mouse',
  },
  moana: { key: 'moana', group: 'topic' },
  mulan: { key: 'kids-tv-film-mulan', group: 'topic' },
  'my little pony': {
    key: 'kids-tv-film-my-little-pony',
    group: 'topic',
    label: 'My Little Pony',
  },
  nickelodeon: { key: 'nickelodeon', group: 'topic' },
  'paddington bear': {
    key: 'paddington-bear',
    group: 'topic',
    label: 'Paddington Bear',
  },
  'paw patrol': { key: 'paw-patrol', group: 'topic', label: 'Paw Patrol' },
  'peppa pig': { key: 'peppa-pig', group: 'topic', label: 'Peppa Pig' },
  'peter rabbit': {
    key: 'peter-rabbit',
    group: 'topic',
    label: 'Peter Rabbit',
  },
  'pj masks': { key: 'pj-masks', group: 'topic', label: 'PJ Masks' },
  princess: { key: 'princess', group: 'topic' },
  spiderman: { key: 'kids-tv-film-spiderman', group: 'topic' },
  superhero: { key: 'superhero', group: 'topic' },
  'me to you': {
    key: 'kids-tv-film-me-to-you',
    label: 'Me to You',
    group: 'kids-tv-film-me-to-you',
  },
  'teddy bears': { key: 'teddy-bears', group: 'topic', label: 'Teddy Bears' },
  'the gruffalo': {
    key: 'the-gruffalo',
    group: 'topic',
    label: 'The Gruffalo',
  },
  'the lion king': {
    key: 'kids-tv-film-the-lion-king',
    group: 'topic',
    label: 'Lion King',
  },
  'the snowman': { key: 'the-snowman', group: 'topic', label: 'The Snowman' },
  'thomas and friends': {
    key: 'kids-tv-film-thomas-and-friends',
    group: 'topic',
    label: 'Thomas & Friends',
  },
  'toy story': { key: 'toy-story', group: 'brands', label: 'Toy Story' },
  transformers: { key: 'transformers', group: 'topic' },
  trolls: { key: 'kids-tv-film-trolls', group: 'kids-tv-film-trolls' },
  'winnie the pooh': {
    key: 'winnie-the-pooh',
    group: 'brands',
    label: 'Winnie the Pooh',
  },
  yoda: { key: 'kids-tv-film-yoda', group: 'topic' },
  'format layout & size': {
    key: 'format-layout-size',
    group: 'format layout & size',
    label: 'Format, Layout & Size',
  },
  announcements: { key: 'announcements', group: 'format layout & size' },
  'collage layout': {
    key: 'collage-layout',
    group: 'format layout & size',
    label: 'Collage Layout',
  },
  'e cards': { key: 'e-cards', group: 'e-cards' },
  'giant cards': { key: 'giant-cards', group: 'giant-cards' },
  'greeting cards': { key: 'greeting-cards', group: 'greeting-cards' },
  invitations: { key: 'invitations', group: 'format layout & size' },
  'landscape orientation': {
    key: 'landscape-orientation',
    label: 'Landscape Orientation',
    group: 'landscape-orientation',
  },
  'large cards': { key: 'large-cards', group: 'large-cards' },
  'magazine spoof': {
    key: 'magazine-spoof',
    group: 'format layout & size',
    label: 'Magazine Spoof',
  },
  'newspaper spoof': {
    key: 'newspaper-spoof',
    group: 'format layout & size',
    label: 'Newspaper Spoof',
  },
  'portrait orientation': {
    key: 'portrait-orientation',
    label: 'Portrait Orientation',
    group: 'portrait-orientation',
  },
  postcards: { key: 'postcards', group: 'postcards' },
  'square cards': { key: 'square-cards', group: 'square-cards' },
  'standard cards': { key: 'standard-cards', group: 'standard-cards' },
  'photo cover cards': {
    key: 'photo-cover-cards',
    group: 'format layout & size',
    label: 'Photo Cover Cards',
  },
  artist: { key: 'artist', group: 'artist' },
  'abigail berry': {
    key: 'abigail-berry',
    label: 'Abigail Berry',
    group: 'abigail-berry',
  },
  'angela chick': {
    key: 'angela-chick',
    label: 'Angela Chick',
    group: 'angela-chick',
  },
  'cat noonan': { key: 'cat-noonan', label: 'Cat Noonan', group: 'cat-noonan' },
  'charly clements': {
    key: 'charly-clements',
    label: 'Charly Clements',
    group: 'charly-clements',
  },
  'chloe allum': {
    key: 'chloe-allum',
    label: 'Chloe Allum',
    group: 'chloe-allum',
  },
  'chloe turner': {
    key: 'chloe-turner',
    label: 'Chloe Turner',
    group: 'chloe-turner',
  },
  'corrin strain': {
    key: 'corrin-strain',
    label: 'Corrin Strain',
    group: 'corrin-strain',
  },
  'dalia clark': {
    key: 'dalia-clark',
    label: 'Dalia Clark',
    group: 'dalia-clark',
  },
  'dean morris': {
    key: 'dean-morris',
    label: 'Dean Morris',
    group: 'dean-morris',
  },
  'emma proctor': {
    key: 'emma-proctor',
    label: 'Emma Proctor',
    group: 'emma-proctor',
  },
  'holly sims': { key: 'holly-sims', label: 'Holly Sims', group: 'holly-sims' },
  'james ellis stevens': {
    key: 'james-ellis-stevens',
    label: 'Ellis Stevens',
    group: 'james-ellis-stevens',
  },
  'jerry tapscott': {
    key: 'jerry-tapscott',
    label: 'Jerry Tapscott',
    group: 'jerry-tapscott',
  },
  'jess rose': { key: 'jess-rose', label: 'Jess Rose', group: 'jess-rose' },
  'jo scott': { key: 'jo-scott', label: 'Jo Scott', group: 'jo-scott' },
  'kathryn kirby': {
    key: 'kathryn-kirby',
    label: 'Kathryn Kirby',
    group: 'kathryn-kirby',
  },
  'katie abey': { key: 'katie-abey', label: 'Katie Abey', group: 'katie-abey' },
  'katt jones': { key: 'katt-jones', label: 'Katt Jones', group: 'katt-jones' },
  'klara hawkins': {
    key: 'klara-hawkins',
    label: 'Klara Hawkins',
    group: 'klara-hawkins',
  },
  'laura darrington': {
    key: 'laura-darrington',
    label: 'Laura Darrington',
    group: 'laura-darrington',
  },
  'lisa barlow': {
    key: 'lisa-barlow',
    label: 'Lisa Barlow',
    group: 'lisa-barlow',
  },
  'lorna syson': {
    key: 'lorna-syson',
    label: 'Lorna Syson',
    group: 'lorna-syson',
  },
  'lucy maggie': {
    key: 'lucy-maggie',
    label: 'Lucy Maggie',
    group: 'lucy-maggie',
  },
  'natalie alex': {
    key: 'natalie-alex',
    label: 'Natalie Alex',
    group: 'natalie-alex',
  },
  'nina bombina': {
    key: 'nina-bombina',
    label: 'Nina Bombina',
    group: 'nina-bombina',
  },
  'roger hutchings': {
    key: 'roger-hutchings',
    label: 'Roger Hutchings',
    group: 'roger-hutchings',
  },
  'rupert fawcett': {
    key: 'rupert-fawcett',
    label: 'Rupert Fawcett',
    group: 'rupert-fawcett',
  },
  'sian roberts': {
    key: 'sian-roberts',
    label: 'Sian Roberts',
    group: 'sian-roberts',
  },
  'stella isaac': {
    key: 'stella-isaac',
    label: 'Stella Isaac',
    group: 'stella-isaac',
  },
  brands: { key: 'brands', group: 'brands' },
  'action man': { key: 'action-man', group: 'brands', label: 'Action Man' },
  'all things banter': {
    key: 'all-things-banter',
    group: 'brands',
    label: 'All Things Banter',
  },
  'banter king': { key: 'banter-king', group: 'brands', label: 'Banter King' },
  bbc: { key: 'bbc', group: 'brands', label: 'BBC' },
  'beatrix potter': {
    key: 'beatrix-potter',
    group: 'brands',
    label: 'Beatrix Potter',
  },
  'brainbox candy': {
    key: 'brainbox-candy',
    group: 'brands',
    label: 'Brainbox Candy',
  },
  'cardy club': { key: 'cardy-club', group: 'brands', label: 'Cardy Club' },
  'davora ltd': { key: 'davora-ltd', label: 'Davora Ltd', group: 'davora-ltd' },
  'dr who': { key: 'dr-who', label: 'Dr Who', group: 'dr-who' },
  'eastern print studio': {
    key: 'eastern-print-studio',
    group: 'brands',
    label: 'Eastern Print Studio',
  },
  'edwardian lady': {
    key: 'edwardian-lady',
    group: 'brands',
    label: 'Edwardian Lady',
  },
  'filthy sentiments': {
    key: 'filthy-sentiments',
    group: 'brands',
    label: 'Filthy Sentiments',
  },
  'flower fairies': {
    key: 'flower-fairies',
    group: 'brands',
    label: 'Flower Fairies',
  },
  'go la la': { key: 'go-la-la', group: 'brands', label: 'Go La la' },
  'happy jackson': {
    key: 'happy-jackson',
    group: 'brands',
    label: 'Happy Jackson',
  },
  'hurrah for gin': {
    key: 'hurrah-for-gin',
    group: 'brands',
    label: 'Hurrah for Gin',
  },
  'jolly awesome': {
    key: 'jolly-awesome',
    group: 'brands',
    label: 'Jolly Awesome',
  },
  'ling design': { key: 'ling-design', group: 'brands', label: 'Ling Design' },
  'moonpig exclusive': {
    key: 'moonpig-exclusive',
    group: 'brands',
    label: 'Moonpig Exclusive',
  },
  nintendo: { key: 'nintendo', group: 'brands' },
  'okey dokey design': {
    key: 'okey-dokey-design',
    group: 'brands',
    label: 'Okey Dokey Design',
  },
  'pearl and ivy': {
    key: 'pearl-and-ivy',
    label: 'Pearl and Ivy',
    group: 'pearl-and-ivy',
  },
  'redback cards limited': {
    key: 'redback-cards-limited',
    group: 'brands',
    label: 'Redback Cards Limited',
  },
  'rumble cards': {
    key: 'rumble-cards',
    group: 'brands',
    label: 'Rumble Cards',
  },
  'sadler jones': {
    key: 'sadler-jones',
    group: 'brands',
    label: 'Sadler Jones',
  },
  'snap to grid': {
    key: 'snap-to-grid',
    label: 'Snap to Grid',
    group: 'snap-to-grid',
  },
  sooshichacha: { key: 'sooshichacha', group: 'brands' },
  'studio pets': { key: 'studio-pets', group: 'brands', label: 'Studio Pets' },
  'the national gallery': {
    key: 'brands-the-national-gallery',
    group: 'brands',
    label: 'The National Gallery',
  },
  'the natural history museum': {
    key: 'the-natural-history-museum',
    group: 'brands',
    label: 'The Natural History Museum',
  },
  'the v and a': { key: 'the-v-and-a', group: 'brands', label: 'The V&A' },
  'tiny tatty teddy': {
    key: 'tiny-tatty-teddy',
    group: 'brands',
    label: 'Me to You',
  },
  'gift sets hampers letterbox': {
    key: 'newia-gift-sets-hampers-letterbox',
    group: 'gift sets hampers letterbox',
    label: 'Gift Sets, Hampers & Letterbox',
  },
  'gift sets': {
    key: 'gift-sets',
    group: 'toys kids & baby',
    label: 'Gift Sets',
  },
  hampers: { key: 'hampers', group: 'gift sets hampers letterbox' },
  letterbox: {
    key: 'gift-sets-hampers-letterbox-letterbox',
    group: 'gift sets hampers letterbox',
  },
  yes: { key: 'yes', group: 'yes' },
  'personalised gifts': {
    key: 'personalised-gifts',
    label: 'Personalised Gifts',
    group: 'personalised-gifts',
  },
  alcohol: { key: 'alcohol', group: 'alcohol' },
  'gift sets letterbox': {
    key: 'alcohol-gift-sets-letterbox',
    group: 'alcohol',
    label: 'Gift Sets & Letterbox',
  },
  gin: { key: 'gin', group: 'alcohol' },
  'beer & cider': { key: 'beer-cider', group: 'alcohol' },
  bitter: { key: 'bitter', group: 'alcohol' },
  cider: { key: 'cider', group: 'alcohol' },
  lager: { key: 'lager', group: 'alcohol' },
  stout: { key: 'stout', group: 'stout' },
  'champagne prosecco & wine': {
    key: 'champagne-prosecco-wine',
    group: 'alcohol',
    label: 'Champagne, Prosecco & Wine',
  },
  champagne: { key: 'champagne', group: 'alcohol' },
  'port & sherry': {
    key: 'port-sherry',
    label: 'Port & Sherry',
    group: 'port-sherry',
  },
  prosecco: { key: 'prosecco', group: 'alcohol' },
  'red wine': { key: 'red-wine', group: 'alcohol', label: 'Red Wine' },
  'rose wine': { key: 'rose-wine', group: 'alcohol', label: 'Rose Wine' },
  sherry: { key: 'sherry', group: 'sherry' },
  'sparkling wine': {
    key: 'sparkling-wine',
    group: 'alcohol',
    label: 'Sparkling Wine',
  },
  'white wine': { key: 'white-wine', group: 'alcohol', label: 'White Wine' },
  standard: { key: 'gin-standard', group: 'alcohol', label: 'Standard Cards' },
  flavoured: { key: 'flavoured', group: 'alcohol' },
  'gin liqueurs': { key: 'gin-liqueurs', group: 'alcohol' },
  vodka: { key: 'vodka', group: 'alcohol' },
  whiskey: { key: 'whiskey', group: 'alcohol' },
  american: { key: 'american', group: 'american' },
  irish: { key: 'irish', group: 'irish' },
  japanese: { key: 'japanese', group: 'japanese' },
  scotch: { key: 'scotch', group: 'alcohol' },
  'other spirits': {
    key: 'other-spirits',
    group: 'alcohol',
    label: 'Other Spirits',
  },
  'beauty face & body': {
    key: 'beauty-face-body',
    group: 'beauty face & body',
  },
  'home & garden': {
    key: 'home-garden',
    group: 'home & garden',
    label: 'Home & Garden',
  },
  aprons: { key: 'aprons', group: 'home & garden' },
  'home accessories': {
    key: 'home-garden-home-accessories',
    label: 'Home Accessories',
    group: 'home-garden-home-accessories',
  },
  garden: { key: 'garden', group: 'home & garden' },
  glassware: { key: 'glassware', group: 'home & garden' },
  'fragrance & candles': {
    key: 'fragrance-candles',
    label: 'Fragrance & Candles',
    group: 'fragrance-candles',
  },
  mugs: { key: 'mugs', group: 'home & garden' },
  ornaments: { key: 'ornaments', group: 'ornaments' },
  'photo frames': {
    key: 'photo-frames',
    group: 'home & garden',
    label: 'Photo Frames',
  },
  'posters prints': {
    key: 'posters-prints',
    label: 'Posters & Prints',
    group: 'posters-prints',
  },
  towels: { key: 'towels', group: 'towels' },
  'food & drink': {
    key: 'food-drink',
    group: 'food & drink',
    label: 'Food & Drink',
  },
  sweets: { key: 'sweets', group: 'food & drink' },
  'brittle toffee': {
    key: 'brittle-toffee',
    label: 'Brittle/Toffee',
    group: 'brittle-toffee',
  },
  fudge: { key: 'fudge', group: 'food & drink' },
  'hard candy': {
    key: 'hard-candy',
    group: 'food & drink',
    label: 'Hard Candy',
  },
  jellies: { key: 'jellies', group: 'food & drink' },
  licorice: { key: 'licorice', group: 'licorice' },
  lollies: { key: 'lollies', group: 'food & drink' },
  mallow: { key: 'mallow', group: 'mallow' },
  'baking kits': {
    key: 'baking-kits',
    group: 'food & drink',
    label: 'Baking Kits',
  },
  chocolate: { key: 'chocolate', group: 'food & drink' },
  biscuits: { key: 'biscuits', group: 'food & drink' },
  novelty: { key: 'novelty', group: 'food & drink' },
  personalised: { key: 'personalised', group: 'food & drink' },
  seasonal: { key: 'food-drink-seasonal', group: 'food-drink-seasonal' },
  'snacks treats savoury': {
    key: 'snacks-treats-savoury',
    group: 'food & drink',
    label: 'Snacks, Treats & Savoury',
  },
  'vegan special diet': {
    key: 'vegan-special-diet',
    group: 'food & drink',
    label: 'Vegan & Special Diet',
  },
  'gift cards': { key: 'gift-cards', group: 'gift cards', label: 'Gift Cards' },
  'egift cards': { key: 'egift-cards', group: 'egift-cards' },
  multibrand: { key: 'multibrand', group: 'multibrand' },
  'dining out': { key: 'dining-out', group: 'dining-out' },
  'entertainment gaming': {
    key: 'entertainment-gaming',
    group: 'entertainment-gaming',
  },
  'home fashion': { key: 'home-fashion', group: 'gift cards' },
  'gift experiences': {
    key: 'gift-experiences',
    group: 'gift experiences',
    label: 'Gift Experiences',
  },
  'all gift experiences': {
    key: 'all-gift-experiences',
    label: 'All Gift Experiences',
    group: 'all-gift-experiences',
  },
  'afternoon tea': {
    key: 'afternoon-tea',
    group: 'gift experiences',
    label: 'Afternoon Tea',
  },
  'at home': { key: 'at-home', group: 'gift experiences', label: 'At Home' },
  comedy: { key: 'comedy', group: 'comedy' },
  'day trips': {
    key: 'day-trips',
    group: 'gift experiences',
    label: 'Day Trips',
  },
  'dinner experiences': {
    key: 'dinner-experiences',
    group: 'gift experiences',
    label: 'Dinner Experiences',
  },
  'food drink': {
    key: 'gift-experiences-food-drink',
    group: 'gift experiences',
    label: 'Food and Drink',
  },
  photoshoots: { key: 'photoshoots', group: 'gift experiences' },
  'short breaks': {
    key: 'short-breaks',
    group: 'gift experiences',
    label: 'Short Breaks',
  },
  'spa breaks': {
    key: 'spa-breaks',
    group: 'gift experiences',
    label: 'Spa Breaks',
  },
  'sports leisure': {
    key: 'sports-leisure',
    group: 'gift experiences',
    label: 'Sports & Leisure',
  },
  family: { key: 'whos-it-for-family', group: 'whos-it-for-family' },
  couples: { key: 'couples', group: 'couples' },
  single: { key: 'single', group: 'single' },
  'kids teens': {
    key: 'kids-teens',
    label: 'Kids & Teens',
    group: 'kids-teens',
  },
  'for how many?': {
    key: 'for-how-many',
    label: 'For How Many?',
    group: 'for-how-many',
  },
  '1 person': { key: '1-person', label: '1 Person', group: '1-person' },
  '2 people': { key: '2-people', label: '2 People', group: '2-people' },
  '3+ people': { key: '3-people', label: '3+ People', group: '3-people' },
  accessories: { key: 'accessories', group: 'accessories' },
  socks: { key: 'socks', group: 'accessories' },
  jewellery: { key: 'jewellery', group: 'accessories' },
  bracelet: { key: 'bracelet', group: 'accessories' },
  earrings: { key: 'earrings', group: 'accessories' },
  necklace: { key: 'necklace', group: 'accessories' },
  gold: { key: 'gold', group: 'gold' },
  'rose gold': { key: 'rose-gold', label: 'Rose Gold', group: 'rose-gold' },
  silver: { key: 'silver', group: 'accessories' },
  balloons: { key: 'newia-balloons', group: 'balloons' },
  'balloons bouquets': {
    key: 'balloons-bouquets',
    group: 'balloons',
    label: 'Balloons Bouquets',
  },
  'helium balloons': {
    key: 'helium-balloons',
    group: 'balloons',
    label: 'Helium Balloons',
  },
  'personalised balloons': {
    key: 'personalised-balloons',
    group: 'balloons',
    label: 'Personalised Balloons',
  },
  'party balloons': {
    key: 'party-balloons',
    group: 'balloons',
    label: 'Party Balloons',
  },
  'giant balloons': {
    key: 'giant-balloons',
    group: 'balloons',
    label: 'Giant Balloons',
  },
  'plain balloons': {
    key: 'plain-balloons',
    label: 'Plain Balloons',
    group: 'plain-balloons',
  },
  'number balloons': {
    key: 'number-balloons',
    group: 'balloons',
    label: 'Number Balloons',
  },
  'branded balloons': {
    key: 'branded-balloons',
    group: 'balloons',
    label: 'Branded Balloons',
  },
  'books & stationery': {
    key: 'books-stationery',
    group: 'books & stationery',
    label: 'Books & Stationery',
  },
  'for adults': {
    key: 'for-adults',
    group: 'books & stationery',
    label: 'For Adults',
  },
  'toys kids & baby': {
    key: 'toys-kids-baby',
    group: 'toys kids & baby',
    label: 'Toys, Kids & Baby',
  },
  'action toys': {
    key: 'action-toys',
    group: 'toys kids & baby',
    label: 'Action Toys',
  },
  'arts crafts': {
    key: 'arts-crafts',
    group: 'toys kids & baby',
    label: 'Arts & Crafts',
  },
  baby: { key: 'toys-kids-baby-baby', group: 'toys kids & baby' },
  'books stationery': {
    key: 'toys-kids-baby-books-stationery',
    group: 'toys kids & baby',
    label: 'Books & Stationery',
  },
  'dolls accessories': {
    key: 'dolls-accessories',
    group: 'toys kids & baby',
    label: 'Dolls & Accessories',
  },
  'games board games puzzles': {
    key: 'games-board-games-puzzles',
    group: 'toys kids & baby',
    label: 'Games, Board Games & Puzzles',
  },
  'lego sets': {
    key: 'lego-sets',
    group: 'toys kids & baby',
    label: 'Lego Sets',
  },
  playset: { key: 'playset', group: 'toys kids & baby' },
  'scratch maps': {
    key: 'toys-kids-baby-scratch-maps',
    label: 'Scratch Maps',
    group: 'toys-kids-baby-scratch-maps',
  },
  'soft toys': {
    key: 'soft-toys',
    group: 'toys kids & baby',
    label: 'Soft Toys',
  },
  'wooden toys': {
    key: 'wooden-toys',
    group: 'toys kids & baby',
    label: 'Wooden Toys',
  },
  'games gadgets & novelty': {
    key: 'games-gadgets-novelty',
    group: 'games gadgets & novelty',
    label: 'Games, Gadgets & Novelty',
  },
  gadgets: { key: 'gadgets', group: 'games gadgets & novelty' },
  'grow kits': {
    key: 'grow-kits',
    group: 'games gadgets & novelty',
    label: 'Grow Kits',
  },
  'ann summers': {
    key: 'ann-summers',
    label: 'Ann Summers',
    group: 'ann-summers',
  },
  friends: { key: 'friends', group: 'brands' },
  'only fools horses': {
    key: 'only-fools-horses',
    group: 'brands',
    label: 'Only Fools and Horses',
  },
  avengers: { key: 'avengers', group: 'brands' },
  brio: { key: 'brio', group: 'brio' },
  'dc comics': { key: 'dc-comics', group: 'brands', label: 'DC Comics' },
  'dear zoo': { key: 'dear-zoo', label: 'Dear Zoo', group: 'dear-zoo' },
  'disney princess': { key: 'disney-princess', group: 'disney-princess' },
  'fisher price': {
    key: 'fisher-price',
    label: 'Fisher Price',
    group: 'fisher-price',
  },
  'guess how much i love you': {
    key: 'guess-how-much-i-love-you',
    group: 'brands',
    label: 'Guess How Much I Love You',
  },
  'hot wheels': { key: 'hot-wheels', label: 'Hot Wheels', group: 'hot-wheels' },
  'hungry caterpillar': {
    key: 'hungry-caterpillar',
    group: 'brands',
    label: 'Hungry Caterpillar',
  },
  jcb: { key: 'jcb', group: 'brands', label: ' JCB' },
  lamaze: { key: 'lamaze', group: 'lamaze' },
  'love hearts': { key: 'love-hearts', group: 'brands', label: 'Love Hearts' },
  'mega blocks': {
    key: 'mega-blocks',
    label: 'Mega Blocks',
    group: 'mega-blocks',
  },
  'mr men': { key: 'mr-men', label: 'Mr Men', group: 'mr-men' },
  nerf: { key: 'nerf', group: 'nerf' },
  paddington: { key: 'paddington', group: 'paddington' },
  'play doh': { key: 'play-doh', group: 'brands', label: 'Play Doh' },
  playmobil: { key: 'playmobil', group: 'brands' },
  'shaun the sheep': {
    key: 'shaun-the-sheep',
    label: 'Shaun the Sheep',
    group: 'shaun-the-sheep',
  },
  'thomas the tank engine': {
    key: 'thomas-the-tank-engine',
    label: 'Thomas the Tank Engine',
    group: 'thomas-the-tank-engine',
  },
  adidas: { key: 'adidas', group: 'adidas' },
  asos: { key: 'asos', label: 'ASOS', group: 'asos' },
  'b and q': { key: 'b-and-q', group: 'b-and-q' },
  'h and m': { key: 'h-and-m', group: 'h-and-m' },
  itunes: { key: 'itunes', label: 'iTunes', group: 'itunes' },
  'john lewis partners': {
    key: 'john-lewis-partners',
    group: 'john-lewis-partners',
  },
  'just eat': { key: 'just-eat', label: 'Just Eat', group: 'just-eat' },
  'm and s': { key: 'm-and-s', group: 'm-and-s' },
  netflix: { key: 'netflix', group: 'netflix' },
  next: { key: 'next', group: 'next' },
  playstation: { key: 'playstation', group: 'brands' },
  spotify: { key: 'spotify', group: 'spotify' },
  xbox: { key: 'xbox', group: 'xbox' },
  cadbury: { key: 'cadbury', group: 'brands' },
  ferrero: { key: 'ferrero', group: 'ferrero' },
  'green blacks': {
    key: 'green-blacks',
    label: 'Green & Blacks',
    group: 'green-blacks',
  },
  guylian: { key: 'guylian', group: 'brands' },
  'holdsworth chocolates': {
    key: 'holdsworth-chocolates',
    group: 'brands',
    label: 'Holdsworth Chocolates',
  },
  'jelly bean': { key: 'jelly-bean', group: 'brands', label: 'Jelly Bean' },
  'jelly belly': { key: 'jelly-belly', group: 'brands', label: 'Jelly Belly' },
  lanson: { key: 'lanson', group: 'brands' },
  lindor: { key: 'lindor', group: 'brands' },
  montezumas: { key: 'montezumas', group: 'brands' },
  thorntons: { key: 'thorntons', group: 'thorntons' },
  toblerone: { key: 'toblerone', group: 'brands' },
  'tonys chocoloney': {
    key: 'tonys-chocoloney',
    group: 'brands',
    label: "Tony's Chocoloney",
  },
  walkers: { key: 'walkers', group: 'brands' },
  whittards: { key: 'whittards', group: 'brands' },
  absolut: { key: 'absolut', group: 'brands' },
  aperol: { key: 'aperol', group: 'aperol' },
  baileys: { key: 'baileys', group: 'baileys' },
  balvenie: { key: 'balvenie', group: 'balvenie' },
  'beer hawk': { key: 'beer-hawk', group: 'brands', label: 'Beer Hawk' },
  'belvedere vodka': {
    key: 'belvedere-vodka',
    group: 'brands',
    label: 'Belvedere Vodka',
  },
  bollinger: { key: 'bollinger', group: 'bollinger' },
  'bombay sapphire': { key: 'bombay-sapphire', group: 'bombay-sapphire' },
  bottega: { key: 'bottega', group: 'brands' },
  brewdog: { key: 'brewdog', group: 'brewdog' },
  budweiser: { key: 'budweiser', group: 'budweiser' },
  bushmills: { key: 'bushmills', group: 'bushmills' },
  'captain morgan': {
    key: 'captain-morgan',
    label: 'Captain Morgan',
    group: 'captain-morgan',
  },
  'chase distillery': {
    key: 'chase-distillery',
    group: 'brands',
    label: 'Chase Distillery',
  },
  'chivas regal': {
    key: 'chivas-regal',
    label: 'Chivas Regal',
    group: 'chivas-regal',
  },
  ciroc: { key: 'ciroc', group: 'ciroc' },
  'dom perignon': {
    key: 'dom-perignon',
    label: 'Dom Perignon',
    group: 'dom-perignon',
  },
  'edinburgh gin': {
    key: 'edinburgh-gin',
    group: 'brands',
    label: 'Edinburgh Gin',
  },
  'fever tree': { key: 'fever-tree', label: 'Fever Tree', group: 'fever-tree' },
  'freedom distillery': {
    key: 'freedom-distillery',
    label: 'Freedom Distillery',
    group: 'freedom-distillery',
  },
  freixenet: { key: 'freixenet', group: 'freixenet' },
  glenmorangie: { key: 'glenmorangie', group: 'brands' },
  glenfiddich: { key: 'glenfiddich', group: 'glenfiddich' },
  gordons: { key: 'gordons', group: 'brands', label: "Gordon's" },
  'grahams port': {
    key: 'grahams-port',
    label: "Graham's Port",
    group: 'grahams-port',
  },
  'grey goose': { key: 'grey-goose', group: 'brands', label: 'Grey Goose' },
  harveys: { key: 'harveys', group: 'harveys' },
  hattingley: { key: 'hattingley', group: 'hattingley' },
  hendricks: { key: 'hendricks', label: "Hendrick's", group: 'hendricks' },
  hennessy: { key: 'hennessy', group: 'hennessy' },
  'hogs back': { key: 'hogs-back', group: 'brands', label: 'Hogs Back' },
  'jack daniels': {
    key: 'jack-daniels',
    group: 'brands',
    label: 'Jack Daniels',
  },
  jagermeister: { key: 'jagermeister', group: 'jagermeister' },
  jameson: { key: 'jameson', group: 'jameson' },
  'jim beam': { key: 'jim-beam', label: 'Jim Beam', group: 'jim-beam' },
  'johnnie walker': {
    key: 'johnnie-walker',
    label: 'Johnnie Walker',
    group: 'johnnie-walker',
  },
  lagavulin: { key: 'lagavulin', group: 'lagavulin' },
  laphroaig: { key: 'laphroaig', group: 'laphroaig' },
  'laurent perrier': {
    key: 'laurent-perrier',
    group: 'brands',
    label: 'Laurent Perrier',
  },
  malibu: { key: 'malibu', group: 'malibu' },
  martini: { key: 'martini', group: 'brands' },
  'moet chandon': { key: 'moet-chandon', group: 'brands' },
  pimms: { key: 'pimms', group: 'brands' },
  'piper heidsieck': {
    key: 'piper-heidsieck',
    group: 'brands',
    label: 'Piper Heidsieck',
  },
  'remy martin': {
    key: 'remy-martin',
    label: 'Remy Martin',
    group: 'remy-martin',
  },
  'sailor jerry': {
    key: 'sailor-jerry',
    label: 'Sailor Jerry',
    group: 'sailor-jerry',
  },
  'sipsmith gin': {
    key: 'sipsmith-gin',
    group: 'brands',
    label: 'Sipsmith Gin',
  },
  'stella artois': {
    key: 'stella-artois',
    group: 'brands',
    label: 'Stella Artois',
  },
  'the famous grouse': {
    key: 'the-famous-grouse',
    group: 'brands',
    label: 'The Famous Grouse',
  },
  'the kraken rum': {
    key: 'the-kraken-rum',
    label: 'The Kraken Rum',
    group: 'the-kraken-rum',
  },
  'two birds vodka': {
    key: 'two-birds-vodka',
    label: 'Two Birds Vodka',
    group: 'two-birds-vodka',
  },
  'veuve cliquot': {
    key: 'veuve-cliquot',
    group: 'brands',
    label: 'Veuve Cliquot',
  },
  'warners gin': { key: 'warners-gin', group: 'brands', label: "Warner's Gin" },
  'whitley neill': {
    key: 'whitley-neill',
    group: 'brands',
    label: 'Whitley Neill',
  },
  'barry m': { key: 'barry-m', group: 'brands', label: 'Barry M' },
  beefayre: { key: 'beefayre', group: 'brands' },
  birchbox: { key: 'birchbox', group: 'birchbox' },
  bulldog: { key: 'bulldog', group: 'bulldog' },
  cowshed: { key: 'cowshed', group: 'cowshed' },
  'heathcote ivory': { key: 'heathcote-ivory', group: 'brands' },
  'miss patisserie': {
    key: 'miss-patisserie',
    group: 'brands',
    label: 'Miss Patisserie',
  },
  'molton brown': {
    key: 'molton-brown',
    label: 'Molton Brown',
    group: 'molton-brown',
  },
  nivea: { key: 'nivea', group: 'brands' },
  opi: { key: 'opi', group: 'brands', label: 'OPI' },
  revlon: { key: 'revlon', group: 'revlon' },
  'sanctuary spa': {
    key: 'sanctuary-spa',
    group: 'brands',
    label: 'Sanctuary Spa',
  },
  'sara miller': {
    key: 'sara-miller',
    label: 'Sara Miller',
    group: 'sara-miller',
  },
  'this works': { key: 'this-works', group: 'brands', label: 'This Works' },
  aery: { key: 'aery', group: 'brands' },
  alessi: { key: 'alessi', group: 'alessi' },
  'lily flame': { key: 'lily-flame', label: 'Lily Flame', group: 'lily-flame' },
  'cath kidston': {
    key: 'cath-kidston',
    group: 'brands',
    label: 'Cath Kidston',
  },
  'orla kiely': { key: 'orla-kiely', label: 'Orla Kiely', group: 'orla-kiely' },
  'emma bridgewater': {
    key: 'emma-bridgewater',
    label: 'Emma Bridgewater',
    group: 'emma-bridgewater',
  },
  'yankee candle': {
    key: 'yankee-candle',
    group: 'brands',
    label: 'Yankee Candle',
  },
  lsa: { key: 'lsa', label: 'LSA', group: 'lsa' },
  'vera wang': { key: 'vera-wang', label: 'Vera Wang', group: 'vera-wang' },
  'flowers gift sets & letterbox': {
    key: 'flowers-gift-sets-letterbox',
    group: 'flowers gift sets & letterbox',
    label: 'Gift Sets & Letterbox',
  },
  'with vase': { key: 'with-vase', label: 'With Vase', group: 'with-vase' },
  'flowers & plants': {
    key: 'flowers-plants',
    group: 'flowers & plants',
    label: 'Variety',
  },
  bouquets: { key: 'bouquets', group: 'flowers & plants' },
  'dried flowers': {
    key: 'dried-flowers',
    label: 'Dried Flowers',
    group: 'dried-flowers',
  },
  'indoor plants': {
    key: 'indoor-plants',
    group: 'flowers & plants',
    label: 'Indoor Plants',
  },
  'outdoor plants': {
    key: 'outdoor-plants',
    label: 'Outdoor Plants',
    group: 'outdoor-plants',
  },
  alstroemeria: { key: 'alstroemeria', group: 'alstroemeria' },
  carnations: { key: 'carnations', group: 'carnations' },
  chrysanthemums: { key: 'chrysanthemums', group: 'chrysanthemums' },
  daffodils: { key: 'daffodils', group: 'daffodils' },
  eucalyptus: { key: 'eucalyptus', group: 'eucalyptus' },
  gerbera: { key: 'gerbera', group: 'gerbera' },
  germini: { key: 'germini', group: 'flowers & plants' },
  gypsophilia: { key: 'gypsophilia', group: 'flowers & plants' },
  lavender: { key: 'lavender', group: 'lavender' },
  lilies: { key: 'lilies', group: 'flowers & plants' },
  lisianthus: { key: 'lisianthus', group: 'flowers & plants' },
  orchid: { key: 'orchid', group: 'orchid' },
  peonies: { key: 'peonies', group: 'flowers & plants' },
  roses: { key: 'roses', group: 'flowers & plants' },
  stocks: { key: 'stocks', group: 'stocks' },
  sunflowers: { key: 'sunflowers', group: 'sunflowers' },
  tulips: { key: 'tulips', group: 'tulips' },
  'flower colour': {
    key: 'flower-colour',
    group: 'flower colour',
    label: 'Colour',
  },
  bright: { key: 'bright', group: 'bright' },
  green: { key: 'green', group: 'flower colour' },
  lilac: { key: 'lilac', group: 'flower colour' },
  'multi colour': {
    key: 'multi-colour',
    group: 'flower colour',
    label: 'Multi Colour',
  },
  orange: { key: 'orange', group: 'flower colour' },
  pastel: { key: 'pastel', group: 'pastel' },
  peach: { key: 'peach', group: 'flower colour' },
  purple: { key: 'purple', group: 'flower colour' },
  rainbow: { key: 'rainbow', group: 'rainbow' },
  red: { key: 'red', group: 'flower colour' },
  yellow: { key: 'yellow', group: 'yellow' },
  'flower special features': {
    key: 'flower-special-features',
    group: 'flower special features',
    label: 'Special Features',
  },
  'easy care': {
    key: 'easy-care',
    group: 'flower special features',
    label: 'Easy Care',
  },
  fairtrade: { key: 'fairtrade', group: 'fairtrade' },
  'long lasting': {
    key: 'long-lasting',
    group: 'flower special features',
    label: 'Long Lasting',
  },
  luxury: { key: 'luxury', group: 'flower special features' },
  'pet friendly': {
    key: 'pet-friendly',
    group: 'flower special features',
    label: 'Pet Friendly',
  },
  scented: { key: 'scented', group: 'flower special features' },
  'special offer': {
    key: 'special-offer',
    label: 'Special Offer',
    group: 'special-offer',
  },
  'uk grown': { key: 'uk-grown', label: 'UK Grown', group: 'uk-grown' },
  '4m': { key: '4m', group: '4m' },
  'activity superstore': {
    key: 'activity-superstore',
    label: 'Activity Superstore',
    group: 'activity-superstore',
  },
  'amazing & co': { key: 'amazing-co', group: 'amazing-co' },
  'ariana grande': {
    key: 'ariana-grande',
    label: 'Ariana Grande',
    group: 'ariana-grande',
  },
  artusi: { key: 'artusi', group: 'artusi' },
  'ask mummy & daddy': {
    key: 'ask-mummy-daddy',
    group: 'brands',
    label: 'Ask Mummy & Daddy',
  },
  bacardi: { key: 'bacardi', group: 'bacardi' },
  baglietti: { key: 'baglietti', group: 'baglietti' },
  'baked in': { key: 'baked-in', label: 'Baked In', group: 'baked-in' },
  'baron de marck': {
    key: 'baron-de-marck',
    label: 'Baron De Marck',
    group: 'baron-de-marck',
  },
  'beanie baby': {
    key: 'beanie-baby',
    label: 'Beanie Baby',
    group: 'beanie-baby',
  },
  beluga: { key: 'beluga', group: 'beluga' },
  besseract: { key: 'besseract', group: 'besseract' },
  'best years': { key: 'best-years', label: 'Best Years', group: 'best-years' },
  'birra moretti': {
    key: 'birra-moretti',
    label: 'Birra Moretti',
    group: 'birra-moretti',
  },
  bloom: { key: 'bloom', group: 'bloom' },
  'booja booja': {
    key: 'booja-booja',
    label: 'Booja Booja',
    group: 'booja-booja',
  },
  "bottle 'n' bar": { key: 'bottle-n-bar', group: 'bottle-n-bar' },
  burberry: { key: 'burberry', group: 'burberry' },
  'calvin klein': {
    key: 'calvin-klein',
    label: 'Calvin Klein',
    group: 'calvin-klein',
  },
  'cartwright & butler': {
    key: 'cartwright-butler',
    group: 'brands',
    label: 'Cartwright & Butler',
  },
  'charbonnel et walker': {
    key: 'charbonnel-et-walker',
    group: 'brands',
    label: 'Charbonnel Et Walker',
  },
  'choc on choc': {
    key: 'choc-on-choc',
    group: 'brands',
    label: 'Choc on Choc',
  },
  'chupa chups': {
    key: 'chupa-chups',
    label: 'Chupa Chups',
    group: 'chupa-chups',
  },
  'cra-z-slime': { key: 'crazslime', group: 'crazslime' },
  'crafty yard': {
    key: 'crafty-yard',
    label: 'Crafty Yard',
    group: 'crafty-yard',
  },
  crayola: { key: 'crayola', group: 'crayola' },
  'crystal head': {
    key: 'crystal-head',
    label: 'Crystal Head',
    group: 'crystal-head',
  },
  davidoff: { key: 'davidoff', group: 'davidoff' },
  diesel: { key: 'diesel', group: 'diesel' },
  'dolce & gabbana': {
    key: 'dolce-gabbana',
    label: 'Dolce & Gabbana',
    group: 'dolce-gabbana',
  },
  'dr pawpaw': { key: 'dr-pawpaw', group: 'brands', label: 'Dr Pawpaw' },
  'elizabeth arden': {
    key: 'elizabeth-arden',
    label: 'Elizabeth Arden',
    group: 'elizabeth-arden',
  },
  elmer: { key: 'elmer', group: 'elmer' },
  'emporio armani': {
    key: 'emporio-armani',
    label: 'Emporio Armani',
    group: 'emporio-armani',
  },
  enortia: { key: 'enortia', group: 'enortia' },
  'faber castell': {
    key: 'faber-castell',
    label: 'Faber Castell',
    group: 'faber-castell',
  },
  'four pillars': {
    key: 'four-pillars',
    label: 'Four Pillars',
    group: 'four-pillars',
  },
  'freedom brewery': {
    key: 'freedom-brewery',
    label: 'Freedom Brewery',
    group: 'freedom-brewery',
  },
  'french connection': {
    key: 'french-connection',
    label: 'French Connection',
    group: 'french-connection',
  },
  'friday night dinner': {
    key: 'friday-night-dinner',
    label: 'Friday Night Dinner',
    group: 'friday-night-dinner',
  },
  'fudge kitchen': {
    key: 'fudge-kitchen',
    label: 'Fudge Kitchen',
    group: 'fudge-kitchen',
  },
  gautier: { key: 'gautier', group: 'gautier' },
  ghmily: { key: 'ghmily', group: 'ghmily' },
  'gin house': { key: 'gin-house', label: 'Gin House', group: 'gin-house' },
  'ginger ray': { key: 'ginger-ray', group: 'brands', label: 'Ginger Ray' },
  'glastonbury micro': {
    key: 'glastonbury-micro',
    label: 'Glastonbury Micro',
    group: 'glastonbury-micro',
  },
  glengoyne: { key: 'glengoyne', group: 'glengoyne' },
  godiva: { key: 'godiva', group: 'godiva' },
  'goliath games': {
    key: 'goliath-games',
    label: 'Goliath Games',
    group: 'goliath-games',
  },
  gucci: { key: 'gucci', group: 'gucci' },
  guess: { key: 'guess', group: 'guess' },
  guinness: { key: 'guinness', group: 'guinness' },
  gyo: { key: 'gyo', group: 'gyo' },
  'happy socks': { key: 'happy-socks', group: 'brands', label: 'Happy Socks' },
  hardys: { key: 'hardys', group: 'hardys' },
  havanna: { key: 'havanna', group: 'havanna' },
  haynes: { key: 'haynes', group: 'haynes' },
  henieken: { key: 'henieken', group: 'henieken' },
  holdsworth: { key: 'holdsworth', group: 'holdsworth' },
  'house of dorchester': {
    key: 'house-of-dorchester',
    label: 'House of Dorchester',
    group: 'house-of-dorchester',
  },
  'hugo boss': { key: 'hugo-boss', label: 'Hugo Boss', group: 'hugo-boss' },
  'i heart wines': {
    key: 'i-heart-wines',
    label: 'I Heart Wines',
    group: 'i-heart-wines',
  },
  'jack & jones': {
    key: 'jack-jones',
    label: 'Jack & Jones',
    group: 'jack-jones',
  },
  jacquart: { key: 'jacquart', group: 'jacquart' },
  'jean paul gaultier': {
    key: 'jean-paul-gaultier',
    label: 'Jean Paul Gaultier',
    group: 'jean-paul-gaultier',
  },
  'jiggly pets': {
    key: 'jiggly-pets',
    label: 'Jiggly Pets',
    group: 'jiggly-pets',
  },
  'jimmy choo': { key: 'jimmy-choo', label: 'Jimmy Choo', group: 'jimmy-choo' },
  'joma jewellery': {
    key: 'joma-jewellery',
    group: 'brands',
    label: 'Joma Jewellery',
  },
  joop: { key: 'joop', group: 'joop' },
  jumanji: { key: 'jumanji', group: 'jumanji' },
  'jurassic park': {
    key: 'jurassic-park',
    label: 'Jurassic Park',
    group: 'jurassic-park',
  },
  'katie loxton': {
    key: 'katie-loxton',
    group: 'brands',
    label: 'Katie Loxton',
  },
  'kin vodka': { key: 'kin-vodka', label: 'Kin Vodka', group: 'kin-vodka' },
  'kinetic sand': {
    key: 'kinetic-sand',
    label: 'Kinetic Sand',
    group: 'kinetic-sand',
  },
  kronenbourg: { key: 'kronenbourg', group: 'kronenbourg' },
  krug: { key: 'krug', group: 'krug' },
  lacoste: { key: 'lacoste', group: 'lacoste' },
  'le toy van': { key: 'le-toy-van', label: 'Le Toy Van', group: 'le-toy-van' },
  'lego architecture': {
    key: 'lego-architecture',
    label: 'Lego Architecture',
    group: 'lego-architecture',
  },
  'lego city': { key: 'lego-city', group: 'brands', label: 'Lego City' },
  'lego creator': {
    key: 'lego-creator',
    label: 'Lego Creator',
    group: 'lego-creator',
  },
  'lego dots': { key: 'lego-dots', label: 'Lego Dots', group: 'lego-dots' },
  'lego duplo': { key: 'lego-duplo', label: 'Lego Duplo', group: 'lego-duplo' },
  'lego friends': {
    key: 'lego-friends',
    group: 'brands',
    label: 'Lego Friends',
  },
  'lego technic': {
    key: 'lego-technic',
    group: 'brands',
    label: 'Lego Technic',
  },
  lindt: { key: 'lindt', group: 'lindt' },
  'little live pets': {
    key: 'little-live-pets',
    label: 'Little Live Pets',
    group: 'little-live-pets',
  },
  lol: { key: 'lol', group: 'lol' },
  lsw: { key: 'lsw', label: 'LSW', group: 'lsw' },
  'marc jacobs': {
    key: 'marc-jacobs',
    label: 'Marc Jacobs',
    group: 'marc-jacobs',
  },
  'marvins magic': {
    key: 'marvins-magic',
    label: 'Marvins Magic',
    group: 'marvins-magic',
  },
  'meredith wing': { key: 'meredith-wing', group: 'meredith-wing' },
  mionetto: { key: 'mionetto', group: 'mionetto' },
  missguided: { key: 'missguided', group: 'missguided' },
  'miu miu': { key: 'miu-miu', label: 'Miu Miu', group: 'miu-miu' },
  moleskine: { key: 'moleskine', group: 'moleskine' },
  moomin: { key: 'moomin', group: 'moomin' },
  'mould & paint': {
    key: 'mould-paint',
    group: 'brands',
    label: 'Mould & Paint',
  },
  'mr bean': { key: 'brands-mr-bean', group: 'brands', label: 'Mr Bean' },
  mugler: { key: 'mugler', group: 'mugler' },
  niederegger: { key: 'niederegger', group: 'niederegger' },
  ninjago: { key: 'ninjago', group: 'ninjago' },
  nio: { key: 'nio', group: 'nio' },
  'non-branded': {
    key: 'nonbranded',
    label: 'Non-branded',
    group: 'nonbranded',
  },
  'oh k!': { key: 'oh-k', label: 'Oh K!', group: 'oh-k' },
  'only fools & horses': {
    key: 'brands-only-fools-horses',
    group: 'brands-only-fools-horses',
  },
  'pac-man': { key: 'pacman', label: 'Pac-man', group: 'pacman' },
  'paco rabanne': {
    key: 'paco-rabanne',
    label: 'Paco Rabanne',
    group: 'paco-rabanne',
  },
  'palm pals': { key: 'palm-pals', label: 'Palm Pals', group: 'palm-pals' },
  'paul smith': { key: 'paul-smith', label: 'Paul Smith', group: 'paul-smith' },
  peroni: { key: 'peroni', group: 'peroni' },
  pinkster: { key: 'pinkster', group: 'pinkster' },
  'plum & ashby': {
    key: 'plum-ashby',
    label: 'Plum & Ashby',
    group: 'plum-ashby',
  },
  'pol roger': { key: 'pol-roger', label: 'Pol Roger', group: 'pol-roger' },
  'posh totty': { key: 'posh-totty', group: 'brands', label: 'Posh Totty' },
  'red 5': { key: 'red-5', group: 'red-5' },
  rihanna: { key: 'rihanna', group: 'rihanna' },
  'roberto cavalli': {
    key: 'roberto-cavalli',
    label: 'Roberto Cavalli',
    group: 'roberto-cavalli',
  },
  'sanctury spa': { key: 'sanctury-spa', group: 'sanctury-spa' },
  'sarah jessica parker': {
    key: 'sarah-jessica-parker',
    label: 'Sarah Jessica Parker',
    group: 'sarah-jessica-parker',
  },
  scratch: { key: 'scratch', group: 'scratch' },
  'sharper image': {
    key: 'sharper-image',
    label: 'Sharper Image',
    group: 'sharper-image',
  },
  sharps: { key: 'sharps', group: 'sharps' },
  'shimmer sparkle': {
    key: 'shimmer-sparkle',
    label: 'Shimmer Sparkle',
    group: 'shimmer-sparkle',
  },
  'shortbread house of edinburgh': {
    key: 'shortbread-house-of-edinburgh',
    label: 'Shortbread House of Edinburgh',
    group: 'shortbread-house-of-edinburgh',
  },
  sipsmith: { key: 'sipsmith', group: 'sipsmith' },
  'slush puppie': {
    key: 'slush-puppie',
    label: 'Slush Puppie',
    group: 'slush-puppie',
  },
  'smarter image': { key: 'smarter-image', group: 'smarter-image' },
  smirnoff: { key: 'smirnoff', group: 'smirnoff' },
  snoopy: { key: 'snoopy', group: 'snoopy' },
  'speed champions': {
    key: 'speed-champions',
    label: 'Speed Champions',
    group: 'speed-champions',
  },
  'stay active': {
    key: 'stay-active',
    label: 'Stay Active',
    group: 'stay-active',
  },
  steiff: { key: 'steiff', group: 'steiff' },
  'stretch armstrong': {
    key: 'stretch-armstrong',
    label: 'Stretch Armstrong',
    group: 'stretch-armstrong',
  },
  stuffaloons: { key: 'stuffaloons', group: 'stuffaloons' },
  swizzels: { key: 'swizzels', group: 'swizzels' },
  taittinger: { key: 'taittinger', group: 'taittinger' },
  tanqueray: { key: 'tanqueray', group: 'tanqueray' },
  'ted baker': { key: 'ted-baker', label: 'Ted Baker', group: 'ted-baker' },
  tetris: { key: 'tetris', group: 'tetris' },
  'the jelly bean factory': {
    key: 'the-jelly-bean-factory',
    label: 'The Jelly Bean Factory',
    group: 'the-jelly-bean-factory',
  },
  'thierry mugler': { key: 'thierry-mugler', group: 'thierry-mugler' },
  'toot toot': { key: 'toot-toot', label: 'Toot Toot', group: 'toot-toot' },
  torres: { key: 'torres', group: 'torres' },
  'traditional beer company': {
    key: 'traditional-beer-company',
    group: 'brands',
    label: 'Traditional Beer Company',
  },
  'treat republic': {
    key: 'treat-republic',
    label: 'Treat Republic',
    group: 'treat-republic',
  },
  'tsarine champagne': {
    key: 'tsarine-champagne',
    label: 'Tsarine Champagne',
    group: 'tsarine-champagne',
  },
  'two birds': { key: 'two-birds', label: 'Two Birds', group: 'two-birds' },
  versace: { key: 'versace', group: 'versace' },
  'very hungry caterpillar': {
    key: 'very-hungry-caterpillar',
    group: 'brands',
    label: 'Very Hungry Caterpillar',
  },
  'via vai': { key: 'via-vai', group: 'brands', label: 'Via Vai' },
  'viginia hayward': { key: 'viginia-hayward', group: 'viginia-hayward' },
  'virgin wines': {
    key: 'virgin-wines',
    group: 'brands',
    label: 'Virgin Wines',
  },
  'virginia hayward ltd': {
    key: 'virginia-hayward-ltd',
    group: 'virginia-hayward-ltd',
  },
  volvo: { key: 'volvo', group: 'volvo' },
  vtech: { key: 'vtech', group: 'vtech' },
  wanderflower: { key: 'wanderflower', group: 'wanderflower' },
  warmies: { key: 'warmies', group: 'warmies' },
  'would i lie to you': {
    key: 'would-i-lie-to-you',
    label: 'Would I Lie to You',
    group: 'would-i-lie-to-you',
  },
  yankee: { key: 'yankee', group: 'yankee' },
  yealands: { key: 'yealands', group: 'yealands' },
  zog: { key: 'zog', group: 'zog' },
  'pictures, art & frames': {
    key: 'pictures-art-frames',
    group: 'pictures-art-frames',
  },
  'gift sets, hampers & letterbox': {
    key: 'gift-sets-hampers-letterbox',
    group: 'gift-sets-hampers-letterbox',
  },
}
