import React, { useRef, useEffect, useMemo, FC } from 'react'
import { Grid, Box, AspectRatio } from '@moonpig/launchpad-components'
import { logger } from '@moonpig/web-core-monitoring'
import { Region } from '@moonpig/web-core-types'
import { useRouter } from '@moonpig/web-core-routing'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { useGetBreakpoint } from '@moonpig/web-shared-utils'
import { TrackedProductTile } from '../ProductTile/TrackedProductTile'
import { GroupCardFeatureTile } from '../FeatureTile/GroupCardFeatureTile'
import { ProductTileProduct } from '../ProductTile/ProductTileProduct'
import {
  ProductClickedEvent,
  AddedToBasketEvent,
  PromoTileData,
  TilesPerRow,
} from '../../types'
import { checkFavourited, useFavouritesState } from '../../contexts/favourites'
import { useHandleFavourite } from '../../utils/favourites/useHandleFavourite'
import { PromoTile } from '../PromoTile/PromoTile'
import {
  AD_ROW,
  AD_CELL_WIDTH,
  AD_CELL_WIDTH_WIDE,
} from '../PromoTile/constants'
import {
  calculateComponentWidth,
  calculateGroupTilePosition,
  formatGridLayout,
} from './utils'
import { GalleryProduct, ProductGridComponent, ProductGridItem } from './types'

const GALLERY_PAGE_SIZE = 24
const tilesPerRow: TilesPerRow = {
  xs: 2,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5,
  xxl: 6,
}

type ProductGridProps = {
  products: GalleryProduct[]
  totalNumberOfProducts: number
  onEndApproaching: () => void
  onTopApproaching: () => void
  onOffsetBorderApproaching: (productIndex: number) => void
  onProductClicked: (clickedEvent: ProductClickedEvent) => void
  onProductFirstClick: (clickedEvent: ProductClickedEvent) => void
  onAddToBasket: (addToBasketEvent: AddedToBasketEvent) => void
  isInternalUser?: boolean
  currentPage?: number
  departments: DepartmentsEnum[]
  trackingData: {
    region: Region
    pageType: string
    metaTitle?: string
  }
  groupCardProject?: string
  showGroupCardTile?: boolean
  promoTile?: PromoTileData
  onGroupCardsTileShow?: () => void
  onGroupCardsCTAClick?: () => void
}

const StyledGrid = styled(Grid)`
  ${s({
    mt: 5,
    paddingX: { xs: 4, md: 8 },
  })}
`
export const ProductGrid: FC<ProductGridProps> = ({
  products,
  totalNumberOfProducts,
  onProductClicked,
  onProductFirstClick,
  onAddToBasket,
  onEndApproaching,
  onTopApproaching,
  onOffsetBorderApproaching,
  isInternalUser = false,
  departments,
  trackingData,
  groupCardProject,
  showGroupCardTile,
  promoTile,
  onGroupCardsCTAClick,
  onGroupCardsTileShow,
}) => {
  const { favourites: favouritedProducts } = useFavouritesState()
  const duplicates = useRef<string[]>([])
  const lastVisitedProduct = useRef<HTMLDivElement | null>(null)
  const productIds = products.map(product => product.id)
  const router = useRouter()

  const { params } = router.getCurrentRoute<'content'>()
  const { productId: lastVisitedProductId } = params

  const tracking = useMemo(() => {
    return {
      totalCount: products.length,
      pageLocation: trackingData.pageType,
    }
  }, [products.length, trackingData.pageType])

  const handleFavourite = useHandleFavourite({
    tracking,
    removeWithConfirmation: false,
  })

  useEffect(() => {
    const productMarker = lastVisitedProduct.current
    if (productMarker && lastVisitedProductId) {
      const productElement = productMarker.parentElement as HTMLElement
      productElement.scrollIntoView({ block: 'center' })
      lastVisitedProduct.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const currentDuplicates = productIds.filter(
    (item, index) => productIds.indexOf(item) !== index,
  )

  if (duplicates.current.length < currentDuplicates.length) {
    duplicates.current = currentDuplicates
    logger.warning(
      `Product Grid: Duplicate items found (page ${Math.ceil(
        products.length / GALLERY_PAGE_SIZE,
      )}) - ${JSON.stringify(duplicates.current)}`,
    )
  }

  const isComponentCheck = (product: ProductGridItem) => {
    return 'component' in product ? product.component : ''
  }

  const breakpoint = useGetBreakpoint()

  const isGallery = true
  const parentDepartment = getParentDepartment(departments[0])
  const isCard = parentDepartment === 'ALL_CARDS'

  const currentTilesPerRow = tilesPerRow[breakpoint]

  const shouldUseWideADTile = currentTilesPerRow === tilesPerRow.md
  /* istanbul ignore next */
  const promoCellWidth = shouldUseWideADTile
    ? AD_CELL_WIDTH_WIDE
    : AD_CELL_WIDTH

  /* istanbul ignore next */
  const addPromoTileSpace = promoTile ? promoCellWidth : 0

  const promoTileLocation = promoTile
    ? currentTilesPerRow * AD_ROW - promoCellWidth
    : 0

  const groupCardTilelocation = showGroupCardTile
    ? calculateGroupTilePosition(currentTilesPerRow, addPromoTileSpace)
    : 0

  const productGridElements = formatGridLayout(
    products,
    promoTileLocation,
    groupCardTilelocation,
  )

  return (
    <StyledGrid gap>
      {productGridElements.map((currentProduct, index) => {
        const isFavourited = checkFavourited(
          favouritedProducts,
          currentProduct as ProductTileProduct,
        )
        /* istanbul ignore next */
        const productIndex = currentProduct.productIndex
          ? currentProduct?.productIndex
          : index
        const isLastItem = index + 1 === products.length
        const isFirstItem = index === 0
        const isOffsetBorderItem = productIndex % GALLERY_PAGE_SIZE === 0
        const cardsReservedSpaceRatio = 3 / 4
        const cardsReservedSpaceHeight = '200px'

        const isComponent = 'component' in currentProduct

        const pageTileProps = {
          isCardProduct: isCard,
          key: currentProduct.id,
          product: currentProduct as ProductTileProduct,
          productIndex,
          totalNumberOfProducts,
          trackingData,
          onAddToBasket,
          onClick: onProductClicked,
          onFirstClick: onProductFirstClick,
          isLastItem,
          isFirstItem,
          isOffsetBorderItem,
          onEndApproaching,
          onTopApproaching,
          onOffsetBorderApproaching,
          isInternalUser,
          isFavourited,
          handleFavourite,
          isGallery,
          groupCardProject,
        }
        return (
          <Box
            key={`${currentProduct.id}-${productIndex}`}
            data-testid="web-grid-item-wrapper"
            className="web-grid-item-wrapper"
            data-product-id={currentProduct.id}
            width={{
              xs: calculateComponentWidth(
                isComponentCheck(currentProduct),
                tilesPerRow.xs,
              ),
              sm: calculateComponentWidth(
                isComponentCheck(currentProduct),
                tilesPerRow.sm,
              ),
              md: calculateComponentWidth(
                isComponentCheck(currentProduct),
                tilesPerRow.md,
              ),
              lg: calculateComponentWidth(
                isComponentCheck(currentProduct),
                tilesPerRow.lg,
              ),
              xl: calculateComponentWidth(
                isComponentCheck(currentProduct),
                tilesPerRow.xl,
              ),
              xxl: calculateComponentWidth(
                isComponentCheck(currentProduct),
                tilesPerRow.xxl,
              ),
            }}
          >
            {lastVisitedProductId === currentProduct.id && (
              <div
                data-testid="web-last-visited-product-id-marker"
                ref={lastVisitedProduct}
              />
            )}

            {isComponent ? (
              <>
                {showGroupCardTile &&
                  currentProduct.component ===
                    ProductGridComponent.groupCardTile && (
                    <GroupCardFeatureTile
                      region={trackingData.region}
                      onCTAClick={onGroupCardsCTAClick}
                      onInView={onGroupCardsTileShow}
                    />
                  )}
                {promoTile &&
                  currentProduct.component ===
                    ProductGridComponent.promoTile && (
                    <PromoTile {...promoTile} isCardGallery={isCard} />
                  )}
              </>
            ) : (
              <>
                {isCard ? (
                  <AspectRatio
                    data-testid="cards-aspect-ratio"
                    ratio={cardsReservedSpaceRatio}
                    style={{
                      minHeight: cardsReservedSpaceHeight,
                    }}
                  >
                    <TrackedProductTile {...pageTileProps} />
                  </AspectRatio>
                ) : (
                  <TrackedProductTile {...pageTileProps} />
                )}
              </>
            )}
          </Box>
        )
      })}
    </StyledGrid>
  )
}
