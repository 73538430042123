import React, { FC, memo, PropsWithChildren, useMemo } from 'react'
import {
  isFacetSelected,
  selectedAllFilters,
  selectedDescendants,
  shallowCompare,
  facetsAreEqual,
} from '../../utils'
import { useProductListingPageContext } from '../../utils/productListingPageContext'
import { FilterOptions } from './FilterOptions'
import { SlidingOption } from './SlidingOption'
import { AllSelectedChangedEvent, FilterChangedEvent } from './types'
import { FilterServiceFilterFacet } from '../../services/types/services'
import { FiltersPageType } from '../types'

type FilterCategoryProps = {
  facet: FilterServiceFilterFacet
  openFilterCategory: string | undefined
  openOption: string | undefined
  allowClear: boolean
  selectedFacets: FilterServiceFilterFacet[]
  filterChanged: FilterChangedEvent
  allSelectedChanged: AllSelectedChangedEvent
  setOpenFilterCategory: (facetKey: string) => void
  setOpenOption: (facetKey: string) => void
  onClearAll: () => void
  pageType: FiltersPageType
}

const FilterCategoryComponent: FC<FilterCategoryProps> = ({
  facet,
  openFilterCategory,
  openOption,
  allowClear,
  selectedFacets,
  setOpenFilterCategory,
  setOpenOption,
  filterChanged,
  allSelectedChanged,
  onClearAll,
  pageType,
}) => {
  const [{ loading: loadingAll }] = useProductListingPageContext()

  const selectedChildren = useMemo(
    () => selectedDescendants(facet, selectedFacets),
    [facet, selectedFacets],
  )

  const selectedAllAppliedFilters = useMemo(
    () => selectedAllFilters(facet, selectedChildren),
    [facet, selectedChildren],
  )

  const selected =
    isFacetSelected(facet, selectedFacets) || !!selectedChildren.length

  const isOptionSelected = openFilterCategory === facet.facetKey

  const { label, facetKey } = facet

  return (
    <SlidingOption
      root
      facetKey={facetKey}
      label={label}
      isOptionSelected={isOptionSelected}
      onSelection={setOpenFilterCategory}
      isMixedView={false}
      selectedChildren={selectedChildren}
      selected={selected}
      allSelected={selectedAllAppliedFilters}
      loading={loadingAll}
      visible={!!facet.children?.length}
      pageType={pageType}
    >
      <FilterOptions
        facet={facet}
        selectedChildren={selectedChildren}
        filterChanged={filterChanged}
        setOpenFilterCategory={setOpenFilterCategory}
        openFilterCategory={openFilterCategory}
        onClearAll={onClearAll}
        allowClear={allowClear}
        allSelectedChanged={allSelectedChanged}
        loading={loadingAll}
        setOpenOption={setOpenOption}
        openOption={openOption}
        pageType={pageType}
      />
    </SlidingOption>
  )
}

/* istanbul ignore next */
const propsAreEqual = (
  prevProps: Readonly<PropsWithChildren<FilterCategoryProps>>,
  nextProps: Readonly<PropsWithChildren<FilterCategoryProps>>,
): boolean => {
  const {
    facet: firstFacet,
    selectedFacets: firstSelectedChildren,
    ...firstProps
  } = prevProps
  const {
    facet: secondFacet,
    selectedFacets: secondSelectedChildren,
    ...secondProps
  } = nextProps

  return (
    shallowCompare(firstProps, secondProps) &&
    (firstFacet === secondFacet || facetsAreEqual(firstFacet, secondFacet)) &&
    (firstSelectedChildren === secondSelectedChildren ||
      (firstSelectedChildren.length === secondSelectedChildren.length &&
        firstSelectedChildren
          .map((x, i) => facetsAreEqual(x, secondSelectedChildren[i]))
          .every(x => x)))
  )
}

export const FilterCategory = memo(FilterCategoryComponent, propsAreEqual)
