import React, { FC, PropsWithChildren } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { CarouselItem as CarouselElement } from '@moonpig/launchpad-components'
import { CAROUSEL_ITEM_HEIGHT_PX } from '../constants'

const StyledCarouselItem = styled(CarouselElement)`
  height: ${CAROUSEL_ITEM_HEIGHT_PX};
  overflow: hidden;
`

type Props = PropsWithChildren<{
  key: string
}>

export const CarouselItem: FC<Props> = ({ key, children }) => (
  <StyledCarouselItem key={key} tabIndex={-1}>
    {children}
  </StyledCarouselItem>
)
