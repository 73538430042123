import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useFindLocaleText } from '../../text-localisation'

type InternalUserGalleryInfoProps = {
  productCount: number
  searchTerm: string
  filters: { facetKey: string; group: string }[]
  promotionId?: string
  totalClickRankDocumentCount: number
}

const StyledList = styled.ul`
  ${s({
    position: 'relative',
    zIndex: 1,
    bgcolor: 'colorBackgroundSite',
    border: '1px solid',
    borderColor: 'colorBodyText',
    color: 'colorBodyText',
    p: 5,
  })}
`

export const InternalUserGalleryInfo: FC<InternalUserGalleryInfoProps> = ({
  productCount,
  searchTerm,
  filters,
  promotionId,
  totalClickRankDocumentCount,
}) => {
  const filterElements = filters.map(entry => {
    return (
      <div key={`${entry.group}:${entry.facetKey}`}>
        {entry.group} : {entry.facetKey}
      </div>
    )
  })
  const localiseText = useFindLocaleText()

  return (
    <StyledList role="list">
      <li aria-label={localiseText('find.product_count', { productCount })}>
        <strong>{localiseText('find.product_count', { productCount })}</strong>
      </li>
      <li aria-label={localiseText('find.search_term', { searchTerm })}>
        <strong>{localiseText('find.search_term', { searchTerm })}</strong>
      </li>
      <li aria-label={localiseText('find.promotion_id', { promotionId })}>
        <strong>{localiseText('find.promotion_id', { promotionId })}</strong>
      </li>
      <li>
        <strong>Filters:</strong>
        {filterElements}
      </li>
      <li>
        <strong>Total click rank event count:</strong>
        {totalClickRankDocumentCount}
      </li>
    </StyledList>
  )
}
