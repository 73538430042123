import { SearchFilter } from '../services'

export const filtersAreEqual = (
  first: SearchFilter[],
  second: SearchFilter[],
) => {
  return (
    first.length === second.length &&
    (first.length === 0 ||
      (first.every(f =>
        second.some(s => s.key === f.key && s.group === f.group),
      ) &&
        second.every(s =>
          first.some(f => f.key === s.key && f.group === s.group),
        )))
  )
}
