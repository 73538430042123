import React from 'react'
import { Box } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { FilterItem } from '../../../services/types/services'
import {
  FilterHeader,
  FilterCategory,
  FilterOption,
  FilterToggle,
  AllFilter,
} from '../components'

const StyledWrapper = styled(Box)`
  ${s({
    py: 3,
    borderBottom: '1px solid',
    borderColor: 'colorBorder03',
  })}
`

type Props = {
  filterItem: FilterItem
  onClick: (filter: FilterItem) => void
  level?: number
}

export const mapFilterItem = ({ filterItem, onClick, level = 0 }: Props) => {
  switch (filterItem.__typename) {
    case 'FilterHeader':
      return (
        <FilterHeader
          label={filterItem.label}
          key={`${filterItem.label}-header`}
        />
      )

    case 'FilterCategory':
      return filterItem.children.length ? (
        <FilterCategory
          filter={filterItem}
          onClick={onClick}
          key={`${filterItem.id}-category`}
          level={level}
        />
      ) : null

    case 'Filter':
      return (
        <FilterOption
          filter={filterItem}
          key={`${filterItem.id}-filter`}
          onClick={onClick}
        />
      )

    case 'AllFilter':
      return (
        <AllFilter
          filter={filterItem}
          key={`${filterItem.id}-filter`}
          onClick={onClick}
        />
      )

    case 'FilterToggle':
    case 'FilterInvertedToggle':
      return (
        <StyledWrapper key={`${filterItem.id}-filter`}>
          <FilterToggle filter={filterItem} onChange={onClick} />
        </StyledWrapper>
      )

    /* istanbul ignore next */
    default:
      return null
  }
}
