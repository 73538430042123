import { SortOrder } from '@moonpig/web-explore-types-graphql'
import { RouterType } from '@moonpig/web-core-routing'
import { PageType } from '../constants'
import {
  AllFilter,
  ApplicableFilter,
  Filter,
  FilterCategory,
  FilterItem,
  FilterServiceFilterItem,
  SearchFilter,
} from '../services/types/services'
import type { FilteringEvent, FilterSource } from '../types'

type PageContext = { title: string; type: PageType; router: RouterType | null }

export type PageStateProps = {
  pageContext: PageContext
}

export type StateProps = {
  pageContext: PageContext
  filtersMenuOpen: boolean
  filtersLoading: boolean
  filters: FilterItem[]
  allCategoryFilters: string[]
  preAppliedFilters: SearchFilter[]
  selectedFilters: ApplicableFilter[]
  filteringEvent: FilteringEvent
  selectedCategories: FilterCategory[]
  queryFilters: ((filters: SearchFilter[]) => Promise<FilterItem[]>) | null
  results: { count: number }
  initialSortValue: SortOrder
  sortValue: SortOrder
  sortMenuOpen: boolean
}

type StateDispatchers = {
  toggleFiltersMenu: (source?: 'sort' | 'filters') => void
  toggleFiltersCategory: (filtersCategory: FilterCategory) => void
  toggleFiltersLoading: () => void
  createPageContext: (props: PageStateProps) => void
  loadFilters: (props: {
    query: (
      filters: SearchFilter[],
    ) => Promise<{ facets: FilterServiceFilterItem[]; filters: FilterItem[] }>
    initialFilters: SearchFilter[]
    urlFilters: SearchFilter[]
    initialSortValue: SortOrder
    results: { count: number }
  }) => void
  toggleFilter: ({
    filter,
    select,
    source,
  }: {
    filter: ApplicableFilter
    select: boolean
    source: FilterSource
  }) => void
  toggleAllCategoryFilters: ({
    filter,
    select,
    source,
  }: {
    filter: AllFilter
    select: boolean
    source: FilterSource
  }) => void
  updateResults: (results: { count: number }) => void
  clearFilters: (category?: FilterCategory, type?: 'filters' | 'sort') => void
  sortBy: (value: SortOrder) => void
}

export type SearchStoreState = StateProps & StateDispatchers

export enum TrackingActionsEnum {
  toggleFiltersMenu = 'toggleFiltersMenu',
  toggleFiltersCategory = 'toggleFiltersCategory',
  clearFilters = 'clearFilters',
  sortBy = 'sortBy',
}

export type TrackingActionTypes = keyof typeof TrackingActionsEnum

export type TrackingPayload = Filter[] | string[] | FilterCategory[]
