import { Flex } from '@moonpig/launchpad-components'
import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import React, { FC, PropsWithChildren } from 'react'

const StyledRudeFilterToggleWrapper = styled(Flex)`
  ${breakpointUp('md')} {
    display: none;
  }
`

export type RudeFilterToggleWrapperProps = {
  isCard: boolean
}

export const RudeFilterToggleWrapper: FC<
  PropsWithChildren<RudeFilterToggleWrapperProps>
> = ({ children, isCard }) => {
  const showExcludeRudeToggle =
    !(process.env.SEARCH_DISABLE_RUDE_TOGGLE === 'true') && isCard

  return (
    <>
      {showExcludeRudeToggle && (
        <StyledRudeFilterToggleWrapper
          data-testid={'rude-filter-toggle-wrapper'}
          flexDirection="column"
          alignItems="end"
          marginBottom={5}
          width={'100%'}
        >
          {children}
        </StyledRudeFilterToggleWrapper>
      )}
    </>
  )
}
