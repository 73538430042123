import { groupCardSearchTerms } from '@moonpig/web-core-app-shell-search'
import { SearchFiltersFacet } from '../pages/GalleryPage/types'
import { SelectedSuggestion } from '../components/types'
import { FilterServiceFilterFacet } from '../services/types/services'

const groupCardFacetKeys = [
  'occasion-leaving',
  'occasion-new-job',
  'occasion-retirement',
  'occasion-thank-you',
  'teacher',
  'occasion-good-luck',
  'occasion-wedding',
  'occasion-sympathy',
  'occasion-sorry',
  'occasion-thinking-of-you',
  'just-to-say',
]

export const isGroupCardsSearchContext = (
  term: string | undefined,
  presetFacets: SearchFiltersFacet[],
  facets: FilterServiceFilterFacet[],
  selectedSuggestion?: SelectedSuggestion,
) => {
  if (process.env.SEARCH_DISABLE_GROUP_CARD_TILE?.toString() === 'true') {
    return false
  }

  // does search term match group card search term list
  if (term && groupCardSearchTerms.includes(term.toLowerCase())) {
    return true
  }

  // do any presetFacets match the group card facet list
  if (
    presetFacets.filter(
      x => x.facetKey && groupCardFacetKeys.includes(x.facetKey.toLowerCase()),
    ).length > 0
  ) {
    return true
  }

  // do any applied facets match the group card facet list
  if (
    facets.filter(
      x => x.facetKey && groupCardFacetKeys.includes(x.facetKey.toLowerCase()),
    ).length > 0
  ) {
    return true
  }

  if (
    selectedSuggestion &&
    groupCardFacetKeys.includes(selectedSuggestion.facetKey)
  ) {
    return true
  }

  return false
}
