import React from 'react'
import { Chip } from '@moonpig/launchpad-components'
import { LocaleTextHook } from '@moonpig/web-core-locale-text'
import { MAX_APPLIED_FILTERS_TO_SHOW_NBAS } from './constants'
import { ApplicableFilter, Filter } from '../../services/types/services'
import { FilterChip, CarouselItem } from './components'
import { FIND_TEXT } from '../../text-localisation/localeText'
import { FilterSource } from '../../types'

type Props = {
  filters: Filter[]
  toggleFilter: ({
    filter,
    select,
    source,
  }: {
    filter: ApplicableFilter
    select: boolean
    source: FilterSource
  }) => void
  selectedFilters: ApplicableFilter[]
  filtersLoading: boolean
  localiseText: LocaleTextHook<typeof FIND_TEXT>
}

export const createCarouselFilterItems = ({
  filters,
  toggleFilter,
  selectedFilters,
  filtersLoading,
  localiseText,
}: Props) => {
  const appliedFilters = selectedFilters.map(filter => {
    const filterLabel =
      filter.__typename === 'FilterInvertedToggle'
        ? localiseText('find.inverted_toggle_filter_label', {
            label: filter.label,
          })
        : filter.label
    return (
      <CarouselItem key={`${filter.id}-applied`}>
        <Chip
          dismissible
          selected
          onClick={() =>
            toggleFilter({
              filter,
              select: false,
              source: FilterSource.NextBestAction,
            })
          }
          aria-label={localiseText(
            'find.remove_facet_group_filter_facet_label',
            {
              group: filter.parent,
              label: filterLabel,
            },
          )}
        >
          {filterLabel}
        </Chip>
      </CarouselItem>
    )
  })

  const getNextBestActionFilters = () => {
    if (
      filtersLoading ||
      selectedFilters.length >= MAX_APPLIED_FILTERS_TO_SHOW_NBAS
    ) {
      return [
        <CarouselItem key={'empty-nbas'}>
          <></>
        </CarouselItem>,
      ]
    }

    return filters
      .map(filter => ({
        ...filter,
        nbaScore: filter.nbaScore ?? /* istanbul ignore next */ 0,
      }))
      .filter(filter => filter.nbaScore)
      .sort((a, b) => a.nbaScore - b.nbaScore)
      .map(filter => (
        <FilterChip
          key={`${filter.id}-available-nbas`}
          filter={filter}
          applyNba={() =>
            toggleFilter({
              filter,
              select: true,
              source: FilterSource.NextBestAction,
            })
          }
        />
      ))
  }

  const nextBestActionFilters = getNextBestActionFilters()

  return [...appliedFilters, ...nextBestActionFilters]
}
