import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { CarouselItem, Chip } from '@moonpig/launchpad-components'
import { Filter } from '../../../services/types/services'
import { CAROUSEL_ITEM_HEIGHT_PX } from '../constants'
import { useFindLocaleText } from '../../../text-localisation'

const StyledCarouselItem = styled(CarouselItem)`
  height: ${CAROUSEL_ITEM_HEIGHT_PX};
  overflow: hidden;
`

export const FilterChip: FC<{
  filter: Filter
  applyNba: ({
    filter,
    select,
    source,
  }: {
    filter: Filter
    select: boolean
    source: 'carousel' | 'menu'
  }) => void
}> = ({ filter, applyNba }) => {
  const localiseText = useFindLocaleText()

  return (
    <StyledCarouselItem>
      <Chip
        dismissible
        selected={false}
        onClick={() => applyNba({ filter, select: true, source: 'carousel' })}
        aria-label={localiseText('find.apply_facet_group_filter_facet_label', {
          group: filter.parent,
          label: filter.label.toLowerCase(),
        })}
      >
        {filter.label}
      </Chip>
    </StyledCarouselItem>
  )
}
