import { styled, breakpointDown, breakpointUp } from '@moonpig/launchpad-utils'
import React, { FC, PropsWithChildren } from 'react'
import { LAYOUT_BREAKPOINT } from './constants'

const StyledSlidingPanel = styled.div.attrs(
  ({
    isSecondaryPanel,
    isNestedPanel,
  }: {
    isSecondaryPanel: boolean
    isNestedPanel: boolean
  }) => ({
    className: `${isSecondaryPanel && 'is-secondary-panel'} ${
      isNestedPanel && 'is-nested-panel'
    } `,
  }),
)<{
  isSecondaryPanel: boolean
  isNestedPanel: boolean
}>`
  display: flex;
  flex-flow: column;

  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    max-height: calc(100vh - 84px);
    height: 100%;
    &.is-secondary-panel {
      max-height: 100%;
      width: 100%;
    }
  }

  ${breakpointUp('xl')} {
    max-height: calc(100vh - 84px);
  }

  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;

    &.is-secondary-panel {
      transform: translateX(
        ${({ isNestedPanel }) => (isNestedPanel ? '75%' : '50%')}
      );
    }

    &.is-nested-panel {
      width: 200%;
    }
  }
`

const StyledScrollableWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
`

type SlidingPanelProps = {
  isPrimaryPanel?: boolean
  isNestedPanel?: boolean
}

export const SlidingPanel: FC<PropsWithChildren<SlidingPanelProps>> = ({
  children,
  isPrimaryPanel = false,
  isNestedPanel = false,
}) => {
  return (
    <StyledSlidingPanel
      data-testid="web-find-sliding-panel"
      isSecondaryPanel={!isPrimaryPanel}
      isNestedPanel={!!isNestedPanel}
    >
      <StyledScrollableWrapper>{children}</StyledScrollableWrapper>
    </StyledSlidingPanel>
  )
}
