import { Box } from '@moonpig/launchpad-components'
import React, { FC, useEffect } from 'react'
import { Region } from '@moonpig/web-core-types'
import { useInView } from 'react-intersection-observer'
import { FeatureTile } from './FeatureTile'
import groupCardsImage from '../../assets/group-cards.svg'
import { useLocaleText } from './text-localisation'

type GroupCardFeatureTileProps = {
  region: Region
  onCTAClick?: () => void
  onInView?: () => void
}

export const GroupCardFeatureTile: FC<GroupCardFeatureTileProps> = ({
  region,
  onCTAClick,
  onInView,
}) => {
  const t = useLocaleText()
  const groupCardsUrl =
    region === 'us'
      ? `/${region}/group-cards/`
      : `/${region}/personalised-cards/group-cards/`

  const onClickGroupCards = () => {
    if (onCTAClick) {
      onCTAClick()
    }

    window.location.assign(groupCardsUrl)
  }

  const image = {
    src: groupCardsImage,
    alt: 'Group cards image',
    height: '218px',
  }

  const [boxRef, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView && onInView) {
      onInView()
    }
  }, [inView, onInView])

  const listItems = [
    <li key={1}>
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t('feature_tile_group_cards.list_item_1'),
        }}
      />
    </li>,
    <li key={2}>
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t('feature_tile_group_cards.list_item_2'),
        }}
      />
    </li>,
    <li key={3}>
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t('feature_tile_group_cards.list_item_3'),
        }}
      />
    </li>,
  ]

  return (
    <Box
      data-testid={'search-group-card-feature-tile'}
      marginBottom={6}
      flex={'0 0 100%'}
      ref={boxRef}
    >
      <Box height={'100%'} p={{ xs: 4, lg: 5, xxl: 10 }}>
        <FeatureTile
          title={t('feature_tile.group_cards.title')}
          pill={{
            displayLabel: t('feature_tile.group_cards.pill'),
            displayVariant: 'marketing',
          }}
          listItems={listItems}
          cta={onClickGroupCards}
          ctaLabel={t('feature_tile.group_cards.call_to_action')}
          image={image}
        />
      </Box>
    </Box>
  )
}
