import { SelectedSuggestion } from '../components/types'
import { SearchFilter } from '../services'
import { FilterInput } from '../services/types/services'

export const collateFacetInputs = (
  appliedFacets: FilterInput[],
  preappliedFacets: FilterInput[],
  selectedSuggestion: Omit<SelectedSuggestion, 'label'> | undefined,
): SearchFilter[] => {
  let unlabelledFacets = [...appliedFacets, ...preappliedFacets].map(f => ({
    key: f.facetKey,
    group: f.group,
  }))
  if (selectedSuggestion) {
    unlabelledFacets = unlabelledFacets.concat([
      {
        key: selectedSuggestion.facetKey,
        group: selectedSuggestion.group,
      },
    ])
  }
  return unlabelledFacets
}
