import { Experiments } from '@moonpig/web-core-experiments'
import { Region } from '@moonpig/web-core-types'
import { type AllFlagIDs } from '@moonpig/web-explore-flags'
import { getCardVariantIdsEnabled } from '@moonpig/web-shared-products'
import { SearchFeatures } from '../types/searchFeatures'

export type LoadFeaturesProps = {
  region: Region
  experiments: Experiments
  flags: Partial<Record<AllFlagIDs, boolean>>
}

export const loadFeatures: (props: LoadFeaturesProps) => SearchFeatures = ({
  experiments,
  flags,
}) => {
  const isFlagOn = (key: string) => flags[key as keyof typeof flags] ?? false
  const isExperimentEnabled = (key: string) =>
    experiments[key]?.toLowerCase() === 'enabled'

  const showCollageMaker = isFlagOn('search-show-collage-maker')
  const showSponsoredProducts = isFlagOn('show-sponsored-products')
  const excludeDirectSmile =
    experiments['gallery-direct-smile-impact'] === 'ExcludeDirectSmile'
  const showGiftVouchers = isExperimentEnabled('show-gift-vouchers')

  const sharedRankingExperiment = isExperimentEnabled(
    'search-use-shared-ranking-v2',
  )
  const vectorSearchExperiment = isExperimentEnabled('search-use-vector-search')
  const aiDataExperiment = isExperimentEnabled('search-use-ai-generated-data')
  const dropdownFiltersExperiment = isExperimentEnabled(
    'search-use-dropdown-filters',
  )
  const showExternallySponsoredProducts =
    isExperimentEnabled('search-show-externally-sponsored-products') ||
    isExperimentEnabled('search-show-externally-sponsored-products-v2')

  const useVectorSearch =
    isFlagOn('search-use-vector-search') || vectorSearchExperiment
  const useSharedRankingV2 =
    isFlagOn('search-use-shared-ranking-v2') || sharedRankingExperiment
  const cardVariants = getCardVariantIdsEnabled()
  const nbaAlgorithm = 'NBA_4'

  const experimentValues = [
    'search-use-new-ia-index=true',
    useSharedRankingV2 && 'search-use-shared-ranking-v2=true',
    useVectorSearch && 'search-use-vector-search=true',
    aiDataExperiment && 'search-use-ai-generated-data=true',
    dropdownFiltersExperiment && 'search-use-dropdown-filters=true',
  ]
    .filter(Boolean)
    .join('&')

  return {
    showGiftVouchers,
    excludeDirectSmile,
    searchUseNewIaIndex: true,
    showCollageMaker,
    cardVariants,
    experimentValues,
    nbaAlgorithm,
    showSponsoredProducts,
    showExternallySponsoredProducts,
  }
}
