import { RouterType } from '@moonpig/web-core-routing'
import { ApplicableFilter } from '../../services/types/services'

type Props = {
  router: RouterType | null
  selectedFilters: ApplicableFilter[]
}

export const updateRoute = ({ router, selectedFilters }: Props) => {
  if (!router) {
    return
  }
  const { name, params } = router.getCurrentRoute<'content'>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { page, filters, ...existingQuery } = params

  const updatedFilters = selectedFilters
    ?.map(({ parent, id }) => `${parent}:${id}`)
    .join('.')

  return router.replace({
    name,
    params: {
      ...existingQuery,
      ...(updatedFilters && { filters: updatedFilters }),
    },
    shallow: true,
  })
}
