import React, { FC } from 'react'
import { Flex, Text } from '@moonpig/launchpad-components'
import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import { Toggle } from '@moonpig/launchpad-forms'
import { system as s } from '@moonpig/launchpad-system'
import { colorValue } from '@moonpig/launchpad-theme'
import { setBrowserCookie } from '@moonpig/web-core-cookies'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { useFlag } from '@moonpig/web-explore-flags'
import { useProductListingPageContext } from '../../utils/productListingPageContext'
import { useFindLocaleText } from '../../text-localisation'
import { useSearchContext } from '../../utils'

const TOGGLE_NAME = 'rude-switch'

const StyledRudeFilterText = styled.label`
  ${s({ typography: 'typeBodyLabel' })}
  white-space: nowrap;
  cursor: pointer;

  ${breakpointUp('md')} {
    ${s({ typography: 'typeDisplay06' })}
  }
`

const StyledRudeFilterFlex = styled(Flex)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  justify-content: flex-end;

  ${s({
    pl: 6,
    pr: 4,
  })}

  ${breakpointUp('md')} {
    min-height: 64px;
    border-bottom: 1px solid ${colorValue('colorBorder03')};
    justify-content: space-between;
    ${s({ py: 4 })}
  }
`

const DesktopOnlyText = styled(Text)`
  display: none;
  ${breakpointUp('md')} {
    display: inline;
    ${s({ typography: 'typeDisplay06' })}
  }
`

export const RudeFilterToggle: FC = () => {
  const [state, dispatch] = useProductListingPageContext()
  const localiseText = useFindLocaleText()
  const { departments } = useSearchContext()
  const department = getParentDepartment(departments[0])
  const filtersMenuRebuild = useFlag('search-filters-menu-rebuild')

  const applyRudeFilter = () => {
    // this will toggle the state and apply the new state to the cookie
    const newState = !state.excludeRudeProducts
    setBrowserCookie('mnpg_exclude_rude_products', newState.toString(), {
      path: '/',
    })
    dispatch({ type: 'TOGGLE_RUDE_FILTER', payload: newState })
  }

  const displayRudeToggle: boolean =
    department === 'ALL_CARDS' && !filtersMenuRebuild

  return displayRudeToggle ? (
    <StyledRudeFilterFlex data-testid={'rude-filter-toggle-flex'}>
      <StyledRudeFilterText htmlFor={TOGGLE_NAME}>
        <DesktopOnlyText>{localiseText('find.show')}</DesktopOnlyText>
        {localiseText('find.toggle_rude')}
        {localiseText('common.cards')}
      </StyledRudeFilterText>
      <Toggle
        name={TOGGLE_NAME}
        checked={!state.excludeRudeProducts}
        onChange={() => applyRudeFilter()}
        value="Show rude products"
        ariaLabel="show rude products toggle"
      />
    </StyledRudeFilterFlex>
  ) : null
}
