import { gql } from '@moonpig/web-core-graphql'
import { GetProducts_productSearch_products as Product } from './types-graphql'
import { PRODUCT_FIELDS_FRAGMENT } from '../queries/productFields'
import { facetsAreEqual } from './GalleryFiltering/facetsAreEqual'
import { SearchFiltersFacet } from '../pages/GalleryPage/types'

export const GetProductsGQL = gql`
  query GetProducts(
    $searchTerm: String!
    $limit: Int!
    $offset: Int!
    $filters: CriteriaInput!
    $secondProductSearchFilters: CriteriaInput
    $thirdProductSearchFilters: CriteriaInput
    $department: [DepartmentsEnum!]
    $returnSuggestions: Boolean!
    $secondDepartment: [DepartmentsEnum!]
    $thirdDepartment: [DepartmentsEnum!]
    $searchOnAllParentDepartments: Boolean!
    $experimentValues: String
    $platform: Platform
    $nbaAlgorithm: String
    $sortOrder: SortOrder
  ) {
    productSearch(
      searchTerm: $searchTerm
      department: $department
      limit: $limit
      offset: $offset
      filters: $filters
      experimentValues: $experimentValues
      platform: $platform
      nbaAlgorithm: $nbaAlgorithm
      sortOrder: $sortOrder
    ) {
      count
      totalClickRankDocumentCount
      products {
        ...ProductSearchFields
      }
      suggestions @include(if: $returnSuggestions) {
        ...ProductSearchFields
      }
      spellingCorrection {
        initialSearchTerm
        correctedSearchTerm
      }
    }
    secondProductSearch: productSearch(
      searchTerm: $searchTerm
      department: $secondDepartment
      limit: $limit
      offset: 0
      filters: $secondProductSearchFilters
      experimentValues: $experimentValues
      platform: $platform
    ) @include(if: $searchOnAllParentDepartments) {
      count
    }
    thirdProductSearch: productSearch(
      searchTerm: $searchTerm
      department: $thirdDepartment
      limit: $limit
      offset: 0
      filters: $thirdProductSearchFilters
      experimentValues: $experimentValues
      platform: $platform
    ) @include(if: $searchOnAllParentDepartments) {
      count
    }
  }
  ${PRODUCT_FIELDS_FRAGMENT}
  fragment ProductSearchFields on Product {
    ...ProductFields
    publishDate
  }
`

export type SpellingCorrection = {
  initialSearchTerm: string
  correctedSearchTerm: string
}

export type ProductSearchData = {
  products: Product[]
  count: number
  suggestions: Product[]
  spellingCorrection?: SpellingCorrection
}

export const removeDuplicatedFacets = (
  facets: SearchFiltersFacet[],
): SearchFiltersFacet[] => {
  if (!facets || facets.length === 0) {
    return facets
  }
  return facets.filter(
    (facet, index, initialFacets) =>
      index ===
      initialFacets.findIndex(initialFacet =>
        facetsAreEqual(facet, initialFacet),
      ),
  )
}
