import React, { FC } from 'react'
import { FilterToggle } from '..'
import { InvertedFilterToggle } from '../../../../services/types/services'
import { useSearchStore } from '../../../../store/SearchStore'
import { FilterSource } from '../../../../types'
import { useFindLocaleText } from '../../../../text-localisation'

export const RudeToggle: FC = () => {
  const localiseText = useFindLocaleText()
  const selectedFilters = useSearchStore(store => store.selectedFilters)
  const toggleFilter = useSearchStore(store => store.toggleFilter)

  const rudeFilter: InvertedFilterToggle = {
    __typename: 'FilterInvertedToggle',
    id: 'not-rude',
    label: localiseText('find.rude'),
    isSelected: false,
    parent: 'not rude',
  }

  const isSelected = !!selectedFilters.find(
    selectedFilter => selectedFilter.id === rudeFilter.id,
  )
  const filter = { ...rudeFilter, isSelected }

  const onChange = () => {
    toggleFilter({
      filter,
      select: !isSelected,
      source: FilterSource.Toggle,
    })
  }

  return <FilterToggle filter={filter} onChange={onChange} />
}
