import { Box } from '@moonpig/launchpad-components'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import React, { FC, PropsWithChildren } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { DESKTOP_MODAL_FILTERS_MENU_WIDTH } from '../../../../constants'

const StyledSlidingMenu = styled(Box)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1050;
  animation: slide-in-from-left 400ms;
  overflow: hidden;
  overscroll-behavior: contain;

  ${s({
    bgcolor: 'colorBackground01',
  })}

  ${breakpoint('md')} {
    width: ${DESKTOP_MODAL_FILTERS_MENU_WIDTH};
  }

  @media (prefers-reduced-motion) {
    animation: none;
    transform: translate(0, 0);
  }

  @keyframes slide-in-from-left {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
`

export const SlidingMenu: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <StyledSlidingMenu
    id="filters-menu"
    data-testid="filters-menu-variant"
    role="tablist"
  >
    {children}
  </StyledSlidingMenu>
)
