import React, { FC, useState, useEffect } from 'react'
import { TertiaryButton } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useFindLocaleText } from '../../../text-localisation'
import { CAROUSEL_ITEM_HEIGHT_PX } from '../constants'

const StyledTertiaryButton = styled(TertiaryButton)`
  height: ${CAROUSEL_ITEM_HEIGHT_PX};

  ${s({
    px: 5,
    mr: { xs: 6, lg: 12 },
  })}

  &:not(:disabled):focus {
    outline: 0;
    border: 3px solid;
    box-shadow: none;

    ${s({
      borderColor: 'colorInteractionFocusIndicator',
      px: 5,
    })}
  }
`

const useDelay = (ms: number) => {
  const [elapsed, setElapsed] = useState(false)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setElapsed(true)
    }, ms)
    return () => clearTimeout(delayDebounceFn)
  }, [ms])

  return elapsed
}

type Props = {
  onFiltersClickedCallback: () => void
  displayButton: boolean
}

export const AllFiltersButton: FC<Props> = ({
  onFiltersClickedCallback,
  displayButton,
}) => {
  // Prevents flash when opening and closing filters menu
  const display = useDelay(0) && displayButton
  const localiseText = useFindLocaleText()

  return (
    <StyledTertiaryButton
      style={{
        visibility: display ? 'visible' : 'hidden',
      }}
      onClick={onFiltersClickedCallback}
      aria-label={localiseText('find.all_filters')}
    >
      {localiseText('find.all_filters')}
    </StyledTertiaryButton>
  )
}
