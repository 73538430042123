import React, { createContext, FC, PropsWithChildren } from 'react'
import { SortOrder } from '@moonpig/web-explore-types-graphql'
import {
  ApplyFiltersProps,
  FilterAction,
  FilterSource,
  ApplyFiltersDetails,
  FilterLookupType,
} from '../types'
import {
  FilterItemsTypeEnum,
  FilterServiceFilterFacet,
  FilterServiceFilterItem,
} from '../services/types/services'

type ProductListingPageContext = [
  ProductListingPageState,
  React.Dispatch<ProductListingPageAction>,
]

export type ProductListingPageState = {
  loading: boolean
  applyFilterData: ApplyFiltersProps
  filterLookup: FilterLookupType
  initialOffset: number
  modified: boolean
  filtersLoading: boolean
  excludeRudeProducts: boolean
  sortOrder: SortOrder
}

type ActionPayloadSetLoading = boolean

type ActionPayloadSetFiltersLoading = boolean

type ActionPayloadAddFilters = {
  applyFilterData: {
    facetKeys: string[]
    selectedFacetKey?: string
    details?: ApplyFiltersDetails
  }
}

type ActionPayloadRemoveFilters = {
  applyFilterData: {
    facetKeys: string[]
    selectedFacetKey?: string
    details?: ApplyFiltersDetails
  }
}

type ActionPayloadClearAllFilters = {
  applyFilterData: {
    details?: ApplyFiltersDetails
  }
}

type ActionPayloadReset = {
  applyFilterData?: {
    facetKeys: string[]
    selectedFacetKey: string
    details?: ApplyFiltersDetails
  }
  initialOffset?: number
}

type ActionBeginLoadMore = {
  modified: boolean
}

type ActionLoadMore = number

type ActionToggleRudeFilter = boolean

type ActionSetModified = boolean

type ActionSetFilters = {
  filters: FilterServiceFilterItem[]
}

type ActionToggleSortBy =
  | SortOrder.POPULARITY
  | SortOrder.PRICE_DESCENDING
  | SortOrder.PRICE_ASCENDING
  | SortOrder.NEWNESS

type ActionPayloads =
  | ActionPayloadSetLoading
  | ActionPayloadClearAllFilters
  | ActionPayloadAddFilters
  | ActionPayloadRemoveFilters
  | ActionLoadMore
  | ActionPayloadReset
  | ActionBeginLoadMore
  | ActionToggleRudeFilter
  | ActionToggleSortBy
  | ActionSetModified
  | ActionSetFilters

type ActionType =
  | 'SET_LOADING'
  | 'SET_FILTERS_LOADING'
  | 'SET_FILTERS'
  | 'SET_MODIFIED'
  | 'CLEAR_ALL_FILTERS'
  | 'BEGIN_LOAD_MORE'
  | 'END_LOAD_MORE'
  | 'ADD_FILTERS'
  | 'REMOVE_FILTERS'
  | 'RESET'
  | 'TOGGLE_RUDE_FILTER'
  | 'TOGGLE_SORT_BY'

export type ProductListingPageAction = {
  type: ActionType
  payload: ActionPayloads
}

export const initialState: ProductListingPageState = {
  loading: false,
  applyFilterData: {
    selectedFacet: {} as FilterServiceFilterFacet,
    facets: [] as FilterServiceFilterFacet[],
    details: {} as {
      action: FilterAction
      sender: FilterSource
      senderDetails?: string[]
    },
  },
  filterLookup: {},
  initialOffset: 0,
  modified: false,
  filtersLoading: false,
  excludeRudeProducts: false,
  sortOrder: 'POPULARITY' as SortOrder,
}

const filterFacets = (items: FilterServiceFilterItem[]) =>
  items.filter(
    (item): item is FilterServiceFilterFacet =>
      item.type === FilterItemsTypeEnum.FACET,
  )

const getAllFacets = (items: FilterServiceFilterItem[]) => {
  const facets = filterFacets(items).map(item => {
    return {
      ...item,
      group: item.group === 'producttype' ? item.facetKey : item.group,
    }
  })
  const childFacets: FilterServiceFilterFacet[] = facets.flatMap(item => {
    return item.children
      ? getAllFacets(item.children)
      : ([] as FilterServiceFilterFacet[])
  })
  return [...facets, ...childFacets]
}

export const getFilterLookup = (filters: FilterServiceFilterItem[]) => {
  const facets = getAllFacets(filters)
  const lookup: Record<string, FilterServiceFilterFacet> = {}
  facets.forEach(facet => {
    if (facet.facetKey) {
      lookup[facet.facetKey] = facet
    }
  })
  return lookup
}

export const reducer = (
  state: ProductListingPageState,
  action: ProductListingPageAction,
): ProductListingPageState => {
  const { type, payload } = action

  const lookupFacet = (facetKey: string) => {
    return state.filterLookup[facetKey] || { facetKey, group: 'default' }
  }

  const refreshFacet = (
    facet: FilterServiceFilterFacet,
    lookup: FilterLookupType,
  ) => {
    return lookup[facet.facetKey] || facet
  }

  switch (type) {
    case 'SET_LOADING': {
      return { ...state, loading: payload as ActionPayloadSetLoading }
    }
    case 'SET_FILTERS_LOADING':
      return {
        ...state,
        filtersLoading: payload as ActionPayloadSetFiltersLoading,
      }
    case 'SET_FILTERS': {
      const filterLookup = getFilterLookup(
        (payload as ActionSetFilters).filters,
      )

      return {
        ...state,
        filterLookup,
        applyFilterData: {
          ...state.applyFilterData,
          facets: state.applyFilterData.facets.map(x =>
            refreshFacet(x, filterLookup),
          ),
        },
      }
    }
    case 'SET_MODIFIED':
      return {
        ...state,
        modified: payload as ActionSetModified,
      }
    case 'RESET': {
      const actionPayloadReset = payload as ActionPayloadReset
      const facetKeys = actionPayloadReset.applyFilterData?.facetKeys || []
      const selectedFacetKey =
        actionPayloadReset.applyFilterData?.selectedFacetKey
      return {
        ...state,
        ...actionPayloadReset,
        applyFilterData: {
          ...actionPayloadReset.applyFilterData,
          facets: facetKeys.map(lookupFacet),
          selectedFacet: selectedFacetKey
            ? lookupFacet(selectedFacetKey)
            : undefined,
        },
      }
    }
    case 'CLEAR_ALL_FILTERS': {
      const clearAllFiltersPayload = payload as ActionPayloadClearAllFilters
      return {
        ...state,
        applyFilterData: {
          ...clearAllFiltersPayload.applyFilterData,
          facets: [],
        },
        initialOffset: 0,
        modified: true,
      }
    }
    case 'TOGGLE_RUDE_FILTER':
      return {
        ...state,
        excludeRudeProducts: payload as ActionToggleRudeFilter,
        modified: true,
      }
    case 'TOGGLE_SORT_BY':
      return {
        ...state,
        sortOrder: payload as ActionToggleSortBy,
        modified: true,
      }
    case 'BEGIN_LOAD_MORE':
      return {
        ...state,
        ...(payload as ActionBeginLoadMore),
      }
    case 'END_LOAD_MORE':
      return {
        ...state,
        modified: true,
      }
    case 'ADD_FILTERS': {
      const applyFiltersPayload = payload as ActionPayloadAddFilters
      const { facetKeys, selectedFacetKey, details } =
        applyFiltersPayload.applyFilterData

      const uniqueFacetKeys = facetKeys.filter(k => {
        return !state.applyFilterData.facets.map(x => x.facetKey).includes(k)
      })

      const newState = {
        ...state,
        applyFilterData: {
          details,
          facets: [
            ...state.applyFilterData.facets,
            ...uniqueFacetKeys.map(lookupFacet),
          ],
          selectedFacet: selectedFacetKey
            ? lookupFacet(selectedFacetKey)
            : undefined,
        },
        initialOffset: 0,
        modified: true,
      }

      return newState
    }
    case 'REMOVE_FILTERS': {
      const applyFiltersPayload = payload as ActionPayloadRemoveFilters
      const selectedFacetKey =
        applyFiltersPayload.applyFilterData.selectedFacetKey
      return {
        ...state,
        applyFilterData: {
          ...applyFiltersPayload.applyFilterData,
          facets: state.applyFilterData.facets.filter(
            applied =>
              !applyFiltersPayload.applyFilterData.facetKeys.some(facetKey => {
                return applied.facetKey === facetKey
              }),
          ),
          selectedFacet: selectedFacetKey
            ? lookupFacet(selectedFacetKey)
            : undefined,
        },
        initialOffset: 0,
        modified: true,
      }
    }
    /* istanbul ignore next */
    default:
      return state
  }
}

export const productListingPageContext =
  createContext<ProductListingPageContext>([
    initialState,
    /* istanbul ignore next */ () => {},
  ])

export const ProductListingPageProvider: FC<
  PropsWithChildren<{
    productListingContext: ProductListingPageContext
  }>
> = ({ productListingContext, children }) => (
  <productListingPageContext.Provider value={productListingContext}>
    {children}
  </productListingPageContext.Provider>
)

export const useProductListingPageContext = () =>
  React.useContext(productListingPageContext)
