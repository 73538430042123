import { styled, breakpointDown } from '@moonpig/launchpad-utils'
import React, { FC, PropsWithChildren, useState } from 'react'
import { LAYOUT_BREAKPOINT } from './constants'

const StyledSlidingMenu = styled.div.attrs(
  ({
    rightPanelActive,
    leftPanelActive,
  }: {
    rightPanelActive: boolean
    leftPanelActive: boolean
  }) => ({
    className: `${leftPanelActive && 'left-panel-active'} ${
      rightPanelActive && 'right-panel-active'
    }`,
  }),
)<{
  rightPanelActive: boolean
  leftPanelActive: boolean
}>`
  height: 100%;
  width: 100%;

  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    height: 100vh;
    animation: start 0ms forwards;

    &.left-panel-active {
      animation: slide-in 500ms forwards;
    }

    &.right-panel-active {
      animation: slide-out 500ms forwards;
    }
  }

  @keyframes start {
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slide-in {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`

type SlidingMenuProps = {
  enableHorizontalAnimation: boolean
  leftPanelActive: boolean
  rightPanelActive: boolean
  onLeftPanelActivating?: () => void
  onRightPanelActivating?: () => void
}

export const SlidingMenu: FC<PropsWithChildren<SlidingMenuProps>> = ({
  children,
  enableHorizontalAnimation,
  leftPanelActive,
  rightPanelActive,
  onLeftPanelActivating,
  onRightPanelActivating,
}) => {
  const [leftPanelActivated, setLeftPanelActivated] = useState(leftPanelActive)
  const [rightPanelActivated, setRightPanelActivated] =
    useState(rightPanelActive)
  if (leftPanelActivated !== leftPanelActive) {
    setLeftPanelActivated(leftPanelActive)
    if (leftPanelActive && onLeftPanelActivating) {
      onLeftPanelActivating()
    }
  }

  if (rightPanelActivated !== rightPanelActive) {
    setRightPanelActivated(rightPanelActive)
    if (rightPanelActive && onRightPanelActivating) {
      onRightPanelActivating()
    }
  }

  return (
    <StyledSlidingMenu
      data-testid="web-find-sliding-menu"
      leftPanelActive={enableHorizontalAnimation && leftPanelActive}
      rightPanelActive={enableHorizontalAnimation && rightPanelActive}
    >
      {children}
    </StyledSlidingMenu>
  )
}
