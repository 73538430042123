import React, { FC } from 'react'
import { TertiaryButton } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { spacing } from '@moonpig/launchpad-theme'
import { useFindLocaleText } from '../../../../text-localisation'
import { useSearchStore } from '../../../../store/SearchStore'
import { FilterCategory } from '../../../../services/types/services'

const StyledButton = styled(TertiaryButton)`
  ${s({
    typography: 'typeButtonLabel',
    color: 'colorInteractionTextLink',
    py: 5,
    px: 2,
    textAlign: 'right',
  })}
`
const StyledWrapper = styled.div`
  min-width: ${spacing(12)}px;
`

type Props = {
  level: number
  category?: FilterCategory
  type?: 'filters' | 'sort'
  hide: boolean
}

export const ClearButton: FC<Props> = ({
  level,
  category,
  type = 'filters',
  hide,
}) => {
  const localiseText = useFindLocaleText()
  const clearFilters = useSearchStore(store => store.clearFilters)

  const onClear = () => {
    if (level === 0) {
      clearFilters()
    } else {
      clearFilters(category, type)
    }
  }

  return (
    <StyledWrapper>
      {!hide && (
        <StyledButton
          aria-label={localiseText('find.clear_selection')}
          onClick={() => onClear()}
          as="button"
        >
          {localiseText('find.clear')}
        </StyledButton>
      )}
    </StyledWrapper>
  )
}
