import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { getParentDepartment } from '@moonpig/web-core-utils'
import {
  trackUIEvent,
  createProductViewedEvent,
  useTrackGAEventOnce,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import { Region } from '@moonpig/web-core-types'
import { Box, Flex } from '@moonpig/launchpad-components'
import {
  ProductClickedEvent,
  AddedToBasketEvent,
  Status,
  PromoTileData,
} from '@moonpig/web-shared-products'
import { DepartmentsEnum, SortOrder } from '@moonpig/web-explore-types-graphql'
import { Dropdown } from '@moonpig/launchpad-forms'
import { breakpointUp, styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { spacing } from '@moonpig/launchpad-theme'
import { getDeviceType } from '@moonpig/web-shared-utils'
import { useFlag } from '@moonpig/web-explore-flags'
import { LoadMoreType } from '../types'
import {
  loadMoreGAEventDetails,
  generateSelectSortOrderGAEvent,
} from '../../analytics/GAEvents'
import { ProductGridWithPaging, GalleryProduct } from '../ProductGridWithPaging'
import { useProductListingPageContext } from '../../utils/productListingPageContext'
import { useFindLocaleText } from '../../text-localisation'
import { SearchFiltersFacet } from '../../pages/GalleryPage/types'
import { isGroupCardsSearchContext } from '../../utils/isGroupCardsSearchContext'
import {
  getSortByOptions,
  useSearchContext,
  useSetUIHCookie,
} from '../../utils'
import { useSearchStore } from '../../store/SearchStore'
import { RudeToggle, SortButton } from '../FiltersMenuVariant'

const StyledBox = styled(Box)`
  position: relative;
`
const StyledSortBy = styled.div`
  ${breakpointUp('md')} {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 20px;
      ${s({
        color: 'colorInteractionIcon',
      })}
    }
  }
  display: none;
`

const StyledDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: ${spacing(10)}px;
  margin-bottom: ${spacing(4)}px;
`

type ProductGridWithTrackingProps = {
  totalCount: number
  pageTitle: string
  metaTitle: string
  details: {
    region: Region
    url: string
    searchTerm: string
    department: DepartmentsEnum[]
  }
  products: GalleryProduct[]
  loadMoreProducts?: (loadMoreType: LoadMoreType) => Promise<boolean>
  presetFacets: SearchFiltersFacet[]
  experiments?: { [key: string]: string } | undefined
  isInternalUser?: boolean
  onAddedToBasket: (e: AddedToBasketEvent) => void
  pageType: string
  customerId?: string
  isZeroSearchResults?: boolean
  groupCardProject?: string
  promoTile?: PromoTileData
}

export const ProductGridWithTrackingComponent: FC<
  ProductGridWithTrackingProps
> = ({
  totalCount,
  metaTitle,
  details,
  products,
  loadMoreProducts,
  presetFacets,
  experiments,
  onAddedToBasket,
  isInternalUser = false,
  pageType,
  customerId,
  isZeroSearchResults = false,
  groupCardProject,
  promoTile,
}) => {
  const { trackGAEventOnce } = useTrackGAEventOnce()
  const [{ applyFilterData, loading }] = useProductListingPageContext()
  const { selectedSuggestion } = useSearchContext()
  const { region, searchTerm, department } = details
  const localiseText = useFindLocaleText()
  const { setUIHCookie } = useSetUIHCookie()
  const filtersMenuRebuild = useFlag('search-filters-menu-rebuild')
  const toggleFiltersMenu = useSearchStore(store => store.toggleFiltersMenu)
  const sortValue = useSearchStore(store => store.sortValue)
  const sortBy = useSearchStore(store => store.sortBy)
  const loadingFilters = useSearchStore(store => store.filtersLoading)

  const isGroupCardTileToggleEnabled = useFlag('search-show-group-card-tile')

  const onProductClicked = useCallback(
    async ({ product }: ProductClickedEvent) => {
      setUIHCookie({ product })
    },
    [setUIHCookie],
  )

  const onProductFirstClick = useCallback(
    async ({ product }: ProductClickedEvent) => {
      const { mnpg_ui_events_api_correlation_id: correlationId } =
        getBrowserCookies()
      if (process.env.API_URL) {
        try {
          await trackUIEvent(
            createProductViewedEvent({
              productId: product.id,
              searchTerm,
              department,
              correlationId,
              filters: [
                ...applyFilterData.facets.map(x => ({
                  facetKey: x.facetKey,
                })),
                ...presetFacets.map(x => ({
                  facetKey: x.facetKey,
                })),
              ],
              store: region.toUpperCase(),
              ...(experiments && { metaData: { experiments } }),
              source: getDeviceType(),
              customerId,
            }),
          )
        } catch (error) {
          // @eslint-disable-next-line @typescript-eslint/no-empty
        }
      }
    },
    [
      searchTerm,
      department,
      applyFilterData.facets,
      presetFacets,
      region,
      experiments,
      customerId,
    ],
  )

  const onGroupCardsCTAClick = () => {
    trackGAEventOnce({
      event: 'select_content',
      content_data: {
        content_type:
          'search | select groups cards promotion | tile | find out more',
      },
      // For GA3 backward compatibility
      event_data: {
        category: 'search',
        action: 'select groups cards promotion',
        label: 'tile | find out more',
        non_interaction: true,
        value: undefined,
      },
      // Web only //
      discount_data: undefined,
      ecommerce: undefined,
      error_data: undefined,
    })
  }

  const onGroupCardsTileShow = () => {
    trackGAEventOnce({
      event: 'impression',
      content_data: {
        content_type:
          'search | view groups cards promotion | tile | find out more',
      },
      event_data: {
        category: 'search',
        action: 'view groups cards promotion',
        label: 'tile | find out more',
        non_interaction: true,
        value: undefined,
      },
      discount_data: undefined,
      ecommerce: undefined,
      error_data: undefined,
    })
  }

  const onAddedToBasketWithTracking = React.useCallback(
    (e: AddedToBasketEvent) => {
      onAddedToBasket({
        ...e,
        componentName: 'product grid',
      })
    },
    [onAddedToBasket],
  )

  const onLoadMoreCallback = React.useCallback(
    async (type: LoadMoreType) => {
      if (type === LoadMoreType.CLICK) {
        trackGAEvent(loadMoreGAEventDetails(products.length))
      }
      return (
        (loadMoreProducts && loadMoreProducts(type)) ||
        /* istanbul ignore next */ true
      )
    },
    [loadMoreProducts, products.length],
  )

  const [, dispatch] = useProductListingPageContext()

  const [selected, setSelected] = useState('Popularity')

  type ActionToggleSortBy =
    | SortOrder.POPULARITY
    | SortOrder.PRICE_DESCENDING
    | SortOrder.PRICE_ASCENDING
    | SortOrder.NEWNESS

  const parentDepartment = getParentDepartment(department[0])

  const applySortBy = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (filtersMenuRebuild) {
      sortBy(event.target.value as SortOrder)
    } else {
      dispatch({
        type: 'TOGGLE_SORT_BY',
        payload: event.target.value as ActionToggleSortBy,
      })
      setSelected(event.target.value)
      trackGAEvent(generateSelectSortOrderGAEvent(event.target.value))
    }
  }

  const isCards = parentDepartment === 'ALL_CARDS'

  const sortByOptions = getSortByOptions({
    isCards,
    localiseText,
  })

  const showGroupCardTile = useMemo(() => {
    return (
      isGroupCardTileToggleEnabled &&
      isCards &&
      isGroupCardsSearchContext(
        searchTerm,
        presetFacets,
        applyFilterData.facets,
        selectedSuggestion,
      )
    )
  }, [
    applyFilterData.facets,
    isCards,
    isGroupCardTileToggleEnabled,
    presetFacets,
    searchTerm,
    selectedSuggestion,
  ])

  return (
    <StyledBox>
      <Status
        loading={{
          status: loading || loadingFilters,
          fixed: false,
          zIndex: 999,
        }}
      />

      {filtersMenuRebuild ? (
        <Flex
          justifyContent="space-between"
          pl={{ xs: 6, md: 7, lg: 10, xxl: 12 }}
          pr={{ xs: 3, md: 7, xxl: 9 }}
          flexDirection={isCards ? 'row' : 'row-reverse'}
          mb={4}
        >
          {!isZeroSearchResults && (
            <SortButton
              onClick={() => toggleFiltersMenu('sort')}
              sortByLabel={localiseText('find.sort_by')}
              selectedOptionLabel={
                sortByOptions.find(sortOption => sortOption.value === sortValue)
                  ?.label || /* istanbul ignore next */ ''
              }
            />
          )}
          {isCards && <RudeToggle />}
        </Flex>
      ) : (
        <>
          {!isZeroSearchResults && (
            <StyledSortBy>
              <StyledDropdown
                data-testid="web-find-productgrid-sortby"
                label={`${localiseText('find.sort_by')}:`}
                name="sort by"
                value={selected}
                options={sortByOptions}
                onChange={e => applySortBy(e)}
              />
            </StyledSortBy>
          )}
        </>
      )}

      <ProductGridWithPaging
        totalCount={totalCount}
        region={region}
        products={products}
        loadMoreProducts={onLoadMoreCallback}
        onProductClicked={onProductClicked}
        onProductFirstClick={onProductFirstClick}
        onAddToBasket={onAddedToBasketWithTracking}
        isInternalUser={isInternalUser}
        metaTitle={metaTitle}
        pageType={pageType}
        groupCardProject={groupCardProject}
        showGroupCardTile={showGroupCardTile}
        promoTile={promoTile}
        onGroupCardsTileShow={onGroupCardsTileShow}
        onGroupCardsCTAClick={onGroupCardsCTAClick}
      />
    </StyledBox>
  )
}

export const ProductGridWithTracking = memo(ProductGridWithTrackingComponent)
