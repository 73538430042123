import React, { FC, ReactNode } from 'react'
import {
  Flex,
  Heading,
  Image,
  ImageProps,
  Pill,
  PrimaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { PillVariant } from '../../types'

type FeatureTileProps = {
  title: string
  description?: string
  image: {
    src: ImageProps['src']
    alt: string
    loading?: 'lazy' | 'eager'
  }
  pill?: {
    displayLabel: string
    displayVariant: PillVariant
  }
  cta: () => void
  ctaLabel: string
  backgroundColor?: string
  listItems?: ReactNode[]
}

const ImageWrapper = styled(Flex)`
  ${s({
    bgcolor: '#f1cfc6',
    justifyContent: 'center',
    alignItems: 'center',
  })}
  overflow: hidden;
`

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  max-height: 282px;
  object-fit: cover;
`
const StyledUl = styled.ul`
  list-style-type: disc;
  ${s({
    marginBottom: 6,
    marginLeft: 6,
    color: 'colorTextAlternate',
  })};
`
export const FeatureTile: FC<FeatureTileProps> = ({
  title,
  description,
  image,
  cta,
  ctaLabel,
  pill,
  backgroundColor,
  listItems,
}) => (
  <Flex
    flexDirection={{ xs: 'column', lg: 'row-reverse' }}
    justifyContent={{ lg: 'space-between' }}
    borderRadius={2}
    boxShadow={3}
    width={'100%'}
    height={'100%'}
    data-testid="shared-feature-tile"
    overflow="hidden"
  >
    <ImageWrapper className="shared-feature-tile-image-wrapper">
      <StyledImage {...image} />
    </ImageWrapper>
    <Flex
      flexDirection={'column'}
      p={{ xs: 6, md: 8 }}
      justifyContent={'space-between'}
      bgcolor={backgroundColor || 'transparent'}
      flexGrow={1}
      data-testid="shared-feature-tile-content"
    >
      <div>
        {pill && (
          <Pill
            label={pill.displayLabel}
            variant={pill.displayVariant}
            mb={6}
            mr={'auto'}
          />
        )}
        <Heading
          level="h2"
          mb={6}
          typography={{ xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay04' }}
          whiteSpace={{ xs: 'wrap', md: 'nowrap' }}
        >
          {title}
        </Heading>
        {description && <Text mb={6}>{description}</Text>}

        {listItems && (
          <StyledUl>
            {listItems.map(x => {
              return x
            })}
          </StyledUl>
        )}
      </div>
      <PrimaryButton onClick={() => cta()} maxWidth={'235px'}>
        {ctaLabel}
      </PrimaryButton>
    </Flex>
  </Flex>
)
