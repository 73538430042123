import React, { FC, memo, PropsWithChildren, useState } from 'react'
import { Box, PrimaryButton, Flex } from '@moonpig/launchpad-components'
import { styled, breakpointDown, breakpointUp } from '@moonpig/launchpad-utils'
import { useRouter } from '@moonpig/web-core-routing'
import { system as s } from '@moonpig/launchpad-system'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { FilterAction, FilterSource } from '../../types'
import {
  updateRoute,
  useSearchContext,
  facetsAreEqual,
  shallowCompare,
  getFilterFacet,
  getFilterHeader,
} from '../../utils'
import { useProductListingPageContext } from '../../utils/productListingPageContext'
import { FilterCategories } from './FilterCategories'
import { LAYOUT_BREAKPOINT } from './constants'
import { headersAreEqual } from '../../utils/GalleryFiltering/facetsAreEqual'
import { AllSelectedChangedEvent, FilterChangedEvent } from './types'
import { useFindLocaleText } from '../../text-localisation'
import { FilterServiceFilterItem } from '../../services/types/services'
import { recursivelyRemovePresetFacets } from '../../utils/GalleryFiltering/filteringHelpers'
import { FiltersPageType } from '../types'

const StyledFlex = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    width: 200%;
    overflow: auto;
  }
`
const StyledButtonWrapper = styled(Box)`
  width: 100%;
  position: fixed;
  display: block;
  bottom: 0;
  z-index: 1;
  background-color: white;
  ${s({
    borderTop: '1px solid',
    borderColor: 'colorBorder03',
  })}

  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    display: auto;
    position: relative;
  }
`

const StyledResultsButton = styled(PrimaryButton)`
  width: 100%;
  ${s({
    px: 12,
    m: 6,
  })}
`

const StyledViewResultsFlex = styled(Flex)`
  ${s({
    justifyContent: 'center',
    alignItems: 'center',
  })}
`

type HierarchicalFiltersProps = {
  facets: FilterServiceFilterItem[] | null
  isOpen: boolean
  resultsCount: number
  filtersLoading?: boolean
  close: () => void
  pageType: FiltersPageType
}

const HierarchicalFiltersComponent: FC<HierarchicalFiltersProps> = ({
  isOpen,
  facets,
  resultsCount,
  close,
  pageType,
}) => {
  const router = useRouter()
  const { departments, presetFacets, selectedSuggestion } = useSearchContext()
  const [state, dispatch] = useProductListingPageContext()

  const [openFilterCategory, setOpenFilterCategory] = useState<
    string | undefined
  >()

  const [openOption, setOpenOption] = useState<string | undefined>()

  const filteredFacets = React.useMemo(() => {
    return recursivelyRemovePresetFacets({
      data: facets || /* istanbul ignore next */ [],
      presetFacets,
      suggestionFacetKey: selectedSuggestion?.facetKey,
      department: getParentDepartment(departments[0]),
    })
  }, [departments, presetFacets, facets, selectedSuggestion])

  const filters = filteredFacets

  const [enableHorizontalAnimation, setEnableHorizontalAnimation] =
    useState<boolean>(false)

  const onFilterCategoryOpened = React.useCallback(
    (category: string | undefined) => {
      setOpenFilterCategory(category)
      setEnableHorizontalAnimation(true)
    },
    [],
  )

  React.useEffect(() => {
    if (!isOpen) {
      setEnableHorizontalAnimation(false)
      setOpenFilterCategory(undefined)
      setOpenOption(undefined)
    }
  }, [isOpen])

  const onClearAll = React.useCallback(() => {
    updateRoute([], [], router)
    dispatch({
      type: 'CLEAR_ALL_FILTERS',
      payload: {
        applyFilterData: {
          details: {
            sender: FilterSource.Gallery,
            action: FilterAction.ClearAll,
          },
        },
      },
    })
  }, [router, dispatch])

  const applyUpdate = React.useCallback(
    (removed: boolean, selectedFacetKeys: string[], facetKey: string) => {
      const applyFilterData = {
        facetKeys: selectedFacetKeys,
        selectedFacetKey: facetKey,
        details: {
          sender: FilterSource.Gallery,
          action: removed ? FilterAction.Remove : FilterAction.Add,
        },
      }

      dispatch({
        type: removed ? 'REMOVE_FILTERS' : 'ADD_FILTERS',
        payload: {
          applyFilterData,
        },
      })

      updateRoute(
        state.applyFilterData.facets,
        selectedFacetKeys.map(
          key => state.filterLookup[key] || /* istanbul ignore next */ {},
        ),
        router,
        removed ? 'REMOVE' : 'ADD',
      )
    },
    [dispatch, router, state.applyFilterData.facets, state.filterLookup],
  )

  const filterChanged = React.useCallback<FilterChangedEvent>(
    (removed, facetKey) => {
      applyUpdate(removed, [facetKey], facetKey)
    },
    [applyUpdate],
  )

  const allSelectedChanged = React.useCallback<AllSelectedChangedEvent>(
    (removed, newFacetKeys, facetKey) => {
      applyUpdate(removed, newFacetKeys, facetKey)
    },
    [applyUpdate],
  )

  const allowClear = !!state.applyFilterData.facets.length

  const localiseText = useFindLocaleText()

  return (
    <StyledFlex>
      <FilterCategories
        enableHorizontalAnimation={enableHorizontalAnimation}
        filters={filters}
        allowClear={allowClear}
        selectedFacets={state.applyFilterData.facets}
        filterChanged={filterChanged}
        close={close}
        onClearAll={onClearAll}
        openFilterCategory={openFilterCategory}
        setOpenFilterCategory={onFilterCategoryOpened}
        openOption={openOption}
        setOpenOption={setOpenOption}
        allSelectedChanged={allSelectedChanged}
        pageType={pageType}
      />

      <StyledButtonWrapper>
        <StyledViewResultsFlex>
          <StyledResultsButton disabled={state.loading} onClick={close}>
            {state.loading
              ? localiseText('find.view_results')
              : localiseText('find.view_n_results', { resultsCount })}
          </StyledResultsButton>
        </StyledViewResultsFlex>
      </StyledButtonWrapper>
    </StyledFlex>
  )
}

/* istanbul ignore next */
const propsAreEqual = (
  prevProps: Readonly<PropsWithChildren<HierarchicalFiltersProps>>,
  nextProps: Readonly<PropsWithChildren<HierarchicalFiltersProps>>,
): boolean => {
  const { facets: firstFacets, ...firstProps } = prevProps
  const { facets: secondFacets, ...secondProps } = nextProps

  return (
    shallowCompare(firstProps, secondProps) &&
    (firstFacets === secondFacets ||
      (firstFacets?.length === secondFacets?.length &&
        (firstFacets || [])
          .map(
            (x, i) =>
              facetsAreEqual(
                getFilterFacet(x),
                getFilterFacet((secondFacets || [])[i]),
              ) &&
              headersAreEqual(
                getFilterHeader(x),
                getFilterHeader((secondFacets || [])[i]),
              ),
          )
          .every(x => x)))
  )
}

export const HierarchicalFilters = memo(
  HierarchicalFiltersComponent,
  propsAreEqual,
)
