import { useCallback } from 'react'
import { setUIHCookie as setUIHCookieFunction } from '@moonpig/web-shared-utils'
import { useProductListingPageContext } from './productListingPageContext'
import { useSearchContext } from './searchContext'

export const useSetUIHCookie = () => {
  const { presetFacets } = useSearchContext()
  const [{ applyFilterData }] = useProductListingPageContext()

  const setUIHCookie = useCallback(
    ({
      product,
      facets,
    }: {
      product: {
        id: string
        category: { id: string | number }
        isSponsored?: boolean
        internallyPromoted?: boolean
      }
      facets?: {
        group: string
        facetKey: string
      }[]
    }) =>
      setUIHCookieFunction({
        product,
        facets: facets || [...applyFilterData.facets, ...(presetFacets || [])],
      }),
    [applyFilterData.facets, presetFacets],
  )

  return { setUIHCookie }
}
