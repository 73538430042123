import { useRouter } from '@moonpig/web-core-routing'
import { getSearchFilters } from '@moonpig/web-core-utils'
import { useEffect } from 'react'
import { useProductListingPageContext } from './productListingPageContext'
import { useSearchContext } from './searchContext'

export const useResetProductListing = (offset: number) => {
  const { onRouteChangeStart, getCurrentRoute } = useRouter()
  const { params } = getCurrentRoute<'search' | 'content'>()
  const { searchTerm, presetFacets } = useSearchContext()
  const [
    {
      applyFilterData: { facets },
      initialOffset: currentInitialOffset,
    },
    dispatch,
  ] = useProductListingPageContext()

  useEffect(() => {
    const filters = getSearchFilters(params)
    if (!areFacetsEqual(facets, filters)) {
      dispatch({
        type: 'RESET',
        payload: {
          applyFilterData: {
            facetKeys: filters.map(f => f.facetKey),
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchTerm, presetFacets, params.filters, params.f])

  useEffect(
    () =>
      onRouteChangeStart(() => {
        if (offset !== currentInitialOffset) {
          dispatch({
            type: 'RESET',
            payload: {
              initialOffset: offset,
            },
          })
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, onRouteChangeStart],
  )
}

const areFacetsEqual = (
  first?: { facetKey?: string | null }[],
  second?: { facetKey?: string | null }[],
) => {
  return (
    !!first === !!second &&
    first?.length === second?.length &&
    first?.every(f => second?.some(s => f.facetKey === s.facetKey))
  )
}
