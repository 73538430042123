import { Dispatch, useState } from 'react'
import { DepartmentsEnum } from '@moonpig/web-explore-types-graphql'
import { SearchService } from '../../services'
import {
  FilterInput,
  FilterServiceFilterItem,
} from '../../services/types/services'
import { SelectedSuggestion } from '../../components/types'
import {
  ProductListingPageAction,
  useProductListingPageContext,
} from '../productListingPageContext'
import { collateFacetInputs } from '../collateFacetInputs'
import { useWatchFilterParameters } from './useWatchFilterParameters'

export type UseFilterServicesProps = {
  services: SearchService
  parameters: {
    appliedFacets: FilterInput[]
    departments: DepartmentsEnum[]
    excludeRude: boolean
    preappliedFacets: FilterInput[]
    promotionId?: string
    searchTerm: string
    selectedSuggestion?: Omit<SelectedSuggestion, 'label'>
    dispatch: Dispatch<ProductListingPageAction>
  }
}

export type UseFiltersResult = {
  filterFacets: FilterServiceFilterItem[]
}

export const useFilters: (
  props: UseFilterServicesProps,
) => UseFiltersResult = ({
  services,
  parameters: {
    appliedFacets,
    departments,
    excludeRude,
    preappliedFacets,
    promotionId,
    searchTerm,
    selectedSuggestion,
    dispatch,
  },
}) => {
  const [filterFacets, setFacets] = useState<FilterServiceFilterItem[]>([])
  const [state] = useProductListingPageContext()

  const unlabelledFacets = collateFacetInputs(
    appliedFacets,
    preappliedFacets,
    selectedSuggestion,
  )

  const loadFilters = async () => {
    const { filtersLoading } = state

    /* istanbul ignore else */
    if (!filtersLoading) {
      dispatch({ type: 'SET_FILTERS_LOADING', payload: true })
      const filtersResult = await services.loadFilters({
        departments,
        excludeRude,
        promotionId,
        searchTerm,
        facets: unlabelledFacets,
      })
      dispatch({ type: 'SET_FILTERS_LOADING', payload: false })
      setFacets(filtersResult.facets)
      dispatch({
        type: 'SET_FILTERS',
        payload: { filters: filtersResult.facets },
      })
    }
  }

  const loadFiltersCallback = async () => {
    await loadFilters()
  }

  useWatchFilterParameters({
    departments,
    excludeRude,
    facets: unlabelledFacets,
    promotionId,
    searchTerm,
    loadFiltersCallback,
  })

  return {
    filterFacets,
  }
}
