import React, { FC } from 'react'
import { useFlag } from '@moonpig/web-explore-flags'
import { FilterServiceFilterFacet } from '../../services/types/services'
import { FiltersCarouselStickyWrapper } from '../FiltersCarousel/FiltersCarouselStickyWrapper'
import { FiltersCarousel } from '../FiltersCarousel/FiltersCarousel'
import { FiltersCarousel as FiltersCarouselVariant } from '../FiltersCarouselVariant'
import { useSearchStore } from '../../store/SearchStore'
import { useNbaFiltersVariant } from '../../utils/nbaFilters'

type Props = {
  filters: FilterServiceFilterFacet[]
  toggleFiltersMenu: () => void
  filtersOpen: boolean
  title: string
}

export const FiltersCarouselSwitcher: FC<Props> = ({
  filters,
  toggleFiltersMenu,
  title,
  filtersOpen,
}) => {
  const filtersMenuRebuild = useFlag('search-filters-menu-rebuild')
  const filtersfromStore = useSearchStore(store => store.filters)
  const nbaFilters = useNbaFiltersVariant(filtersfromStore)

  return filtersMenuRebuild ? (
    <FiltersCarouselVariant filters={nbaFilters} />
  ) : (
    <FiltersCarouselStickyWrapper>
      <FiltersCarousel
        availableNextBestActions={filters}
        title={title}
        modalOpen={filtersOpen}
        onFiltersClicked={toggleFiltersMenu}
      />
    </FiltersCarouselStickyWrapper>
  )
}
