import React, { FC, PropsWithChildren, useEffect, useRef } from 'react'
import { Flex, IconButton, Text } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { IconSystemChevronLeft } from '@moonpig/launchpad-assets'
import { ClearButton } from '../ClearButton'
import { useFindLocaleText } from '../../../../text-localisation'
import { SlidingPanel } from '../SlidingMenu'
import { FilterCategory } from '../../../../services/types/services'

const StyledText = styled(Text)`
  ${s({
    textAlign: 'left',
    typography: { xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' },
  })}
`

const StyledHeader = styled(Flex)`
  position: sticky;
  z-index: 1051;
  top: 0;
  left: 0;
  ${s({
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: '64px',
    py: 3,
    pl: 3,
    pr: 6,
    borderBottom: '1px solid',
    borderColor: 'colorBorder03',
    bgcolor: 'colorBackground01',
  })}
`

type Props = {
  category: FilterCategory
  level: number
  onClose: () => void
  hideClearButton: boolean
}

export const FilterCategorySubMenu: FC<PropsWithChildren<Props>> = ({
  category,
  onClose,
  children,
  level,
  hideClearButton,
}) => {
  const localiseText = useFindLocaleText()
  const [isOpen, setIsOpen] = React.useState(true)
  const categoryBackButton = useRef<HTMLButtonElement>(null)

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation()
    setIsOpen(false)
  }

  useEffect(() => {
    setIsOpen(true)
  }, [category])

  return (
    <SlidingPanel
      level={level}
      isOpen={isOpen}
      onAnimationEnd={() => isOpen && categoryBackButton.current?.focus()}
      onClose={onClose}
      id={`${category.id}-filter-category-panel`}
    >
      <StyledHeader data-testid="filter-category-header">
        <IconButton
          ref={categoryBackButton}
          icon={IconSystemChevronLeft}
          onClick={handleBackButtonClick}
          label={localiseText('find.filters_menu_panel.close', category.label)}
        />
        <StyledText>{category.label}</StyledText>
        <ClearButton level={level} category={category} hide={hideClearButton} />
      </StyledHeader>
      {children}
    </SlidingPanel>
  )
}
