import { Box } from '@moonpig/launchpad-components'
import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { styled, breakpointUp, breakpointDown } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useIsMobile } from '../../utils'

const HEADER_HEIGHT_PX = '134px'

const StyledFiltersCarouselBox = styled(Box)<{
  isSticky: boolean
}>`
  ${breakpointUp('xl')} {
    top: ${HEADER_HEIGHT_PX};
  }
  ${breakpointDown('xl')} {
    top: 0px;
  }
  ${breakpointDown('md')} {
    top: -1px;
  }
  ${s({
    boxShadow: '0px 4px 4px -4px rgba(0, 32, 77, 0.15)',
    bgcolor: 'colorBackground01',
  })}
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'static')};
  z-index: 500;

  @keyframes box-fade-in {
    from {
      opacity: 0.3;
    }
    to {
      opacity: 1;
    }
  }
`

export const FiltersCarouselStickyWrapper: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const isMobile = useIsMobile()
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    let prevScrollDepth = window.scrollY
    let thresholdLimitUp = window.scrollY
    let thresholdLimitDown = window.scrollY

    const scrollEvent = () => {
      const currentScrollDepth = window.scrollY
      if (
        prevScrollDepth > currentScrollDepth ||
        (currentScrollDepth > 300 && isMobile)
      ) {
        thresholdLimitDown = currentScrollDepth

        if (
          thresholdLimitUp - 70 > currentScrollDepth &&
          (currentScrollDepth > 300 || isMobile)
        ) {
          setIsSticky(true)
          thresholdLimitUp = currentScrollDepth
        }
      }

      if (
        prevScrollDepth < currentScrollDepth ||
        isSticky ||
        (currentScrollDepth < 300 && !isMobile)
      ) {
        thresholdLimitUp = currentScrollDepth

        /* istanbul ignore next */
        if (
          thresholdLimitDown < currentScrollDepth ||
          (currentScrollDepth < 300 && !isMobile)
        ) {
          thresholdLimitDown = currentScrollDepth
          setIsSticky(false)
        }
      }

      prevScrollDepth = currentScrollDepth
    }

    window.addEventListener('scroll', scrollEvent)

    return () => window.removeEventListener('scroll', scrollEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledFiltersCarouselBox
      data-testid={'sticky-filters-carousel'}
      isSticky={isSticky}
    >
      {children}
    </StyledFiltersCarouselBox>
  )
}
