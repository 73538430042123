import { FilterItem } from '../../../services/types/services'

export const getAllSelectedFilters = (filters: FilterItem[]): string[] =>
  filters.reduce((acc, filter) => {
    if (filter.__typename === 'Filter' && filter.isSelected) {
      acc.push(filter.label)
    } else if (filter.__typename === 'FilterCategory' && filter.children) {
      acc.push(...getAllSelectedFilters(filter.children))
    }
    return acc
  }, [] as string[])
