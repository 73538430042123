import {
  FilterServiceFilterItem,
  FilterServiceFilterFacet,
  FilterServiceFilterHeader,
  FilterItem,
  FilterCategory,
  ApplicableFilter,
} from '../../services/types/services'

export const getFilterFacet = (
  item: FilterServiceFilterItem,
): FilterServiceFilterFacet | undefined => {
  if ('facetKey' in item) {
    return item
  }
  return undefined
}

export const getFilterHeader = (
  item: FilterServiceFilterItem,
): FilterServiceFilterHeader | undefined => {
  if ('facetKey' in item) {
    return undefined
  }
  return item
}

export const reduceFilterFacets = (
  items: FilterServiceFilterItem[],
): FilterServiceFilterFacet[] => {
  return items.reduce<FilterServiceFilterFacet[]>((accumulator, current) => {
    return 'facetKey' in current ? [...accumulator, current] : accumulator
  }, [])
}

export const reduceFilters = (
  items: FilterItem[],
): (ApplicableFilter | FilterCategory)[] => {
  return items.reduce<(ApplicableFilter | FilterCategory)[]>(
    (accumulator, current) => {
      return 'id' in current ? [...accumulator, current] : accumulator
    },
    [],
  )
}
