import { IconSystemFilter } from '@moonpig/launchpad-assets'
import { Box, Flex, PrimaryButton, Text } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import React from 'react'
import { useFindLocaleText } from '../../text-localisation'

const StyledButton = styled(PrimaryButton)`
  ${s({
    bgcolor: 'colorBackground01',
    border: '3px solid',
    paddingLeft: 5,
    borderColor: 'colorBorder05',
    color: 'colorInteractionIcon',
    boxShadow: 0,
    height: '46px',
    borderRadius: 6,
  })}
  &:not(:disabled) {
    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
      ${s({
        boxShadow: 0,
        border: '3px solid',
        color: 'colorInteractionButtonHover',
        bgcolor: 'colorBackground06',
        borderColor: 'colorInteractionButtonHover',
      })}
    }
  }
`
const StyledFilterCount = styled.div`
  min-width: 22px;
  min-height: 22px;
  max-width: 22px;
  max-height: 22px;
  border-radius: 50%;
  ${s({
    bgcolor: 'colorInteractionButton',
    color: 'colorTextInverted',
    typography: 'typeButtonLabel',
  })}
`

type FiltersButtonStickyProps = {
  appliedFiltersCount: number
  onClick: () => void
}

export const FiltersButtonSticky = React.forwardRef<
  HTMLAnchorElement,
  FiltersButtonStickyProps
>(({ onClick, appliedFiltersCount }, ref) => {
  const localiseText = useFindLocaleText()
  return (
    <Flex paddingLeft={{ xs: 6, lg: 10, xxl: 12 }}>
      <StyledButton
        ref={ref}
        aria-label={localiseText('find.open_filters')}
        testId="filters-button-sticky"
        onClick={onClick}
        leadingIcon={
          appliedFiltersCount > 0 ? (
            <StyledFilterCount
              aria-label={localiseText('find.n_filters_applied', {
                filterCount: appliedFiltersCount,
              })}
            >
              {appliedFiltersCount}
            </StyledFilterCount>
          ) : (
            <Box data-testid={'filters-menu-icon'}>
              <IconSystemFilter aria-hidden width="1.5rem" />
            </Box>
          )
        }
      >
        <Text typography="typeButtonLabel">{localiseText('find.filters')}</Text>
      </StyledButton>
      <Box
        width={'10px'}
        height={'48px'}
        borderColor={'colorBlack10'}
        borderRight={1}
      />
    </Flex>
  )
})
