import React, { FC } from 'react'
import {
  styled,
  breakpointDown,
  createGlobalStyle,
  breakpointUp,
} from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { IconSystemChevronRight } from '@moonpig/launchpad-assets'
import { Box, Flex, IconButton, Text } from '@moonpig/launchpad-components'
import {
  RadioButton,
  RadioGroup,
  FormControlLabel,
} from '@moonpig/launchpad-forms'
import { useFindLocaleText } from '../../text-localisation'
import { SlidingPanel } from '../HierarchicalFilters/SlidingPanel'
import { SlidingMenu } from '../HierarchicalFilters/SlidingMenu'
import { ContextualHeader } from '../HierarchicalFilters/ContextualHeader'
import { LAYOUT_BREAKPOINT } from '../HierarchicalFilters/constants'

const StyledDropdownOptions = styled.ul`
  position: relative;
  z-index: 3;
  background-color: white;
  list-style-type: none;
`

const StyledText = styled(Text)`
  ${breakpointDown('md')} {
    ${s({
      typography: { xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' },
      color: 'outlineCtaTwoContrast',
    })}
  }
`

const GlobalStyles = createGlobalStyle`
  ${breakpointDown('md')} {
    body {
      overflow: hidden;
    }
    header {
      display: none;
    }
  }
`

const StyledFormControlLabel = styled(FormControlLabel)`
  padding: 8px;
  height: 54px;
  ${s({
    borderBottom: '1px solid',
    borderColor: 'colorBlack20',
  })}
`

type SortBySelectProps = {
  name: string
  label: string
  value: string
  options: { label: string; value: string }[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  setOpenFilterCategory: (value: string | undefined) => void
  openFilterCategory?: string
}

const StyledIconBox = styled(Box)`
  min-width: 32px;
  min-height: 32px;
`

const StyledDiv = styled.div`
  display: inline-flex;
  width: 100%;
`

const StyledHidingBox = styled.div`
  display: block;
  ${s({
    px: { xs: 4, md: 0 },
  })}
`

const StyledIconButton = styled(IconButton)`
  display: block;
`

const StyledPanelWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`

const MobileOnly = styled.div`
  display: auto;
  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    display: none;
  }
`

const StyledBox = styled(Box)`
  ${breakpointDown('md')} {
    ${s({
      mt: '58px',
    })}
  }
  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    ${s({
      borderBottom: '1px solid',
      borderColor: 'colorBackgroundInformation',
    })}
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
`

export const SortByFilter: FC<SortBySelectProps> = ({
  options,
  onChange,
  value,
  label,
  setOpenFilterCategory,
  openFilterCategory,
}) => {
  const showDropdown = openFilterCategory === 'sortby'
  const onSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setOpenFilterCategory(undefined)
    onChange(e)
  }
  const localiseText = useFindLocaleText()
  const selectedOptionLabel = options.find(o => o.value === value)?.label
  return (
    <>
      <StyledHidingBox data-testid={'sort-by-filter'}>
        <StyledDiv
          onClick={() => {
            setOpenFilterCategory('sortby')
          }}
        >
          <Flex
            data-testid="find-sortby-button"
            justifyContent="center"
            flexDirection="column"
            marginLeft={4}
            width="100%"
          >
            <Flex justifyContent="space-between">
              <StyledText>{label}</StyledText>
              <Text data-testid={'sortby-current-value'} color={'#0050FF'}>
                {selectedOptionLabel}
              </Text>
            </Flex>
          </Flex>
          <Box>
            <StyledIconBox marginLeft="auto" marginTop="auto">
              <StyledIconButton
                icon={IconSystemChevronRight}
                label={`view sort-by options`}
              >
                <IconSystemChevronRight />
              </StyledIconButton>
            </StyledIconBox>
          </Box>
        </StyledDiv>
      </StyledHidingBox>

      {showDropdown && (
        <SlidingPanel isNestedPanel>
          <StyledPanelWrapper data-testid={'sliding-sortby-panel'}>
            <SlidingMenu
              enableHorizontalAnimation={false}
              rightPanelActive={!!showDropdown}
              leftPanelActive={!showDropdown}
            >
              <SlidingPanel>
                <MobileOnly>
                  <ContextualHeader
                    title={localiseText('find.sort_by')}
                    onBack={() => {
                      setOpenFilterCategory(undefined)
                    }}
                    onClear={
                      // istanbul ignore next
                      () => {}
                    }
                    allowClear={false}
                    loading={false}
                  />
                </MobileOnly>

                <StyledDropdownOptions>
                  <StyledBox>
                    <RadioGroup name="sort-by" legend="">
                      {options?.map(sortByOption => (
                        <StyledFormControlLabel
                          label={sortByOption.label}
                          id={sortByOption.value}
                          key={sortByOption.value}
                        >
                          <RadioButton
                            checked={value === sortByOption.value}
                            onChange={onSelect}
                            value={sortByOption.value}
                          />
                        </StyledFormControlLabel>
                      ))}
                    </RadioGroup>
                  </StyledBox>
                  <GlobalStyles />
                </StyledDropdownOptions>
              </SlidingPanel>
            </SlidingMenu>
          </StyledPanelWrapper>
        </SlidingPanel>
      )}
    </>
  )
}
