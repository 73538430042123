import {
  IconSystemChevronRight,
  IconSystemPlusOutline,
  IconSystemMinusOutline,
} from '@moonpig/launchpad-assets'
import { Box, Flex, IconButton, Text } from '@moonpig/launchpad-components'
import { styled, breakpointUp, breakpointDown } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import React, { FC, memo, PropsWithChildren, useCallback } from 'react'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { colorValue } from '@moonpig/launchpad-theme'
import { LAYOUT_BREAKPOINT } from './constants'
import {
  capitaliseFirstCharacter,
  shallowCompare,
  facetsAreEqual,
} from '../../utils'
import { useFindLocaleText } from '../../text-localisation'
import { FilterServiceFilterFacet } from '../../services/types/services'
import { generateFilterCategoryExpandedGAEvent } from '../../analytics/GAEvents'
import { FiltersPageType } from '../types'

const StyledTitleText = styled(Text).attrs(
  ({
    isMixedView,
    selectedChildren,
  }: {
    isMixedView: boolean
    selectedChildren: boolean
  }) => ({
    className: `${isMixedView && 'is-mixed-view-text'} ${
      selectedChildren && 'selected-children'
    }`,
  }),
)<{
  isMixedView: boolean
  selectedChildren: boolean
}>`
  ${s({
    typography: { xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' },
    color: 'outlineCtaTwoContrast',
    mb: -3,
  })}
  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    ${s({
      mx: 0,
    })}
  }
  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    ${s({
      mx: 2,
      typography: 'typeDisplay06',
    })}
  }
  &.is-mixed-view-text {
    ${breakpointDown(LAYOUT_BREAKPOINT)} {
      ${s({
        typography: 'typeBodyCaption',
        color: 'outlineCtaTwoContrast',
        mx: 4,
        px: 4,
      })}
    }
  }
  &.selected-children {
    ${breakpointDown(LAYOUT_BREAKPOINT)} {
      ${s({
        typography: 'typeDisplay05',
      })}
    }
  }
`

const StyledSelectableOption = styled.div.attrs(
  ({
    isMixedView,
    selectedChildren,
    root,
  }: {
    isMixedView: boolean
    selectedChildren: boolean
    root: boolean
  }) => ({
    className: `${isMixedView && 'is-mixed-view'}
     ${selectedChildren && 'selected-children'}
     ${root ? 'add-padding' : 'remove-border'}
     `,
  }),
)<{ isMixedView: boolean; selectedChildren: boolean; root: boolean }>`
  display: inline-flex;
  width: 100%;
  cursor: pointer;
  min-height: 64px;

  ${s({
    py: 3,
    pr: 4,
    pl: 6,
  })}

  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    border-bottom: 1px solid ${colorValue('colorBorder03')};
  }

  &.remove-border {
    ${breakpointUp(LAYOUT_BREAKPOINT)} {
      ${s({
        borderBottom: 'none',
      })}
    }
  }

  &.add-padding {
    ${breakpointDown(LAYOUT_BREAKPOINT)} {
      ${s({
        py: 3,
      })}
    }
  }

  &.is-mixed-view {
    ${s({
      borderBottom: 'none',
    })}

    ${breakpointDown(LAYOUT_BREAKPOINT)} {
      display: flex;
      justify-content: space-between;
      width: auto;
      ${s({
        px: 2,
        mt: 4,
        border: 1,
        borderColor: 'colorBackgroundInformation',
        borderRadius: 2,
      })}
    }

    &.selected-children {
      ${breakpointDown(LAYOUT_BREAKPOINT)} {
        ${s({
          bgcolor: 'colorBackground03',
        })}
      }
    }
  }
`

const StyledAppliedFilterContainer = styled(Text).attrs(
  ({ isMixedView }: { isMixedView: boolean }) => ({
    className: `${isMixedView && 'mixed-view-filter-text'}`,
  }),
)<{ isMixedView: boolean }>`
  ${s({
    pb: 2,
    ml: 4,
  })}

  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    ${s({
      ml: 2,
    })}
  }

  &.mixed-view-filter-text {
    ${breakpointDown(LAYOUT_BREAKPOINT)} {
      ${s({
        ml: 6,
      })}
    }
  }
`

const StyledFacetLabelText = styled(Text)`
  ${s({
    color: 'colorInteractionSelectedState',
    typography: 'typeBodyCaption',
  })}
`

const StyledIconChevronDown = styled(IconSystemChevronRight)`
  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    transform: rotate(90deg);
  }
`

const StyledIconChevronUp = styled(IconSystemChevronRight)`
  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    transform: rotate(-90deg);
  }
`

const StyledDesktopSubcategoryIcon = styled(IconButton)`
  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    display: none;
  }
  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    display: block;
    ${s({
      ml: -5,
      mt: 3,
    })}
  }
`

const StyledCategoryIcon = styled(IconButton).attrs(
  ({ root }: { root: boolean }) => ({
    className: `${!root && 'is-sub'}`,
  }),
)<{ root: boolean }>`
  display: block;

  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    &.is-sub {
      display: none;
    }
  }
`

const StyledIconBox = styled(Box)`
  min-width: 32px;
  min-height: 32px;
`

const formatLabel = (
  label: string,
  isLast: boolean,
  prefix?: string,
): string => {
  return `${prefix || ''}${capitaliseFirstCharacter(label)}${
    !isLast ? ', ' : ''
  }`
}

type AppliedFiltersProps = {
  label: string
  isMixedView: boolean
  selectedChildren: FilterServiceFilterFacet[]
  allSelected?: { label: string; includedKeys: string[] }[]
}

const AppliedFilters: FC<AppliedFiltersProps> = ({
  label,
  isMixedView,
  selectedChildren,
  allSelected,
}) => {
  const localiseText = useFindLocaleText()

  // selected children not covered by all selection
  const remainingSelectedChildren = allSelected
    ?.flatMap(x => x.label)
    .includes(label)
    ? []
    : selectedChildren.filter(
        c => !allSelected?.flatMap(x => x.includedKeys).includes(c.facetKey),
      )

  return (
    <StyledAppliedFilterContainer
      isMixedView={isMixedView}
      data-testid="web-find-applied-filters"
    >
      {!!allSelected?.length &&
        allSelected.map(({ label: innerLabel }, i, arr) => (
          <StyledFacetLabelText key={`all ${innerLabel}`}>
            {formatLabel(
              innerLabel,
              arr.length - 1 === i && !remainingSelectedChildren.length,
              'All ',
            )}
          </StyledFacetLabelText>
        ))}
      {remainingSelectedChildren.length > 5 ? (
        <StyledFacetLabelText
          key={`${remainingSelectedChildren.length} ${localiseText(
            'find.filters',
          )}`}
        >
          {localiseText('find.n_more_filters', {
            filterName: remainingSelectedChildren[0].label,
            moreCount: remainingSelectedChildren.length - 1,
          })}
        </StyledFacetLabelText>
      ) : (
        remainingSelectedChildren.map((facet, i, arr) => {
          return (
            <StyledFacetLabelText key={`${facet.facetKey}`}>
              {formatLabel(facet.label, arr.length - 1 === i)}
            </StyledFacetLabelText>
          )
        })
      )}
    </StyledAppliedFilterContainer>
  )
}

type ExpandableOptionProps = {
  root: boolean
  facetKey: string
  label: string
  isOpen: boolean
  isMixedView: boolean
  selectedChildren: FilterServiceFilterFacet[]
  onSelection: (facetKey: string) => void
  pageType: FiltersPageType
  selected?: boolean
  allSelected?: { label: string; includedKeys: string[] }[]
  loading?: boolean
}

const ExpandableOptionComponent: FC<ExpandableOptionProps> = ({
  root,
  facetKey,
  label,
  isOpen,
  isMixedView,
  selectedChildren,
  onSelection,
  selected,
  allSelected,
  loading = false,
  pageType,
}) => {
  const expandedCollapsedCallback = useCallback(() => {
    if (!loading) {
      trackGAEvent(
        generateFilterCategoryExpandedGAEvent({
          facetKey,
          pageType,
          expanded: !isOpen,
        }),
      )
      onSelection(isOpen ? '' : facetKey)
    }
  }, [facetKey, pageType, isOpen, loading, onSelection])
  return (
    <StyledSelectableOption
      isMixedView={isMixedView}
      selectedChildren={selectedChildren?.length > 0 && true}
      root={root}
      onClick={expandedCollapsedCallback}
    >
      {!root && (
        <StyledDesktopSubcategoryIcon
          icon={isOpen ? IconSystemMinusOutline : IconSystemPlusOutline}
          label={`filter subcategory options`}
        />
      )}
      <Flex flexDirection="column" justifyContent="center">
        <StyledTitleText
          isMixedView={isMixedView}
          selectedChildren={selectedChildren?.length > 0}
        >
          {label}
        </StyledTitleText>
        {selected && (
          <AppliedFilters
            isMixedView={isMixedView}
            selectedChildren={selectedChildren}
            allSelected={allSelected}
            label={label}
          />
        )}
      </Flex>
      <>
        <StyledIconBox marginLeft="auto" marginTop="auto">
          {
            <StyledCategoryIcon
              root={root}
              icon={isOpen ? StyledIconChevronUp : StyledIconChevronDown}
              label={`filter category options`}
              disabled={loading}
            />
          }
        </StyledIconBox>
      </>
    </StyledSelectableOption>
  )
}

/* istanbul ignore next */
const propsAreEqual = (
  prevProps: Readonly<PropsWithChildren<ExpandableOptionProps>>,
  nextProps: Readonly<PropsWithChildren<ExpandableOptionProps>>,
): boolean => {
  const {
    allSelected: firstAllSelected,
    selectedChildren: firstSelectedChildren,
    ...firstProps
  } = prevProps
  const {
    allSelected: secondAllSelected,
    selectedChildren: secondSelectedChildren,
    ...secondProps
  } = nextProps

  return (
    shallowCompare(firstProps, secondProps) &&
    (firstAllSelected === secondAllSelected ||
      (!firstAllSelected || !secondAllSelected
        ? !!firstAllSelected === !!secondAllSelected
        : (firstAllSelected.length === secondAllSelected.length &&
            firstAllSelected
              ?.map((x, i) => secondAllSelected && x === secondAllSelected[i])
              .every(x => x)) ||
          false)) &&
    (firstSelectedChildren === secondSelectedChildren ||
      (firstSelectedChildren.length === secondSelectedChildren.length &&
        firstSelectedChildren
          .map((x, i) => facetsAreEqual(x, secondSelectedChildren[i]))
          .every(x => x)))
  )
}

export const ExpandableOption = memo(ExpandableOptionComponent, propsAreEqual)
