import {
  reduceFilters,
  reduceFilterFacets,
} from './GalleryFiltering/filterItemUtils'
import { getFilterFacet } from '.'
import {
  FilterServiceFilterItem,
  FilterServiceFilterFacet,
  FilterItem,
  Filter,
  FilterCategory,
  ApplicableFilter,
} from '../services/types/services'

export const useNbaFilters = (
  facets: FilterServiceFilterItem[],
): FilterServiceFilterFacet[] => {
  const recursiveFlatMapNbas = (
    allFacets: FilterServiceFilterFacet[],
  ): FilterServiceFilterFacet[] => {
    const current = allFacets.map(x => {
      return {
        ...x,
      }
    })
    const currentChildren = allFacets.flatMap(x =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      x.children?.length
        ? recursiveFlatMapNbas(reduceFilterFacets(x.children))
        : [],
    )

    return [...current, ...currentChildren]
  }

  const nbaFacets = [
    ...new Set(recursiveFlatMapNbas(reduceFilterFacets(facets))),
  ]
    .filter(x => x.nbaScore && x.nbaScore !== null)
    .filter(facet => getFilterFacet(facet))

  return [...nbaFacets]
}

export const useNbaFiltersVariant = (filters: FilterItem[]): Filter[] => {
  const recursiveFlatMapNbas = (
    allFilters: (ApplicableFilter | FilterCategory)[],
  ): Filter[] => {
    const current = allFilters
      .filter(
        x => x.__typename === 'Filter' || x.__typename === 'FilterCategory',
      )
      .map(x => {
        if (x.__typename === 'FilterCategory') {
          return {
            id: x.id,
            label: x.label,
            parent: x.parent,
            nbaScore: x.nbaScore,
            count: x.count,
          }
        }

        return x
      })
    const currentChildren = allFilters.flatMap(x =>
      x.__typename === 'FilterCategory'
        ? recursiveFlatMapNbas(reduceFilters(x.children))
        : [],
    )

    return [...current, ...currentChildren] as Filter[]
  }

  const nbaFilters = [
    ...new Set(recursiveFlatMapNbas(reduceFilters(filters))),
  ].filter(x => x.nbaScore && x.nbaScore !== null)

  return [...nbaFilters]
}
