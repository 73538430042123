import { DYNAMIC_PAGE_TYPE, SEARCH_PAGE_TYPE } from '../constants'

export const generateGAListDataName = (
  pageType: string | undefined,
  pageTitle: string,
  searchTerm?: string,
  facets?: {
    group: string
    facetKey: string
  }[],
  department?: string,
) => {
  if (pageType !== SEARCH_PAGE_TYPE && pageType !== DYNAMIC_PAGE_TYPE)
    return pageTitle

  if (facets?.length) {
    if (searchTerm) {
      return `filter | ${department} | keyword | ${searchTerm}`.toLowerCase()
    }
    return `${pageType.toLowerCase()} | ${department} | autocomplete | ${facets
      .map(facet => `${facet.group}:${facet.facetKey}`)
      .join(',')}`.toLowerCase()
  }
  return `${pageType.toLowerCase()} | ${department} | keyword | ${searchTerm}`.toLowerCase()
}
