import { Box, Text } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import React, {
  useState,
  memo,
  useCallback,
  FC,
  PropsWithChildren,
} from 'react'
import { breakpointDown, styled, breakpointUp } from '@moonpig/launchpad-utils'
import { FilterOption } from './FilterOption'
import { SlidingMenu } from './SlidingMenu'
import { SlidingPanel } from './SlidingPanel'
import { ContextualHeader } from './ContextualHeader'
import { LAYOUT_BREAKPOINT } from './constants'
import { ListWithAllOption } from './ListWithAllOption'
import {
  isFacetSelected,
  shallowCompare,
  facetsAreEqual,
  areAllChildrenSelected,
  reduceFilterFacets,
  getFilterFacet,
  getFilterHeader,
} from '../../utils'
import { headersAreEqual } from '../../utils/GalleryFiltering/facetsAreEqual'
import { AllSelectedChangedEvent, FilterChangedEvent } from './types'
import {
  FilterServiceFilterFacet,
  FilterServiceFilterHeader,
  FilterServiceFilterItem,
} from '../../services/types/services'
import { FiltersPageType } from '../types'

const MobileOnly = styled.div`
  display: auto;
  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    display: none;
  }
`

const StyledBox = styled(Box)`
  ${breakpointDown('md')} {
    ${s({
      mt: '64px',
    })}
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
`

const StyledHeaderText = styled(Text)`
  ${breakpointDown('md')} {
    display: flex;
    align-items: center;
    justify-content: center;
    ${s({
      mr: 8,
      pr: 4,
    })}
  }

  ${s({
    typography: 'typeDisplay06',
  })}
`
const StyledAlphabeticalHeaderText = styled(Text)`
  ${s({
    typography: 'typeDisplay06',
  })}
`

const StyledHeaderBox = styled(Box).attrs(
  ({ isFirst }: { isFirst: boolean }) => ({
    className: `${isFirst && 'is-first'}
   `,
  }),
)<{ isFirst: boolean }>`
  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    ${s({
      ml: 6,
      mt: 6,
    })}

    &.is-first {
      ${s({ mt: 4 })}
    }
  }

  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    ${s({
      ml: 6,
      mt: 6,
    })}

    &.is-first {
      ${s({ mt: 0 })}
    }
  }
`

const StyledAllList = styled(ListWithAllOption)`
  ${s({
    pb: 6,
    pt: 4,
    mb: 1,
  })}
  ${breakpointDown(LAYOUT_BREAKPOINT)} {
    ${s({
      mb: 15,
      pb: 6,
      px: 6,
    })}
  }
`

const hasChildren = (facet: FilterServiceFilterItem) =>
  Boolean('facetKey' in facet && facet?.children?.length)

type FilterOptionsProps = {
  facet: FilterServiceFilterFacet
  selectedChildren: FilterServiceFilterItem[]
  openFilterCategory: string | undefined
  openOption?: string
  allowClear: boolean
  filterChanged: FilterChangedEvent
  allSelectedChanged: AllSelectedChangedEvent
  setOpenFilterCategory: (facetKey: string) => void
  setOpenOption: (facetKey: string) => void
  onClearAll: () => void
  loading?: boolean
  pageType: FiltersPageType
}

type FilterHeaderComponentProps = {
  item: FilterServiceFilterHeader
  isFirst: boolean
}

type FilterFacetComponentProps = {
  item: FilterServiceFilterFacet
  lastSelected?: string
  selectedChildren: FilterServiceFilterItem[]
  setOpenFilterCategory: (facetKey: string) => void
  openFilterCategory?: string
  filterChanged: FilterChangedEvent
  onClearAll: () => void
  allowClear: boolean
  allSelectedChanged: AllSelectedChangedEvent
  isMixedView: boolean
  pageType: FiltersPageType
}

const FilterHeaderComponent: FC<FilterHeaderComponentProps> = ({
  item,
  isFirst,
}) => {
  return (
    <StyledHeaderBox isFirst={isFirst}>
      {
        /* istanbul ignore next*/
        item.name.length > 1 ? (
          <StyledHeaderText>{item.name}</StyledHeaderText>
        ) : (
          <StyledAlphabeticalHeaderText>
            {item.name}
          </StyledAlphabeticalHeaderText>
        )
      }
    </StyledHeaderBox>
  )
}

const FilterFacetComponent: FC<FilterFacetComponentProps> = ({
  item,
  selectedChildren,
  filterChanged,
  setOpenFilterCategory,
  openFilterCategory,
  lastSelected,
  onClearAll,
  allowClear,
  allSelectedChanged,
  isMixedView,
  pageType,
}) => {
  const selectedFacets = reduceFilterFacets(selectedChildren)
  const checked = isFacetSelected(item, selectedFacets)
  const selected =
    item.facetKey === lastSelected && /* istanbul ignore next */ checked

  return (
    <FilterOption
      facet={item}
      checked={checked}
      selected={selected}
      filterChanged={filterChanged}
      selectedChildren={selectedFacets}
      setOpenFilterCategory={setOpenFilterCategory}
      openFilterCategory={openFilterCategory}
      onClearAll={onClearAll}
      allowClear={allowClear}
      isMixedView={isMixedView}
      allSelectedChanged={allSelectedChanged}
      pageType={pageType}
    />
  )
}

const FilterOptionsComponent: FC<FilterOptionsProps> = ({
  facet,
  selectedChildren,
  filterChanged,
  allSelectedChanged,
  setOpenFilterCategory,
  onClearAll,
  allowClear,
  loading,
  openOption,
  setOpenOption,
  pageType,
}) => {
  const [lastSelected, setLastSelected] = useState<string | undefined>()

  const isMixedView =
    facet?.children?.some(hasChildren) &&
    facet?.children?.some(f => !hasChildren(f))
  const allSelected = areAllChildrenSelected(
    facet,
    reduceFilterFacets(selectedChildren),
  )

  const filterChangedInner = useCallback<FilterChangedEvent>(
    (removed, facetKey) => {
      setLastSelected(facetKey)
      filterChanged(removed, facetKey)
    },
    [filterChanged],
  )

  return (
    <SlidingMenu
      enableHorizontalAnimation
      rightPanelActive={!!openOption}
      leftPanelActive={!openOption}
    >
      <SlidingPanel>
        <MobileOnly>
          <ContextualHeader
            title={facet.label}
            onBack={() => {
              setOpenFilterCategory('')
            }}
            onClear={onClearAll}
            allowClear={allowClear}
            loading={loading}
          />
        </MobileOnly>

        <StyledBox>
          <StyledAllList
            group={facet.group}
            label={facet.label}
            facetKey={facet.facetKey}
            hasAllOption={facet.hasAllOption}
            selected={!!allSelected}
            selectedChanged={removed =>
              allSelectedChanged(
                removed,
                reduceFilterFacets(
                  facet.children || /* istanbul ignore next */ [],
                ).map(x => x.facetKey),
                facet.facetKey,
              )
            }
          >
            {facet?.children?.map((currentFacet, index) => {
              if ('facetKey' in currentFacet) {
                return (
                  <Box
                    key={`${currentFacet.group}-${currentFacet.facetKey}-${currentFacet.count}`}
                  >
                    <FilterFacetComponent
                      item={currentFacet}
                      filterChanged={filterChangedInner}
                      selectedChildren={selectedChildren}
                      setOpenFilterCategory={setOpenOption}
                      openFilterCategory={openOption}
                      onClearAll={onClearAll}
                      allowClear={allowClear}
                      isMixedView={!!isMixedView}
                      allSelectedChanged={allSelectedChanged}
                      lastSelected={lastSelected}
                      pageType={pageType}
                    />
                  </Box>
                )
              }

              return (
                <Box key={`header-${currentFacet.name}`}>
                  <FilterHeaderComponent
                    item={currentFacet}
                    isFirst={index === 0}
                  />
                </Box>
              )
            })}
          </StyledAllList>
        </StyledBox>
      </SlidingPanel>
    </SlidingMenu>
  )
}

/* istanbul ignore next */
const propsAreEqual = (
  prevProps: Readonly<PropsWithChildren<FilterOptionsProps>>,
  nextProps: Readonly<PropsWithChildren<FilterOptionsProps>>,
): boolean => {
  const {
    facet: firstFacet,
    selectedChildren: firstSelectedChildren,
    ...firstProps
  } = prevProps
  const {
    facet: secondFacet,
    selectedChildren: secondSelectedChildren,
    ...secondProps
  } = nextProps

  return (
    shallowCompare(firstProps, secondProps) &&
    (firstFacet === secondFacet || facetsAreEqual(firstFacet, secondFacet)) &&
    (firstSelectedChildren === secondSelectedChildren ||
      (firstSelectedChildren.length === secondSelectedChildren.length &&
        firstSelectedChildren
          .map(
            (x, i) =>
              facetsAreEqual(
                getFilterFacet(x),
                getFilterFacet(secondSelectedChildren[i]),
              ) &&
              headersAreEqual(
                getFilterHeader(x),
                getFilterHeader(secondSelectedChildren[i]),
              ),
          )
          .every(x => x)))
  )
}

export const FilterOptions = memo(FilterOptionsComponent, propsAreEqual)
