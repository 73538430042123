import { create } from 'zustand'
import { SortOrder } from '@moonpig/web-explore-types-graphql'
import type { PageStateProps, SearchStoreState } from './types'
import { FilterAction, FilteringEvent, FilterSource } from '../types'
import { trackingMiddleware } from './middleware/Tracking'
import { updateCategories } from './helpers/updateCategories'
import { updateSelectedFilters } from './helpers/updateSelectedFilters'
import { clearCategoryFilters } from './helpers/filterCategories'
import { updateAllCategoryFilters } from './helpers/updateAllCategoryFilters'
import { updateAllFilters } from './helpers/updateAllFilters'
import { updateRoute } from './helpers/updateRoute'
import { getFiltersById } from './helpers/getFiltersById'
import { removePreAppliedFilters } from './helpers/removePreAppliedFilters'
import { getPersistedFilters } from './helpers/persistFilterSelection'
import { applySelectedFilters as applyPreviouslySelectedFilters } from './helpers/applySelectedFilters'
import { ApplicableFilter, FilterItem } from '../services/types/services'

export const useSearchStore = create<SearchStoreState>(
  trackingMiddleware((set, get) => ({
    pageContext: { title: '', type: '', router: null },
    filtersMenuOpen: false,
    filtersLoading: false,
    filters: [],
    preAppliedFilters: [],
    selectedFilters: [],
    filteringEvent: {} as FilteringEvent,
    selectedCategories: [],
    allCategoryFilters: [],
    queryFilters: null,
    results: { count: 0 },
    initialSortValue: SortOrder.POPULARITY,
    sortValue: SortOrder.POPULARITY,
    sortMenuOpen: false,
    toggleFiltersMenu: source => {
      set(state => ({
        filtersMenuOpen: !state.filtersMenuOpen,
        selectedCategories: [],
        sortMenuOpen: source === 'sort',
      }))
    },
    toggleFiltersCategory: filtersCategory =>
      set(state => {
        const currentSelectedCategories = [...state.selectedCategories]
        const isCurrentCategory =
          filtersCategory.id ===
          currentSelectedCategories[currentSelectedCategories.length - 1]?.id

        if (isCurrentCategory) {
          currentSelectedCategories.pop()
        } else {
          currentSelectedCategories.push(filtersCategory)
        }

        return {
          selectedCategories: currentSelectedCategories,
        }
      }),
    toggleFiltersLoading: () =>
      set(state => ({ filtersLoading: !state.filtersLoading })),
    createPageContext: ({ pageContext }: PageStateProps) =>
      set(() => ({ pageContext })),
    loadFilters: async ({
      query,
      initialFilters,
      urlFilters,
      initialSortValue,
      results,
    }) => {
      get().toggleFiltersLoading()
      const filtersResult = await query([...initialFilters, ...urlFilters])
      const persistedFilters = getPersistedFilters()
      get().toggleFiltersLoading()

      set(() => {
        return {
          filters: applyPreviouslySelectedFilters(
            removePreAppliedFilters(filtersResult.filters, initialFilters),
            [...persistedFilters, ...urlFilters.map(f => f.key)],
          ),
          preAppliedFilters: initialFilters,
          selectedFilters: getFiltersById({
            filterIds: [...urlFilters.map(f => f.key), ...persistedFilters],
            allFilters: filtersResult.filters,
          }),
          queryFilters: async filters => {
            const result = await query(filters)
            return removePreAppliedFilters(result.filters, initialFilters)
          },
          sortValue: initialSortValue,
          initialSortValue,
          results,
        }
      })
    },
    toggleFilter: async ({ filter, select, source }) => {
      const {
        selectedFilters,
        queryFilters,
        toggleFiltersLoading,
        preAppliedFilters,
        allCategoryFilters,
        selectedCategories,
        pageContext: { router },
      } = get()

      if (queryFilters) {
        toggleFiltersLoading()
        const updatedSelectedFilters = updateSelectedFilters({
          selectedFilters,
          newFilter: filter,
          select,
        })
        const updatedFilters = await queryFilters([
          ...updatedSelectedFilters.map(f => ({
            key: f.id,
            group: f.parent,
            userApplied: true,
          })),
          ...preAppliedFilters,
        ])
        toggleFiltersLoading()

        const updatedCategories = updateCategories(
          updatedFilters,
          selectedCategories.map(c => c.id),
        )
        updateRoute({ router, selectedFilters: updatedSelectedFilters })
        set(() => {
          return {
            filters: updatedFilters,
            allCategoryFilters: updateAllFilters({
              allCategoryFilters,
              select,
              category: updatedCategories[selectedCategories.length - 1],
            }),
            selectedFilters: updatedSelectedFilters,
            select,
            filteringEvent: {
              action: select ? FilterAction.Add : FilterAction.Remove,
              source,
              filter,
            },
            selectedCategories: updatedCategories,
          }
        })
      }
    },
    toggleAllCategoryFilters: async ({ filter, select, source }) => {
      const {
        selectedFilters,
        queryFilters,
        toggleFiltersLoading,
        preAppliedFilters,
        selectedCategories,
        allCategoryFilters,
        pageContext: { router },
      } = get()

      if (queryFilters) {
        toggleFiltersLoading()
        const updatedSelectedFilters = updateAllCategoryFilters({
          selectedFilters,
          categoryFilters:
            selectedCategories[selectedCategories.length - 1].children,
          select,
        })

        const updatedFilters = await queryFilters([
          ...updatedSelectedFilters.map(f => ({
            key: f.id,
            group: f.parent,
            userApplied: true,
          })),
          ...preAppliedFilters,
        ])
        toggleFiltersLoading()

        updateRoute({ router, selectedFilters: updatedSelectedFilters })
        set(state => {
          return {
            filters: updatedFilters,
            allCategoryFilters: select
              ? [...allCategoryFilters, filter.id]
              : allCategoryFilters.filter(f => f !== filter.id),
            selectedFilters: updatedSelectedFilters,
            select,
            filteringEvent: {
              action: select ? FilterAction.Add : FilterAction.Remove,
              source,
              filter,
            },
            selectedCategories: updateCategories(
              updatedFilters,
              state.selectedCategories.map(c => c.id),
            ),
          }
        })
      }
    },
    updateResults: results => set(() => ({ results })),
    clearFilters: async (category, type) => {
      const {
        queryFilters,
        toggleFiltersLoading,
        preAppliedFilters,
        selectedFilters,
        pageContext: { router },
        allCategoryFilters,
        filters,
      } = get()

      let updatedFilters: FilterItem[] | null = null
      let updatedSelectedFilters: ApplicableFilter[] | null = null

      if (queryFilters) {
        if (type !== 'sort') {
          toggleFiltersLoading()
          const persistedFilters = getFiltersById({
            filterIds: getPersistedFilters(),
            allFilters: filters,
          })
          updatedSelectedFilters = category
            ? clearCategoryFilters(category, [...selectedFilters])
            : persistedFilters

          updatedFilters = await queryFilters([
            ...preAppliedFilters,
            ...updatedSelectedFilters.map(f => ({
              key: f.id,
              group: f.parent,
              userApplied: true,
            })),
          ])
          toggleFiltersLoading()

          updateRoute({ router, selectedFilters: updatedSelectedFilters })
        }

        set(state => {
          return {
            filters: updatedFilters || filters,
            selectedFilters: updatedSelectedFilters || selectedFilters,
            allCategoryFilters: category
              ? updateAllFilters({
                  allCategoryFilters,
                  select: false,
                  category,
                })
              : [],
            filteringEvent: {
              action: FilterAction.ClearAll,
              source: FilterSource.FiltersMenu,
            },
            selectedCategories: updatedFilters
              ? updateCategories(
                  updatedFilters,
                  state.selectedCategories.map(c => c.id),
                )
              : state.selectedCategories,
            sortValue: category ? state.sortValue : state.initialSortValue,
          }
        })
      }
    },
    sortBy: value => set(() => ({ sortValue: value })),
  })),
)
