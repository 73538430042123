import { styled } from '@moonpig/launchpad-utils'
import React, { FC, PropsWithChildren } from 'react'
import { ExpandableOption } from './ExpandableOption'
import { SlidingPanel } from './SlidingPanel'
import { FilterServiceFilterFacet } from '../../services/types/services'
import { FiltersPageType } from '../types'

const StyledPanelWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`

const StyledHidingBox = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? `block` : 'none')};
`

type SlidingOptionProps = {
  root: boolean
  facetKey: string
  label: string
  isMixedView: boolean
  isOptionSelected: boolean
  onSelection: (facetKey: string) => void
  selectedChildren: FilterServiceFilterFacet[]
  pageType: FiltersPageType
  selected?: boolean
  allSelected?: { label: string; includedKeys: string[] }[]
  loading?: boolean
  visible?: boolean
}

export const SlidingOption: FC<PropsWithChildren<SlidingOptionProps>> = ({
  root,
  children,
  facetKey,
  label,
  isMixedView,
  selectedChildren,
  onSelection,
  selected = false,
  allSelected,
  loading,
  isOptionSelected,
  visible = true,
  pageType,
}) => {
  return (
    <StyledHidingBox
      visible={!!visible}
      data-testid="web-find-filters-sliding-option"
    >
      <ExpandableOption
        root={root}
        isOpen={isOptionSelected}
        facetKey={facetKey}
        label={label}
        onSelection={onSelection}
        isMixedView={isMixedView}
        selectedChildren={selectedChildren}
        selected={selected}
        key={facetKey}
        allSelected={allSelected}
        loading={loading}
        pageType={pageType}
      />
      {isOptionSelected && (
        <SlidingPanel isNestedPanel>
          <StyledPanelWrapper>{children}</StyledPanelWrapper>
        </SlidingPanel>
      )}
    </StyledHidingBox>
  )
}
