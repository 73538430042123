import React, { FC, memo, useEffect, useState } from 'react'
import { styled, breakpointDown, breakpointUp } from '@moonpig/launchpad-utils'
import { Box } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { HierarchicalFilters } from '../HierarchicalFilters'
import { LAYOUT_BREAKPOINT } from '../HierarchicalFilters/constants'
import { DESKTOP_MODAL_FILTERS_MENU_WIDTH } from '../../constants'
import {
  FilterServiceFilterItem,
  SearchSortOrder,
} from '../../services/types/services'
import { FiltersPageType } from '../types'

const StyledStickyDiv = styled(Box)`
  top: 96px;
  display: block;
  position: relative;
  min-width: 0px;

  ${breakpointDown('md')} {
    position: relative;
    min-width: 0px;
  }
`

const StyledFiltersWrapper = styled(Box)<{
  menuClosed: boolean
}>`
  ${s({
    bgcolor: 'colorBackground01',
    height: '100%',
  })}

  ${breakpointDown('md')} {
    display: ${({ menuClosed }) => (menuClosed ? 'none' : 'auto')};
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1050;
    width: 100%;
    height: 100%;

    animation: slide-in-from-bottom 400ms;

    @keyframes slide-in-from-bottom {
      0% {
        transform: translateY(100%);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  ${breakpointUp('md')} {
    display: ${({ menuClosed }) => (menuClosed ? 'none' : 'auto')};
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1050;
    width: ${DESKTOP_MODAL_FILTERS_MENU_WIDTH};
    height: 100%;
    margin-top: 0px;

    animation: slide-in-from-left 400ms;

    @keyframes slide-in-from-left {
      0% {
        left: -100%;
      }
      100% {
        left: 0;
      }
    }
  }
`

const StyledGreyDiv = styled.div.attrs(
  ({ menuClosed }: { menuClosed: boolean }) => ({
    className: !menuClosed && 'menu-open',
  }),
)`
  ${breakpointUp(LAYOUT_BREAKPOINT)} {
    &.menu-open {
      width: 100%;
      z-index: 1049;
      background-color: rgba(0, 0, 0, 0.6);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: 100vh;
      display: flex;
    }
  }
`

export type FiltersMenuProps = {
  isOpen: boolean
  count: number
  facets: FilterServiceFilterItem[]
  close: () => void
  isFromSearch: boolean
  filtersLoading: boolean
  firstLoadCurrent?: boolean
  supportedSortOrders: SearchSortOrder[]
  pageType: FiltersPageType
}

const FiltersMenuComponent: FC<FiltersMenuProps> = ({
  isOpen,
  count,
  facets,
  close,
  filtersLoading,
  pageType,
}) => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])
  return (
    <div>
      <StyledStickyDiv data-testid="sticky-filters-menu">
        <>
          {facets && facets.length > 0 && mounted && (
            <div>
              <StyledFiltersWrapper
                menuClosed={!isOpen}
                data-testid="web-find-filters-wrapper"
              >
                {mounted && (
                  <HierarchicalFilters
                    isOpen={isOpen}
                    resultsCount={count}
                    facets={facets}
                    close={close}
                    filtersLoading={filtersLoading}
                    pageType={pageType}
                  />
                )}
              </StyledFiltersWrapper>
              <StyledGreyDiv
                data-testid="web-find-filters-grey-div"
                menuClosed={!isOpen}
                onClick={() => close()}
              />
            </div>
          )}
        </>
      </StyledStickyDiv>
    </div>
  )
}

export const FiltersMenu = memo(FiltersMenuComponent)
