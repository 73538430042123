import { styled } from '@moonpig/launchpad-utils'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import React, { FC, PropsWithChildren } from 'react'
import { useFindLocaleText } from '../../text-localisation'

type FiltersMenuModalProps = {
  onClose: () => void
  isOpen: boolean
}

const StyledDialogOverlay = styled(DialogOverlay)`
  position: fixed;
  top: 96px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
`

const StyledDialogContent = styled(DialogContent)`
  position: relative;
`

export const FiltersMenuModal: FC<
  PropsWithChildren<FiltersMenuModalProps>
> = props => {
  const { onClose, isOpen, children } = props
  const t = useFindLocaleText()

  return (
    <div data-testid="filters-menu-modal">
      <StyledDialogOverlay onDismiss={onClose} isOpen={isOpen}>
        <StyledDialogContent aria-label={t('find.filter_menu')}>
          {children}
        </StyledDialogContent>
      </StyledDialogOverlay>
    </div>
  )
}
